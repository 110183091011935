import * as XLSX from 'xlsx'

export const grade = (grade: number): number => {
  if (isNaN(grade)) {
    return 0
  }

  return parseFloat(grade?.toFixed(1)?.replace(/\.0+$/, ''))
}

export const exportCSV = (data: string, filename: string) : void => {
  const downloadLink = document.createElement('a')
  const blob = new Blob(['\ufeff', data])
  const url = URL.createObjectURL(blob)
  downloadLink.href = url
  downloadLink.download = filename

  document.body.appendChild(downloadLink)
  downloadLink.click()
  document.body.removeChild(downloadLink)
}

export const exportXLSX = (data: string[][], sheet: string, filename: string) : void => {
  const wb = XLSX.utils.book_new()
  const ws = XLSX.utils.aoa_to_sheet(data)
  XLSX.utils.book_append_sheet(wb, ws, sheet)
  XLSX.writeFile(wb, filename)
}

export const youtubeEmbedId = (url: string): string => {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
  const match = url.match(regExp)

  return (match && match[7].length === 11) ? match[7] : ''
}

export const random = (): number => parseInt((Math.random() * 100000).toFixed(0))

export const hexToRgb = (hex: string): string => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)

  if (!result)
    return '0, 0, 0'

  const r = parseInt(result[1], 16)
  const g = parseInt(result[2], 16)
  const b = parseInt(result[3], 16)

  return `${r}, ${g}, ${b}`
}
