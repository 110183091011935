import React, { useContext } from 'react'
import IDegree from '@interfaces/IDegree'
import IClass from '@interfaces/IClass'
import RegistrationContext from '@contexts/Registration'
import IRegistrationTypeColumn from '@interfaces/IRegistrationTypeColumn'

type Props = {
  entity: any
  column: IRegistrationTypeColumn
  onChange: (event: React.ChangeEvent<HTMLSelectElement>, entity: any, column: IRegistrationTypeColumn) => void
}

const SelectField: React.FC<any> = ({ entity, column, onChange }: Props) => {
  const { degrees, disciplines } = useContext(RegistrationContext)

  if (column.key === 'degreeName') {
    return (
      <select name="degreeName" defaultValue={entity.degreeName} className="form-control" onChange={e => onChange(e, entity, column)}>
        <option value="">-- Selecione --</option>
        {degrees.map((degree: IDegree) => (
          <option value={degree.name} key={degree.id}>{degree.name}</option>
        ))}
      </select>
    )
  }

  if (column.key === 'className') {
    return (
      <select name="className" defaultValue={entity.className} className="form-control" onChange={e => onChange(e, entity, column)}>
        <option value="">-- Selecione --</option>
        {degrees.length > 0 && degrees.find((degree: IDegree) => degree.name == entity.degreeName)?.classes.map((class_: IClass) => (
          <option value={class_.name} key={class_.id}>{class_.name}</option>
        ))}
      </select>
    )
  }

  if (column.key === 'disciplineName') {
    return (
      <select name="disciplineName" defaultValue={entity.disciplineName} className="form-control" onChange={e => onChange(e, entity, column)}>
        <option value="">-- Selecione --</option>
        {disciplines.map((discipline: any) => (
          <option value={discipline.name} key={discipline.id}>{discipline.name}</option>
        ))}
      </select>
    )
  }

  return null
}

export default SelectField
