import IClass from '@interfaces/IClass'
import axios from 'axios'

export const all = (degreeId: number, params: any = {}): Promise<any> => {
  return axios.get(`/degrees/${degreeId}/classes`, {
    params,
  })
}

export const students = (degreeId: number, classId: number, params: any = {}): Promise<any> => {
  return axios.get(`/degrees/${degreeId}/classes/${classId}/students`, {
    params,
  })
}

export const studentsForMigration = (degreeId: number, classId: number, params: any = {}): Promise<any> => {
  return axios.get(`/degrees/${degreeId}/classes/${classId}/students-for-migration`, {
    params,
  })
}

export const create = (degreeId: number, name: string): Promise<any> => {
  return axios.post(`/degrees/${degreeId}/classes`, {
    name,
  })
}

export const update = (degreeId: number, classId: number, data: IClass): Promise<any> => {
  return axios.put(`/degrees/${degreeId}/classes/${classId}`, data)
}

export const remove = (degreeId: number, classId: number): Promise<any> => {
  return axios.delete(`/degrees/${degreeId}/classes/${classId}`)
}

export const destroy = (degreeId: number, classId: number): Promise<any> => {
  return axios.delete(`/degrees/${degreeId}/classes/${classId}`)
}

export const deleteAll = (degreeId: number, classes: number[]): Promise<any> => {
  return axios.post(`/degrees/${degreeId}/classes/delete-all`, classes)
}

export const find = (degreeId: number, classId: number, year: number): Promise<any> => {
  return axios.get(`/degrees/${degreeId}/classes/${classId}/${year}`)
}

export const findClasses = (degreeId: number, year: number): Promise<any> => {
  return axios.get(`/degrees/${degreeId}/classes/school/${year}`)
}

export const migrate = (degreeId: number, classId: number, users: number[]): Promise<any> => {
  return axios.post(`/degrees/${degreeId}/classes/${classId}/migrated`, users)
}