import React, { createContext, useCallback, useEffect, useState } from 'react'
import useStickyState from '../hooks/useStickyState'
import * as $Platform from '@services/Platform'
import IPlatform from '@interfaces/IPlatform'
import axios from 'axios'
import { Outlet } from 'react-router-dom'

interface IPanelContext {
  platforms: IPlatform[]
  connectionLost: boolean
  sidebarCollapsed: boolean
  setSidebarCollapsed: React.Dispatch<React.SetStateAction<boolean>>
  activeMenuPath?: string
  setActiveMenuPath: React.Dispatch<React.SetStateAction<string>>
}

const PanelContext = createContext<IPanelContext>({ } as IPanelContext)

export const PanelProvider: React.FC = () => {
  const [ platforms, setPlatforms ] = useState<IPlatform[]>([])
  const [ sidebarCollapsed, setSidebarCollapsed ] = useStickyState<boolean>(false, 'sidebarCollapsed')
  const [ activeMenuPath, setActiveMenuPath ] = useState<string>('/')
  const [ connectionLost, setConnectionLost ] = useState<boolean>(false)

  useEffect(() => {
    $Platform.all().then(({ data: platforms }: any) => {
      setPlatforms(platforms.filter((p: IPlatform) => p.id !== 8))
    })
  }, [])

  const connection_timeout = parseInt(process.env.REACT_APP_CHECK_CONNECTION_TIMEOUT || '5000')

  const checkConnection = useCallback((timeout: NodeJS.Timeout) => {
    clearTimeout(timeout)

    axios.get('/', {
      timeout: parseInt(process.env.REACT_APP_CHECK_CONNECTION_TIMEOUT || '5000'),
    })
      .then(() => setConnectionLost(false))
      .catch(() => setConnectionLost(true))
      .finally(() => {
        timeout = setTimeout(() => checkConnection(timeout), connection_timeout)
      })
  }, [connection_timeout])

  useEffect(() => {
    const timeout: NodeJS.Timeout = setTimeout(() => checkConnection(timeout), connection_timeout)
  }, [checkConnection, connection_timeout])

  return (
    <PanelContext.Provider
      value={{
        platforms,
        connectionLost,
        sidebarCollapsed,
        setSidebarCollapsed,
        activeMenuPath,
        setActiveMenuPath,
      }}
    >
      <Outlet />
    </PanelContext.Provider>
  )
}

export default PanelContext
