import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import StudentsAndProductsContext from '@contexts/StudentsAndProducts'
import * as $Platform from '@services/Platform'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import { Toggle } from './Association.styles'
import IDegreeGroup from '@interfaces/IDegreeGroup'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'
import { getCssProperty } from '@helpers/Theme'
import { IPlatformSelection } from '@interfaces/IPlatformSelection'

const Association: React.FC<any> = () => {
  const { degree, degreeGroups, selectedAssociation, isLoading, setIsLoading, refresh, pushPlatform, removePlatform, year } = useContext(StudentsAndProductsContext)

  const [ activeDegreeGroupIds, setActiveDegreeGroupIds ] = useState<number[]>([])

  const SweetAlert = withReactContent(Swal)

  useEffect(() => {
    const degreeGroupIds: number[] = []

    if (degreeGroups[degree?.id]?.length > 0) {
      degreeGroups[degree?.id].forEach((items: IDegreeGroup[]) => {
        const degreeGroup: IDegreeGroup = items[0]

        if (degreeGroup != null && degreeGroup.degreeId == null || degreeGroup.degreeId === 0 || degreeGroup.degreeId === degreeGroup.currentDegreeId) {
          degreeGroupIds.push(degreeGroup.degreeGroupId)
        }
      })
    }

    setActiveDegreeGroupIds([ ...degreeGroupIds ])
  }, [degree, degreeGroups])

  const handleActiveDegreeGroupId = (degreeGroupId: number) => {
    if (activeDegreeGroupIds.includes(degreeGroupId)) {
      setActiveDegreeGroupIds(activeDegreeGroupIds.filter((id: number) => id !== degreeGroupId))
    } else {
      setActiveDegreeGroupIds([...activeDegreeGroupIds, degreeGroupId])
    }
  }

  const isChecked = useCallback((platformId: number, productId?: number) => {
    if (selectedAssociation?.degreeId === degree?.id && selectedAssociation?.platforms?.length > 0) {
      const platform: IPlatformSelection = selectedAssociation.platforms.find((platform: IPlatformSelection) => platform.id === platformId) as IPlatformSelection

      if (platform) {
        if (productId) {
          return platform.productIds.includes(productId)
        } else {
          return true
        }
      }
    }

    return false
  }, [selectedAssociation, degree])

  const isValid = useMemo(() => {
    if (degreeGroups[degree?.id]?.length && selectedAssociation?.students?.length) {
      for (const degreeGroup of degreeGroups[degree?.id]) {
        const items: IDegreeGroup[] = degreeGroup.filter((item: IDegreeGroup) => {
          return selectedAssociation.platforms.map(p => p.id).includes(item.platformId)
        })

        if (!items.length)
          continue

        for (const item of items) {
          const checked: boolean = isChecked(item.platformId, item.productId)

          if (item.maxAccessAmount === -1 || !checked)
            continue

          const currentAccessAmount = checked ? item.currentAccessAmount + selectedAssociation.students.length : item.currentAccessAmount

          if (currentAccessAmount > item.maxAccessAmount) {
            return false
          }
        }
      }
    }

    return true
  }, [degree?.id, degreeGroups, isChecked, selectedAssociation])

  const onChange = (platformId: number, productId?: number) => {
    const checked = isChecked(platformId, productId)

    if (!checked) {
      pushPlatform(platformId, productId)
    } else {
      removePlatform(platformId, productId)
    }
  }

  const onConfirm = () => {
    setIsLoading(true)
    SweetAlert.showLoading()

    $Platform.associate(selectedAssociation, year).then(() => {
      refresh()
      SweetAlert.fire({
        icon: 'success',
        title: 'Alunos associados com sucesso!',
        text: 'Os alunos foram associados com sucesso as plataformas selecionadas.',
      })
    }).catch(() => {
      SweetAlert.fire({
        icon: 'error',
        title: 'Erro ao associar alunos!',
        text: 'Ocorreu um erro ao associar os alunos a plataformas selecionadas.',
      })
    }).finally(() => {
      setIsLoading(false)
      SweetAlert.hideLoading()
    })
  }

  return (
    <div className="products-association mb-3">
      {degreeGroups[degree?.id]?.length > 0 && degreeGroups[degree?.id].map((items: IDegreeGroup[]) => {
        const degreeGroup: IDegreeGroup = items[0]
        const isActive: boolean = activeDegreeGroupIds.includes(degreeGroup.degreeGroupId)

        return (
          <div className="section mb-3" key={degreeGroup.degreeGroupId}>
            <div className="form-check d-flex justify-content-between align-items-center">
              <label className="form-check-label">{items[0]?.degreeGroupName}</label>
              <Toggle onClick={() => handleActiveDegreeGroupId(degreeGroup.degreeGroupId)}>
                {isActive ? (
                  <FiChevronUp size={20} color={getCssProperty('--secondary-color')} />
                ) : (
                  <FiChevronDown size={20} color={getCssProperty('--secondary-color')} />
                )}
              </Toggle>
            </div>

            {isActive && items.length > 0 && items.map((item: IDegreeGroup, index: number) => {
              const checked: boolean = isChecked(item.platformId, item.productId)
              const currentAccessAmount = checked ? item.currentAccessAmount + selectedAssociation?.students?.length : item.currentAccessAmount

              return (
                <div className="d-flex justify-content-between align-items-center pe-3" key={degreeGroup.currentDegreeId + '-' + degreeGroup.productId + '-' + index}>
                  <div className="form-check d-flex">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      style={{ minWidth: 14 }}
                      checked={checked}
                      onChange={() => onChange(item.platformId, item.productId)}
                    />
                    <label className="form-check-label">{item.productName ?? item.platformName}</label>
                  </div>

                  <div className={'badge p-1 ' + (currentAccessAmount === 0 || item.maxAccessAmount === -1 ? 'bg-secondary' : (currentAccessAmount > item.maxAccessAmount ? 'bg-danger' : 'bg-primary'))}>
                    {currentAccessAmount} {item.maxAccessAmount !== -1 ? `/ ${item.maxAccessAmount}` : ''}
                  </div>
                </div>
              )
            })}
          </div>
        )
      })}

      <button
        className="btn btn-primary"
        onClick={onConfirm}
        disabled={isLoading || !selectedAssociation?.students?.length || !selectedAssociation?.platforms?.length || !isValid}
      >Confirmar</button>
    </div>
  )
}

export default Association
