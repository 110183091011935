import React, { useCallback, useContext } from 'react'
import PanelContext from '@contexts/Panel'
import StudentsAndProductsContext from '@contexts/StudentsAndProducts'
import { IStudentSelection } from '@interfaces/IStudentSelection'
import IPlatform from '@interfaces/IPlatform'
import PlatformCount from '@components/PlatformCount/PlatformCount'
import { Tooltip } from './Student.styles'

const Student: React.FC<any> = ({ student, data }: any) => {
  const { platforms } = useContext(PanelContext)

  const { selectedAssociation, onCheck, setAssociationUpdate } = useContext(StudentsAndProductsContext)

  const handleAssociationUpdate = useCallback((platform: IPlatform) => setAssociationUpdate({
    student,
    platform,
  }), [setAssociationUpdate, student])

  return (
    <div className="student-item">
      <div className="toggle"></div>

      <div className="name">
        <label className="form-check">
          <input
            type="checkbox"
            className="form-check-input"
            checked={selectedAssociation?.students?.some((s: IStudentSelection) => s.id === student.id)}
            onChange={(e: any) => onCheck(e, student.degreeId, student.classId, student.id)}
          />
          <span>{student.nickname}</span>
        </label>
      </div>

      <div className="identifier">{student.identifier}</div>
      <div className="usercode">{student.usercode}</div>

      {platforms.filter(platform => platform?.degreeIds?.includes(student.degreeId) && !platform.isCurrentPlatform).map((platform: any) => {
        const association = data?.find((p: any) => p.platformId === platform.id)

        return (
          <Tooltip className="platform" key={platform.name}>
            <div className="tooltip-container">
              <button className="btn btn-light btn-sm"
                onClick={() => handleAssociationUpdate(platform)}
              >
                <PlatformCount count={association?.platformCount} />
              </button>
              <div className="tooltip">Atualizar associação</div>
            </div>
          </Tooltip>
        )
      })}
    </div>
  )
}

export default Student
