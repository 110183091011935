import ISchool from '@interfaces/ISchool'
import axios from 'axios'

export const login = (token: string): Promise<any> => {
  return axios.post('auth/login', {
    token,
  })
}

export const me = (token: string): Promise<any> => {
  return axios.get('auth/me', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export const setDefaultSchool = (school: ISchool): Promise<any> => {
  return axios.post('auth/set-default-school', school)
}

export const schools = (): Promise<any> => {
  return axios.get('auth/schools')
}

export const years = (schoolId: number): Promise<any> => {
  return axios.get('auth/years', {
    params: {
      schoolId,
    },
  })
}

export const permissions = (): Promise<any> => {
  return axios.get('auth/permissions', {
    timeout: 30000,
  })
}
