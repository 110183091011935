import styled from 'styled-components'

export const Container = styled.div`
  position: relative;

  > .field {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border: 1px solid #d1d1d1;
    background-color: #fff;
    cursor: default;

    > .field-container {
      padding: 8px 16px;

      > .tags {
        display: flex;
        flex-wrap: wrap;
        gap: 6px;

        > .tag {
          display: flex;
          align-items: center;
          padding: 2px 6px;
          background-color: #eee;
          border-radius: 30px;
          font-size: 0.8rem;
          gap: 4px;

          > .delete {
            width: 14px;
            height: 14px;
            border: 0;
            padding: 0;
            background-color: transparent;
            position: relative;
            top: -2px;

            > svg {
              position: relative;
              top: -3px;
            }

            &:hover > svg {
              color: #f00;
            }
          }
        }
      }
    }

    &:hover {
      border-color: var(--primary-color);
    }
  }
`

export const DropDownList = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  border: 1px solid #d1d1d1;
  background-color: #fff;
  z-index: 9;
  border-top: 0;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  padding: 1rem;

  .dropdown-item {
    > .dropdown-item-label {
      display: flex;
      align-items: center;
      gap: 2px;

      > .toggle {
        background-color: transparent;
        border: 0;
        padding: 0;
      }
    }

    > .children {
      padding-left: 3rem;
    }
  }
`

export const Trigger = styled.button`
  height: 100%;
  border: 0;
  background-color: #fff;
  border-left: 1px solid #d1d1d1;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: var(--primary-color);
    color: #fff;
  }
`

export const Placeholder = styled.div`
  color: #999;
`
