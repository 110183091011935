import axios from 'axios'

export const sendPassword = (userId: number) : Promise<any> => {
  return axios.post(`users/${userId}/send-password`)
}

export const multiSendPassword = (userIds: number[]): Promise<any> => {
  return axios.post('users/multi-send-password', userIds)
}

export const destroy = (userId: number): Promise<any> => {
  return axios.delete('users/' + userId)
}

export const multiDestroy = (usersId: number[]): Promise<any> => {
  return axios.delete('users/multi-delete', {
    data: usersId
  })
}
