import styled from 'styled-components'
import { getCssProperty } from '@helpers/Theme'

export const Container = styled.div`
  width: 100%;
  overflow-x: scroll;
  position: relative;
  margin-bottom: 16px;

  ::-webkit-scrollbar{
    width: 5px;
    height: 5px;
  }

  ::-webkit-scrollbar-thumb{
    background: #c5c6c9;
    border-radius: 0px;
  }

  ::-webkit-scrollbar-thumb:hover{
    background: #c5c6c9;
  }

  ::-webkit-scrollbar-track{
    background: #f7f7fb;
    border-radius: 0px;
    box-shadow: inset 0px 0px 0px 0px #F0F0F0;
  }

  > .header,
  > .accordion > .labels,
  > .accordion > .body > .class-item {
    display: flex;
    position: relative;

    > div {
      display: flex;
      align-items: center;
    }

    > .toggle {
      min-width: 80px;
      max-width: 80px;
      position: sticky;
      padding-left: 10px;
      z-index: 1;
      left: 0;
    }

    > .class-name {
      min-width: 140px;
      max-width: 140px;
      position: sticky;
      z-index: 1;
      left: 80px;
    }

    > .discipline {
      min-width: 150px;
      max-width: 150px;
      padding-right: 10px;
    }
  }
`

export const Header = styled.div`
  margin-bottom: 10px;

  > div {
    height: 42px;
    background-color: #fff;

    > span {
      font-weight: 500;
    }
  }
`

export const Accordion = styled.div`
  margin-bottom: 20px;
  background-color: #f7f7fb;

  &.is-active {
    > .body {
      max-height: 900px;
      border-color: #ccc;
    }
  }
`

export const Labels = styled.div`
  > div {
    height: 60px;
    background-color: #f7f7fb;
  }

  > .class-name {
    font-weight: 500;
  }
`

export const Body = styled.div`
  width: 100%;
  border-top: 1px solid #eee;

  > .class-item {
    > div {
      min-height: 42px;
      background-color: #f7f7fb;
      padding: 10px 0;
    }

    > .discipline > .teachers {
      width: 100%;
    }
  }
`

export const Toggle = styled.button`
  width: 38px;
  height: 38px;
  border-radius: 100%;
  background-color: ${getCssProperty('--light-color')};
  padding: 0;
  border: 0;
`
