import React, { useContext, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router'
import Icon from '@components/Icon/Icon'
import PanelContext from '@contexts/Panel'
import { Button, MenuItemContainer } from './MenuItem.styles'

type Props = {
  title: string
  icon: string
  path: string
}

const MenuItem: React.FC<Props> = ({ title, icon, path }: Props) => {
  const navigate = useNavigate()
  const location = useLocation()

  const { activeMenuPath, setActiveMenuPath } = useContext(PanelContext)

  useEffect(() => {
    if (location.pathname === path) {
      setActiveMenuPath(path)
    }
  }, [location.pathname, path, setActiveMenuPath])

  const handleMenuClick = () => {
    setActiveMenuPath(path)
    navigate(path)
  }

  return (
    <MenuItemContainer className="menu-item">
      <Button className={(activeMenuPath === path ? ' is-active' : '')} onClick={handleMenuClick}>
        <div className="btn-inner">
          <div className="icon">
            <Icon name={icon} />
          </div>

          <div className="title">{title}</div>
        </div>
      </Button>
    </MenuItemContainer>
  )
}

export default MenuItem
