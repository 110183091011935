import { useEffect } from 'react'
import * as Theme from '../helpers/Theme'
import IClient from '@interfaces/IClient'
import { hexToRgb } from '@helpers/Utils'

const useTheme = (client: IClient): void => {
  useEffect(() => {
    if (client) {
      const primaryColor = client?.primaryColor ?? Theme.getCssProperty('--primary-color')
      const secondaryColor = client?.secondaryColor ?? Theme.getCssProperty('--secondary-color')

      Theme.setProperty('--primary-color', primaryColor)
      Theme.setProperty('--secondary-color', secondaryColor)
      Theme.setProperty('--primary-color-rgb', hexToRgb(primaryColor))
      Theme.setProperty('--secondary-color-rgb', hexToRgb(secondaryColor))
    }
  }, [client])
}

export default useTheme
