import React, {useState, useMemo} from 'react'
import IUserLog from '@interfaces/IUserLog'
import moment from 'moment'
import Pagination from '@components/Pagination/Pagination'
import { usePagination, useSortBy, useTable } from 'react-table'
import columns from '../../data/ReactTableUserLogsColumns'

const actions: any = {
  update: 'Atualização',
  create: 'Criação',
  delete: 'Exclusão',
}

const amountList: number[] = [10, 25, 50, 100]

const UserLogs: React.FC<any> = ({ data }: any) => {
  const [ search, setSearch ] = useState<string>('')
  const [ dateDe, setDateDe ] = useState<string>('')
  const [ datePara, setDatePara ] = useState<string>('')
  const [ action, setAction ] = useState<string>('')

  const filterData = useMemo(() => {
    return data.filter((student: IUserLog) => {
      let exists = true

      if (search.length > 0) {
        exists = student.userName.toLowerCase().includes(search.toLowerCase())
      }

      if (exists && dateDe.length > 0)
        exists = +new Date(student.createdAt) - +new Date(dateDe) > 0

      if (exists && datePara.length > 0)
        exists = +new Date(datePara) - +new Date(student.createdAt) > 0

      if (exists && action.length > 0)
        exists = student.action.includes(action)

      return exists
    })
  }, [data, search, dateDe, datePara, action])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: {
      pageIndex,
    },
  }: any = useTable({
    columns,
    data: filterData,
    initialState: {
      pageIndex: 0,
      pageSize: 10,
    } as any,
  }, useSortBy, usePagination)

  return (
    <>
      {data.length > 0 ? (
        <div className="row align-items-end mb-3">
          <div className="col-12 col-md-7">
            <div className="row">
              <div className="col-12 col-md-4">
                <div className="form-group mb-3">
                  <label htmlFor="dateDe">De:</label>
                  <input type="date" id="dateDe" name="dateDe" className="form-control" onChange={e => setDateDe(e.target.value)} />
                </div>

                <div className="form-group mb-3">
                  <label htmlFor="datePara">Para:</label>
                  <input type="date" id="datePara" name="datePara" className="form-control" onChange={e => setDatePara(e.target.value)} />
                </div>
              </div>

              <div className="col-12 col-md-4">
                <div className="form-group mb-3">
                  <label htmlFor="action">Tipo:</label>
                  <select id="action" name="action" className="form-control" onChange={e => setAction(e.target.value)}>
                    <option value="">Todos</option>
                    <option value="update">Atualização</option>
                    <option value="create">Criação</option>
                    <option value="delete">Exclusão</option>
                  </select>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="form-group mb-3">
                  <label htmlFor="search">Pesquisar:</label>
                  <input type="text" id="search" name="search" className="form-control" placeholder="Pesquise pelo nome" onChange={e => setSearch(e.target.value)} />
                </div>
              </div>
            </div>
          </div>

          {data.length >= amountList[1] ? (
            <div className="col-12 col-md-4 d-flex justify-content-end">
              <div className="row">
                <div className="form-group mb-3">
                  <label htmlFor="showList">Exibir:</label>
                  <select id="showList" name="showList" className="form-control" onChange={e => setPageSize(parseInt(e.target.value))}>
                    {amountList.map((amount) => {
                      return (data.length >= amount ? <option value={amount} key={amount.toString()}>{amount}</option> : null)
                    })}
                  </select>
                </div>
              </div>
            </div>
          ) : null }
        </div>
      ) : null}

      <div className="card">
        <div className="card-header">
          Histórico de alterações
        </div>

        <div className="card-body p-0">
          <div className="table-responsive">
            <table {...getTableProps()} className="table table-striped table-dashed mb-0">
              <thead>
                {headerGroups.map((headerGroup: any, headerGroupIndex: number) => (
                  <tr {...headerGroup.getHeaderGroupProps()} key={headerGroupIndex}>
                    {headerGroup.headers.map((column: any, columnIndex: number) => (
                      <th {...column.getHeaderProps(column.getSortByToggleProps())} key={columnIndex}>
                        {column.render('Header')}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>

              <tbody {...getTableBodyProps()} >
                {data.length > 0 ? page.map((item : any, itemIndex: number) => {
                  prepareRow(item)
                  return (
                    <tr key={itemIndex}>
                      <td>{item.values.userName}</td>
                      <td>{item.values.columnName}</td>
                      <td>{item.values.oldValue}</td>
                      <td>{item.values.newValue}</td>
                      <td>{actions[item.values.action]}</td>
                      <td>{moment(item.values.createdAt).format('D/MM/YYYY [ás] H:mm:ss')}</td>
                    </tr>)
                }) : (
                  <tr>
                    <td colSpan={4}>Nenhuma alteração encontrada.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        {pageCount > 1 && (
          <div className="card-footer d-flex justify-content-between align-items-center">
            <div>
              <Pagination
                page={pageIndex}
                pageCount={pageCount}
                canPreviousPage={canPreviousPage}
                canNextPage={canNextPage}
                nextPage={nextPage}
                previousPage={previousPage}
                onClick={(page: number) => gotoPage(page)}
              />
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default UserLogs
