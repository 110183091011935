import React, { useContext, useMemo, useState } from 'react'
import * as Utils from '@helpers/Utils'
import AuthContext from '@contexts/Auth'
import { useNavigate } from 'react-router-dom'
import Pagination from '@components/Pagination/Pagination'
import { usePagination, useSortBy, useTable } from 'react-table'
import { Spinner } from 'react-bootstrap'
import { FaSort, FaSortDown, FaSortUp } from 'react-icons/fa'
import Permission from '@enums/Permission.enum'
import { PasswordProvider } from '@contexts/Password'
import useTeacherColumns from '@hooks/react-table/useTeacherColumns'
import TeachersContext from '@contexts/Teachers'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import { getCssProperty } from '@helpers/Theme'
import ITeacher from '@interfaces/ITeacher'
import * as $User from '@services/User'

const Teachers: React.FC<any> = () => {
  const { permissions, year } = useContext(AuthContext)
  const { teachers, setTeachers, selected, setSelected, isLoading } = useContext(TeachersContext)

  const [ search, setSearch ] = useState<string>('')

  const navigate = useNavigate()
  const columns = useTeacherColumns()
  const SweetAlert = withReactContent(Swal)

  const data = useMemo(() => {
    return teachers.filter((teacher: ITeacher) => {
      let exists = true

      if (search.length > 0) {
        exists = teacher.name.toLowerCase().includes(search.toLowerCase()) ||
          teacher.nickname.toLowerCase().includes(search.toLowerCase()) ||
          teacher.email === search
      }

      return exists
    })
  }, [teachers, search])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: {
      pageIndex,
    },
  }: any = useTable({
    columns,
    data,
    initialState: {
      pageIndex: 0,
      pageSize: 10,
    } as any,
  }, useSortBy, usePagination)

  const exportTeachers = () => {
    const data = teachers.map((teacher: ITeacher) => [
      teacher.name,
      teacher.nickname,
      teacher.email,
      teacher.password && teacher.password?.length > 20 ? 'Senha alterada' : teacher.password,
      teacher.disciplines?.split(',')?.join(' / '),
      teacher.classes?.split(',')?.join(' / '),
      teacher.platforms?.split(',')?.join(' / '),
    ])

    data.unshift([
      'Nome', 'Apelido', 'Email', 'Senha', 'Disciplinas', 'Turmas', 'Produtos',
    ])

    Utils.exportXLSX(data as string[][], 'Professores', 'Professores.xlsx')
  }

  const handleDelete = () => {
    SweetAlert.fire({
      title: 'Tem certeza?',
      text: `Deseja prosseguir com a exclusão de ${selected.length} professor${selected.length === 1 ? '' : 'es'}? Você não poderá voltar atrás dessa decisão.`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: getCssProperty('--secondary-color'),
      cancelButtonColor: getCssProperty('--primary-color'),
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    }).then(result => {
      if (result.isConfirmed) {
        SweetAlert.fire({
          title: 'Aguarde...',
          didOpen: () => SweetAlert.showLoading(),
        })

        $User.multiDestroy(selected).then(() => {
          setSelected([])
          setTeachers(teachers.filter((teacher: ITeacher) => !selected.includes(teacher.id)))

          SweetAlert.fire({
            title: 'Sucesso!',
            text: 'Professores excluídos com sucesso!',
            icon: 'success',
            confirmButtonColor: getCssProperty('--secondary-color'),
          })
        }).catch(() => SweetAlert.fire({
          title: 'Erro!',
          text: 'Ocorreu um erro ao excluir os professores. Tente novamente mais tarde.',
          icon: 'error',
          confirmButtonColor: getCssProperty('--secondary-color'),
        }))
      }
    })
  }

  return (
    <>
      <div className="row align-items-end">
        <div className="col-12 col-md-4">
          <div className="form-group mb-3">
            <label htmlFor="search">Pesquisar:</label>
            <input type="text" id="search" name="search" className="form-control" placeholder="Pesquise pelo nome ou e-mail" onChange={e => setSearch(e.target.value)} />
          </div>
        </div>

        <div className="col-12 col-md-4 d-flex align-items-end mb-3">
          <button className="btn btn-outline-danger position-relative pe-3 ps-3 me-4" disabled={!selected.length} onClick={handleDelete}>
            Excluir selecionados
            <span className={'position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger ' + (!selected.length ? 'd-none' : '')}>
              {selected.length}
            </span>
          </button>
        </div>

        <div className="col-12 col-md-4 d-flex justify-content-end align-items-end mb-3">
          {permissions.includes(Permission.EDIT_TEACHERS) && (
            <button className="btn btn-outline-primary pe-3 ps-3" onClick={exportTeachers}>Exportar</button>
          )}

          {permissions.includes(Permission.CREATE_TEACHERS) && year === new Date().getFullYear() && (
            <button className="btn btn-primary pe-3 ps-3 ms-2" onClick={() => navigate('/professores/cadastrar')}>Cadastrar</button>
          )}
        </div>
      </div>

      <div className="card">
        <div className="card-header">Professores ({teachers.length})</div>

        <div className="card-body p-0">
          {!isLoading ? (teachers.length ? (
            <div className="table-responsive">
              <PasswordProvider>
                <table {...getTableProps()} className="table table-default mb-0">
                  <thead>
                    {headerGroups.map((headerGroup: any) => {
                      const { key, ...props } = headerGroup.getHeaderGroupProps()

                      return (
                        <tr {...props} key={key}>
                          {headerGroup.headers.map((column: any) => (
                            <th
                              {...column.getHeaderProps({
                                ...column.getSortByToggleProps(),
                                style: {
                                  ...column.getHeaderProps().style,
                                  ...column.style,
                                }
                              })}
                              disabled={isLoading}
                              key={column.id}
                            >
                              {column.render('Header')}
                              {column.canSort && (!column.isSorted ? (
                                <FaSort className="ms-1" size={9} color="#707ca1" />
                              ) : column.isSortedDesc ? (
                                <FaSortDown className="ms-1" size={9} color="#00b7ff" />
                              ) : (
                                <FaSortUp className="ms-1" size={9} color="#00b7ff" />
                              ))}
                            </th>
                          ))}
                        </tr>
                      )
                    })}
                  </thead>

                  <tbody {...getTableBodyProps()}>
                    {page.map((row: any) => {
                      prepareRow(row)

                      return (
                        <tr {...row.getRowProps()} key={row.id}>
                          {row.cells.map((cell: any, index: number) => (
                            <td
                              align="middle"
                              {...cell.getCellProps({
                                style: {
                                  ...cell.getCellProps().style,
                                  ...cell.column.style,
                                },
                              })}
                              key={`cell-${index}`}
                            >
                              {cell.render('Cell')}
                            </td>
                          ))}
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </PasswordProvider>
            </div>
          ) : (
            <div className="bg-light text-center p-3">
              Nenhum professor cadastrado.
            </div>
          )) : (
            <div className="d-flex justify-content-center p-3 bg-light">
              <Spinner animation="border" variant="primary" />
            </div>
          )}
        </div>

        {pageCount > 1 && (
          <div className="card-footer d-flex justify-content-end">
            <Pagination
              page={pageIndex}
              pageCount={pageCount}
              canPreviousPage={canPreviousPage}
              canNextPage={canNextPage}
              nextPage={nextPage}
              previousPage={previousPage}
              onClick={(page: number) => gotoPage(page)}
            />
          </div>
        )}
      </div>
    </>
  )
}

export default Teachers
