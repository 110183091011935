import { getCssProperty } from '@helpers/Theme'
import styled from 'styled-components'

export const Toggle = styled.button`
  width: 22px;
  height: 22px;
  border-radius: 100%;
  background-color: ${getCssProperty('--light-color')};
  padding: 0;
  border: 0;
`
