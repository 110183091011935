import React, { useCallback, useContext, useMemo } from 'react'
import Password from '@components/Password/Password'
import TeachersContext from '@contexts/Teachers'
import { Link } from 'react-router-dom'
import ITeacher from '@interfaces/ITeacher'
import TeacherClasses from '@components/Teacher/TeacherClasses/TeacherClasses'

const useTeacherColumns: any = () => {
  const { teachers, selected, setSelected } = useContext(TeachersContext)

  const handleCheckboxChange = useCallback(({ target: { checked, value } }: React.ChangeEvent<HTMLInputElement>) => {
    const id = Number(value)

    if (checked) {
      setSelected([...selected, id])
    } else {
      setSelected(selected.filter((i: number) => i !== id))
    }
  }, [selected, setSelected])

  return useMemo(() => [
    {
      accessor: 'cb',
      disableSortBy: true,
      Header: () => (
        <input
          type="checkbox"
          className="form-check-input"
          checked={selected.length === teachers.length && teachers.length > 0}
          onChange={({ target: { checked } }) => {
            if (checked) {
              setSelected(teachers.map((teacher: ITeacher) => teacher.id) as number[])
            } else {
              setSelected([])
            }
          }}
        />
      ),
      Cell: ({ row: { original: { id } } }: any) => (
        <input
          type="checkbox"
          className="form-check-input"
          checked={selected.includes(id)}
          onChange={handleCheckboxChange}
          value={id}
        />
      )
    },
    {
      Header: 'Nome',
      accessor: 'name',
      Cell: ({ row: { original: { id, name } } }: any) => {
        return (
          <>
            <div>{name}</div>
            <Link to={`/professores/${id}/editar`} className="text-decoration-none">Ver ou editar &gt;</Link>
          </>
        )
      }
    },
    {
      Header: 'Apelido',
      accessor: 'nickname',
    },
    {
      Header: 'E-mail',
      accessor: 'email',
      Cell: ({ row: { original: { id, password, email } } }: any) => {
        return (
          <>
            <div>{email}</div>
            <Password userId={id} passwordLength={password} disabled={email.length === 0} />
          </>
        )
      }
    },
    {
      Header: 'Disciplinas',
      Cell: ({ row: { original: { id, disciplines } } }: any) => {
        return disciplines?.length > 0 && disciplines.split(',').map((discipline: string, index: number) => (
          <div className="badge badge-tag mb-1 me-1" key={`${id}-discipline-${index}`}>
            {discipline}
          </div>
        ))
      },
      style: {
        width: 160,
      }
    },
    {
      Header: 'Turmas',
      Cell: ({ row: { original: { id, classes } } }: any) => <TeacherClasses teacherId={id} classes={classes?.split(',') ?? []} />,
      style: {
        width: 200,
      }
    },
    {
      Header: 'Produtos',
      Cell: ({ row: { original: { id, platforms } } }: any) => {
        return platforms?.length > 0 && platforms.split(',').map((platform: string, index: number) => (
          <div className="badge badge-tag mb-1 me-1" key={`${id}-platform-${index}`}>
            {platform}
          </div>
        ))
      },
      style: {
        width: 300,
      }
    },
  ], [handleCheckboxChange, selected, setSelected, teachers])
}

export default useTeacherColumns
