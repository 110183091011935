import React, { useContext, useRef, useState } from 'react'
import StudentsAndProductsContext from '@contexts/StudentsAndProducts'
import { Spinner } from 'react-bootstrap'

const Search: React.FC<any> = () => {
  const { isLoading, setIsLoading, doSearch } = useContext(StudentsAndProductsContext)
  const [ isSearch, setIsSearch ] = useState<boolean>(false)
  const [ search, setSearch ] = useState<string>('')

  const searchRef = useRef<any>(null)

  const handleSearch = () => {
    setIsLoading(true)
    setIsSearch(search.length > 0)
    doSearch(search)
  }

  const clearSearch = () => {
    setSearch('')
    setIsLoading(true)
    setIsSearch(false)
    doSearch('')
    if (searchRef.current) {
      searchRef.current.value = ''
    }
  }

  return (
    <div className="input-group mb-3">
      <input type="text" ref={searchRef} className="form-control" placeholder="Pesquisar pelo aluno" onChange={e => setSearch(e.target.value)} disabled={isLoading} />
      {isSearch && (
        <button className="btn btn-outline-primary pe-3 ps-3" onClick={clearSearch} disabled={isLoading}>Limpar</button>
      )}
      <button className="btn btn-primary pe-3 ps-3" onClick={handleSearch} disabled={isLoading}>
        {isLoading ? (
          <>
            Aguarde <Spinner animation="border" size="sm" />
          </>
        ) : 'Pesquisar'}
      </button>
    </div>
  )
}

export default Search
