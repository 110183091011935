import React, { useContext, useState } from 'react'
import { AiOutlineDownload } from 'react-icons/ai'
import * as $Utils from '@helpers/Utils'
import * as XLSX from 'xlsx'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import { Alert } from 'react-bootstrap'
import RegistrationContext from '@contexts/Registration'
import RegistrationStepEnum from '@enums/RegistrationStep.enum'
import IRegistrationEntity from '@interfaces/IRegistrationEntity'
import Button from '@components/Button/Button'

const Upload: React.FC<any> = ({ instruction }: any) => {
  const { entities, storeEntities, setStep, isLoading, setIsLoading, registrationTypeAction, additionalData } = useContext(RegistrationContext)

  const [ file, setFile ] = useState<File|null>(null)
  const [ uploaded, setUploaded ] = useState<boolean>(false)

  const SweetAlert = withReactContent(Swal)

  const nextStep = () => setStep(RegistrationStepEnum.Finish)

  const upload = () => {
    setIsLoading(true)

    if (file) {
      const reader = new FileReader()

      reader.onload = (e: any) => {
        const workbook = XLSX.read(e.target.result, {
          type: 'binary'
        })

        const first_sheet_name = workbook.SheetNames[0]
        const worksheet = workbook.Sheets[first_sheet_name]

        const rows = XLSX.utils.sheet_to_json(worksheet, {
          header: 1
        }) as string[][]

        if (!registrationTypeAction?.isValid(rows[0])) {
          setIsLoading(false)
          SweetAlert.fire({
            title: 'Erro!',
            text: 'O arquivo selecionado não é válido! Utilize o modelo de arquivo disponibilizado e siga as instruções ao lado.',
            icon: 'error',
            confirmButtonText: 'Ok'
          })
          return
        }

        rows.shift()

        if (!rows.length) {
          setIsLoading(false)
          SweetAlert.fire({
            title: 'Erro!',
            text: 'O arquivo não contém dados válidos!',
            icon: 'error'
          })
          return
        }

        const entities: IRegistrationEntity[] = registrationTypeAction?.getEntities(rows, additionalData) as IRegistrationEntity[]

        storeEntities(entities).then(() => setUploaded(true)).catch(() => {
          SweetAlert.fire({
            title: 'Erro!',
            text: 'Ocorreu um erro ao processar o arquivo!',
            icon: 'error'
          })
        }).finally(() => setIsLoading(false))
      }

      reader.readAsBinaryString(file)
    }
  }

  const download = () => $Utils.exportXLSX([
    registrationTypeAction?.getLabels() as string[],
  ], 'Cadastro de usuários', 'Planilha modelo.xlsx')

  const handleFileChange = (e: any) => {
    setFile(e.target.files[0])
    setUploaded(false)
  }

  return (
    <>
      <div className="card-body">
        <div className="row">
          <div className="col-12 col-md-6">
            <h5 className="mb-0">Download da planilha modelo</h5>

            <hr/>

            <div className="input-group mb-5">
              <div className="form-control">Baixe o modelo e siga as instruções ao lado</div>
              <button className="input-group-text btn-primary" onClick={() => download()}>
                <span>Baixar modelo</span> <AiOutlineDownload size={22} />
              </button>
            </div>

            <h5 className="mb-0">Upload de planilha</h5>

            <hr/>

            <div className="input-group mb-3">
              <input
                type="file"
                id="file-input"
                className="form-control"
                onChange={handleFileChange}
                accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              />

              <Button
                className="btn btn-success text-white"
                onClick={upload}
                loadingText="Aguarde..."
                disabled={!file || isLoading}
                isLoading={isLoading}
              >Enviar arquivo</Button>
            </div>

            {!isLoading && file && uploaded && entities.length ? (
              <Alert variant="success">Arquivo: <strong>{file?.name}</strong> enviado com sucesso.</Alert>
            ) : null}
          </div>

          <div className="col-12 col-md-6">
            <h5 className="mb-0">Instruções de preenchimento</h5>
            <hr/>
            {instruction}
          </div>
        </div>
      </div>

      <div className="card-footer d-flex justify-content-end">
        <button className="btn btn-primary" onClick={nextStep} disabled={!entities.length || isLoading || !uploaded}>Continuar</button>
      </div>
    </>
  )
}

export default Upload
