import React, { createContext, useState } from 'react'

type PasswordContextProps = {
  sendedPasswords: any[]
  setSendedPasswords: React.Dispatch<React.SetStateAction<any[]>>
  isLoading: boolean
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  sendingPasswordToUserId: number|null
  setSendingPasswordToUserId: React.Dispatch<React.SetStateAction<number|null>>
}

const PasswordContext = createContext<PasswordContextProps>({ } as PasswordContextProps)

export const PasswordProvider: React.FC<any> = ({ children }: any) => {
  const [ sendedPasswords, setSendedPasswords ] = useState<any[]>([])
  const [ isLoading, setIsLoading ] = useState<boolean>(false)
  const [ sendingPasswordToUserId, setSendingPasswordToUserId ] = useState<number|null>(null)

  return (
    <PasswordContext.Provider value={{ sendedPasswords, setSendedPasswords, sendingPasswordToUserId, setSendingPasswordToUserId, isLoading, setIsLoading }}>
      {children}
    </PasswordContext.Provider>
  )
}

export default PasswordContext
