import EntityValidationErrorEnum from '@enums/EntityValidationError.enum'
import RegistrationTypeColumn from '@interfaces/IRegistrationTypeColumn'

const columns: RegistrationTypeColumn[] = [
  {
    label: 'RA',
    key: 'identifier',
    required: true,
    type: 'number',
    errors: [
      EntityValidationErrorEnum.IDENTIFIER_REQUIRED,
      EntityValidationErrorEnum.NUMERIC_IDENTIFIER_REQUIRED,
      EntityValidationErrorEnum.DUPLICATED_IDENTIFIER_FOUND,
      EntityValidationErrorEnum.IDENTIFIER_DUPLICATED_FIELD,
    ],
    worker: (value: string) : number|null => {
      return value == null || isNaN(Number(value)) ? null : Number(value)
    },
  },
  {
    label: 'Nome',
    key: 'name',
    required: true,
    type: 'string',
    errors: [
      EntityValidationErrorEnum.NAME_REQUIRED,
    ],
  },
  {
    label: 'E-mail (Não obrigatório)',
    key: 'email',
    required: false,
    type: 'email',
    placeholder: '-',
    errors: [
      EntityValidationErrorEnum.EMAIL_INVALID,
      EntityValidationErrorEnum.DUPLICATED_EMAIL_FOUND,
      EntityValidationErrorEnum.EMAIL_EXISTS,
      EntityValidationErrorEnum.EMAIL_DUPLICATED_FIELD,
    ],
    worker: (value: string) : string|null => {
      return value ? String(value).trim() : value
    },
  },
  {
    label: 'Senha (Não obrigatório)',
    key: 'password',
    required: false,
    type: 'password',
    placeholder: '****',
    worker: (value: string) : string|null => {
      return value ? String(value) : value
    },
  },
  {
    label: 'Série',
    key: 'degreeName',
    required: true,
    type: 'select',
    errors: [
      EntityValidationErrorEnum.DEGREE_REQUIRED,
      EntityValidationErrorEnum.DEGREE_NOT_FOUND,
    ],
    worker: (value: string) : string|null => {
      return value ? String(value).trim() : value
    },
  },
  {
    label: 'Turma',
    key: 'className',
    required: true,
    type: 'select',
    errors: [
      EntityValidationErrorEnum.CLASS_REQUIRED,
      EntityValidationErrorEnum.CLASS_NOT_FOUND,
    ],
    worker: (value: string) : string|null => {
      return value ? String(value).trim() : value
    },
  },
  {
    label: 'Ano',
    key: 'year',
    required: false,
    type: 'number',
    errors: [],
    display: false,
  }
]

export default columns
