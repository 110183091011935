import React, { useContext, useEffect, useState } from 'react'
import * as $Platform from '@services/Platform'
import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai'
import { getCssProperty } from '@helpers/Theme'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import { IManagerAssociation } from '@interfaces/IManagerAssociation'
import { Spinner } from 'react-bootstrap'
import AuthContext from '@contexts/Auth'
import IPlatform from '@interfaces/IPlatform'
import axios from 'axios'

const Managers: React.FC<any> = () => {
  const { year } = useContext(AuthContext)

  const [ editMode, setEditMode ] = useState<boolean>(false)
  const [ managers, setManagers ] = useState<any>([])
  const [ associations, setAssociations ] = useState<IManagerAssociation[]>([])
  const [ isLoading, setIsLoading ] = useState<boolean>(true)
  const [ platforms, setPlatforms ] = useState<IPlatform[]>([])

  const SweetAlert = withReactContent(Swal)

  useEffect(() => {
    setIsLoading(true)

    axios.all([
      $Platform.all(),
      $Platform.managers(),
    ]).then(axios.spread(({ data: platforms }, { data: managers }) => {
      setPlatforms(platforms)
      setManagers(managers)
    })).finally(() => setIsLoading(false))
  }, [])

  const onCheck = (e: any, managerId: number, platformId: number) => {
    const { checked } = e.target

    const index = associations.findIndex((item: IManagerAssociation) => item.managerId === managerId)

    const platformIds = managers[managerId].filter((association: any) => association.platformId != 0).map((association: any) => association.platformId)

    const association: IManagerAssociation = associations[index] ?? {
      managerId: managerId,
      platformIds,
    }

    if (checked) {
      if (index === -1) {
        association.platformIds.push(platformId)
        setAssociations([ ...associations, association ])
      } else {
        setAssociations([
          ...associations.slice(0, index),
          {
            ...association,
            platformIds: [
              ...association.platformIds,
              platformId,
            ],
          },
          ...associations.slice(index + 1),
        ])
      }
    } else {
      association.platformIds = association.platformIds.filter((id: number) => id !== platformId)

      if (index === -1) {
        setAssociations([ ...associations, association ])
      } else {
        setAssociations([
          ...associations.slice(0, index),
          association,
          ...associations.slice(index + 1),
        ])
      }
    }
  }

  const cancel = () => setEditMode(false)

  const save = () => {
    SweetAlert.fire({
      title: 'Atualizado gestores...',
      didOpen: () => SweetAlert.showLoading()
    })

    $Platform.managerAssociations(associations).then(() => {
      setAssociations([])

      $Platform.managers().then(({ data }: any) => setManagers(data))

      SweetAlert.fire({
        title: 'Gestores atualizados com sucesso!',
        icon: 'success',
        didOpen: () => SweetAlert.hideLoading(),
      }).then(() => setEditMode(false))
    }).catch(() => {
      SweetAlert.fire({
        title: 'Erro ao atualizar gestores!',
        icon: 'error',
        didOpen: () => SweetAlert.hideLoading(),
      })
    })
  }

  return (
    <div className="card">
      <div className="card-header d-flex justify-content-between">
        <div>Gestores</div>
        {!editMode && year === new Date().getFullYear() && (
          <button className="btn btn-link" onClick={() => setEditMode(true)}>Editar gestores &gt;</button>
        )}
      </div>

      <div className="card-body pb-0">
        {!isLoading && Object.entries(managers).length > 0 ? (
          <div className="table-responsive">
            <table className="table table-default mb-3">
              <thead>
                <tr>
                  <th style={{ width: 160 }}>Nome</th>
                  {platforms.map((platform: any) => (
                    <th style={{ width: 80 }} key={`platform-${platform.id}`}>{platform.name}</th>
                  ))}
                </tr>
              </thead>

              <tbody>
                {Object.entries(managers).map(([ managerId, associations ]: any) => {
                  const manager = associations[0]

                  return (
                    <tr key={`manager-${managerId}`}>
                      <td>{manager?.nickname}</td>
                      {platforms.map((platform: any) => (
                        <td key={platform.id}>
                          {!editMode ? (
                            <>
                              {associations.find((association: any) => association.platformId == platform.id) ? (
                                <AiFillCheckCircle size={18} color={getCssProperty('--primary-color')} />
                              ) : (
                                <AiFillCloseCircle size={18} color="#999" />
                              )}
                            </>
                          ) : (
                            <input
                              type="checkbox"
                              defaultChecked={associations.find((association: any) => association.platformId == platform.id) != null}
                              onChange={(e: any) => onCheck(e, manager.id, platform.id)}
                              disabled={isLoading}
                            />
                          )}
                        </td>
                      ))}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        ) : isLoading ? (
          <div className="d-flex justify-content-center p-3 bg-light mb-3">
            <Spinner animation="border" variant="primary" />
          </div>
        ) : (
          <div className="d-flex justify-content-center p-3 bg-light mb-3">
            <div className="text-center">Nenhum gestor cadastrado.</div>
          </div>
        )}

        {editMode && year === new Date().getFullYear() && (
          <div className="d-flex justify-content-end mb-3">
            <button className="btn btn-outline-primary me-3" onClick={cancel} disabled={isLoading}>Cancelar</button>
            <button className="btn btn-primary" onClick={save} disabled={isLoading}>Salvar</button>
          </div>
        )}
      </div>
    </div>
  )
}

export default Managers
