import React, { useContext } from 'react'
import UserRegistrationContext from '@contexts/UserRegistration'
import Button from '@components/Button/Button'
import Download from './Download/Download'
import Upload from './Upload/Upload'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Tab, Tabs } from 'react-bootstrap'
import * as $MassRegistration from '@services/MassRegistration'
import Table from './Table/Table'

const UserRegistration: React.FC<any> = () => {
  const { token, entities, setEntities, isLoading, setIsLoading } = useContext(UserRegistrationContext)

  const SweetAlert = withReactContent(Swal)

  const students = entities.filter(entity => {
    return entity.role == 1 || entity.role.toString().toLowerCase() === 'aluno'
  })

  const managers = entities.filter(entity => {
    return entity.role == 2 || entity.role.toString().toLowerCase() === 'gestor'
  })

  const teachers = entities.filter(entity => {
    return entity.role == 3 || entity.role.toString().toLowerCase() === 'professor'
  })

  const revisors = entities.filter(entity => {
    return entity.role == 4 || entity.role.toString().toLowerCase() === 'corretor'
  })

  const upload = () => {
    setIsLoading(true)

    SweetAlert.fire({
      title: 'Aguarde',
      text: 'Cadastrando usuários...',
      icon: 'info',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => SweetAlert.showLoading()
    })

    $MassRegistration.store(token, entities).then(() => {
      SweetAlert.fire({
        title: 'Sucesso!',
        text: 'Usuários cadastrados com sucesso!',
        icon: 'success',
      })
    }).catch(() => {
      SweetAlert.fire({
        title: 'Erro!',
        text: 'Erro ao cadastrar usuários, tente novamente!',
        icon: 'error',
      })
    }).finally(() => {
      setIsLoading(false)
      SweetAlert.hideLoading()
    })
  }

  const deleteAll = () => {
    SweetAlert.fire({
      title: 'Tem certeza?',
      text: 'Prosseguir e limpar todos os registros?',
      icon: 'warning',
    }).then((result) => {
      if (result.value) {
        setEntities([])
      }
    })
  }

  return (
    <>
      <div className="card">
        {entities.length ? (
          <Tabs defaultActiveKey="students" className="mb-3">
            <Tab eventKey="students" title={'Alunos (' + students.length + ')'}>
              <Table entities={students} />
            </Tab>
            <Tab eventKey="managers" title={'Gestores (' + managers.length + ')'}>
              <Table entities={managers} />
            </Tab>
            <Tab eventKey="teachers" title={'Professores (' + teachers.length + ')'}>
              <Table entities={teachers} />
            </Tab>
            <Tab eventKey="revisors" title={'Corretores (' + revisors.length + ')'}>
              <Table entities={revisors} />
            </Tab>
          </Tabs>
        ) : (
          <>
            <div className="card-header">
              Cadastro de usuários em massa
            </div>

            <div className="card-body">
              <div className="row pt-5 pb-5">
                <div className="col-12 col-md-8 offset-md-2 text-center">
                  <div className="d-flex align-items-center">
                    <Download />
                    <Upload />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        <div className="card-footer d-flex justify-content-end">
          <button className="btn btn-danger me-2" onClick={deleteAll}>Descartar tudo</button>

          <Button
            className="btn btn-primary"
            disabled={isLoading || !entities?.length}
            isLoading={isLoading}
            onClick={upload}
          >
            Associar usuários
          </Button>
        </div>
      </div>
    </>
  )
}

export default UserRegistration
