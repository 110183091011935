import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Nav, Spinner, Tab } from 'react-bootstrap'
import Disassociation from './Components/Disassociation/Disassociation'
import Association from './Components/Association/Association'
import StudentsAndProductsContext from '@contexts/StudentsAndProducts'
import Degree from './Components/Degree/Degree'
import IDegree from '@interfaces/IDegree'
import Update from './Components/Update/Update'
import { Buttons } from './StudentsAndProducts.styles'
import Search from './Components/Search/Search'
import AuthContext from '@contexts/Auth'

const StudentsAndProducts: React.FC<any> = () => {
  const { year } = useContext(AuthContext)
  const { students, degrees, setDegree, isLoading, selectedAssociation, setSelectedAssociation } = useContext(StudentsAndProductsContext)
  const [ showAssociation, setShowAssociation ] = useState<boolean>(true)

  useEffect(() => {
    if (!selectedAssociation?.students?.length && showAssociation) {
      setShowAssociation(false)
      setSelectedAssociation({
        ...selectedAssociation,
        platforms: []
      })
    }
  }, [showAssociation, selectedAssociation, setSelectedAssociation])

  const handleShowAssociation = () => {
    if (!showAssociation) {
      setShowAssociation(true)
    } else {
      setShowAssociation(false)
      setSelectedAssociation({
        ...selectedAssociation,
        platforms: []
      })
    }
  }

  const onTabSelect = (degreeId: number) => {
    setDegree(degrees.find((degree: any) => degree.id === degreeId) as IDegree)
  }

  const _degrees = useMemo((): IDegree[] => {
    return degrees.filter((degree: IDegree) => {
      return students != null && students[degree.id] != null && Object.keys(students[degree.id]).length > 0 && degree?.classes?.length > 0
    })
  }, [degrees, students])

  return (
    <div className="students-and-products-page">
      <Update />

      <div className="row d-flex justify-content-end">
        <div className="col-12 col-md-4">
          <Search />
        </div>
      </div>

      <div className="card mb-4">
        {degrees?.length > 0 ? (
          <Tab.Container defaultActiveKey={degrees[0].id} onSelect={(eventKey: string|null) => onTabSelect(parseInt(eventKey as string))}>
            <div className="card-header d-flex justify-content-between align-items-center p-0 has-tabs">
              <Nav variant="tabs">
                {_degrees.map((degree: any) => (
                  <Nav.Item key={degree.id}>
                    <Nav.Link eventKey={degree.id} disabled={isLoading || showAssociation}>
                      {degree.shortname}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </div>

            <div className="card-body pb-0">
              <div className="row">
                <div className={showAssociation ? 'col-10' : 'col-12'}>
                  <Tab.Content>
                    {_degrees.map((degree: IDegree) => (
                      <Tab.Pane eventKey={degree.id} key={degree.id}>
                        <div className="row mb-3 pt-3">
                          <div className="col-12 col-md-2">
                            {degree?.classes?.length > 0 && (
                              <div className="form-group mb-3">
                                <select className="form-control">
                                  <option value="">Turma</option>
                                  {degree.classes.map((class_: any) => (
                                    <option key={class_.id} value={class_.id}>{class_.name}</option>
                                  ))}
                                </select>
                              </div>
                            )}
                          </div>

                          {year === new Date().getFullYear() && (
                            <div className="col-12 col-md-10 mb-3 d-flex justify-content-end">
                              <Buttons className="d-flex align-items-center">
                                <button
                                  className={'btn me-2 btn' + (showAssociation ? '-primary' : '-outline-primary')}
                                  disabled={isLoading || !selectedAssociation?.students?.length}
                                  onClick={handleShowAssociation}
                                >Associar em Massa</button>

                                <Disassociation disabled={showAssociation} />
                              </Buttons>
                            </div>
                          )}
                        </div>

                        <hr />

                        {!isLoading ? (
                          <>
                            {students[degree.id] != null && Object.keys(students[degree.id]).length > 0 && degree.classes.length > 0 ? (
                              <Degree degree={degree} />
                            ) : (
                              <div className="alert bg-light text-center">Nenhum aluno cadastrado nessa série.</div>
                            )}
                          </>
                        ) : (
                          <div className="alert bg-light text-center">
                            Aguarde <Spinner animation="border" size="sm" />
                          </div>
                        )}
                      </Tab.Pane>
                    ))}
                  </Tab.Content>
                </div>

                <div className={showAssociation ? 'col-2' : 'd-none'}>
                  <Association />
                </div>
              </div>
            </div>
          </Tab.Container>
        ) : (
          <div className="card-body pb-0">
            {isLoading ? (
              <div className="d-flex justify-content-center p-3 bg-light mb-3">
                <Spinner animation="border" variant="primary" />
              </div>
            ) : (
              <div className="alert bg-light text-center">Não há resultados a serem exibidos.</div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default StudentsAndProducts
