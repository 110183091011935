import styled from 'styled-components'
import { getCssProperty } from '@helpers/Theme'

export const Header = styled.div`
  margin-bottom: 10px;

  > div {
    font-weight: 500;
  }
`

export const Labels = styled.div`
  > div {
    height: 60px;
    background-color: #f7f7fb;
  }
`

export const Accordion = styled.div`
  margin-bottom: 20px;
  background-color: #f7f7fb;
`

export const Body = styled.div`
  width: 100%;
  border-top: 1px solid #eee;

  > .student-item {
    > div {
      min-height: 50px;
      background-color: #f7f7fb;
      padding: 10px 0;
    }
  }
`

export const Container = styled.div`
  overflow-x: scroll;

  strong {
    font-weight: 500;
  }

  ${Header},
  ${Labels},
  ${Body} > .student-item {
    display: flex;
    justify-content: space-between;

    > div {
      width: 100%;
      padding: 0 5px;
    }

    > .toggle {
      min-width: 80px;
      max-width: 80px;
      padding-left: 10px;

      @media (max-width: 992px) {
        width: 80px;
      }
    }

    > .name {
      min-width: 220px;

      @media (max-width: 992px) {
        width: 220px;
        min-width: 220px;
      }
    }

    > .identifier,
    > .usercode {
      max-width: 80px;

      @media (max-width: 992px) {
        width: 80px;
        min-width: 80px;
      }
    }
  }

  ${Labels} > div,
  ${Body} > .student-item > div {
    display: flex;
    align-items: center;
  }

  ${Header} > .platform,
  ${Labels} > .platform,
  ${Body} > .student-item > .platform {
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 992px) {
      width: 110px;
      min-width: 110px;
    }
  }

  ${Labels} > .platform,
  ${Body} > .student-item > .platform {
    position: relative;

    > span {
      position: absolute;
      right: calc(50% + 12px);
      transform: translateY(-50%);
      top: 50%;
    }
  }

  @media (max-width: 992px) {
    .platform > .btn {
      padding: .25rem .5rem;
    }
  }
`

export const Toggle = styled.button`
  width: 38px;
  height: 38px;
  border-radius: 100%;
  background-color: ${getCssProperty('--light-color')};
  padding: 0;
  border: 0;
`
