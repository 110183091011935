import { useState } from 'react'
import IClient from '@interfaces/IClient'
import ISchool from '@interfaces/ISchool'
import IUser from '@interfaces/IUser'
import useStickyState from './useStickyState'

export interface IAuth {
  user: IUser
  setUser: React.Dispatch<React.SetStateAction<IUser>>
  token: string
  setToken: React.Dispatch<React.SetStateAction<string>>
  client: IClient
  setClient: React.Dispatch<React.SetStateAction<IClient>>
  school: ISchool
  setSchool: React.Dispatch<React.SetStateAction<ISchool>>
  isAuthenticated: boolean
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>
  permissions: string[]
  setPermissions: React.Dispatch<React.SetStateAction<string[]>>
  isLoading: boolean
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  appId: string
  setAppId: React.Dispatch<React.SetStateAction<string>>
}

const useAuth = (): IAuth => {
  const [ user, setUser ] = useStickyState<IUser>({} as IUser, 'user')
  const [ token, setToken ] = useStickyState<string>('', 'token')
  const [ client, setClient ] = useStickyState<IClient>({} as IClient, 'client')
  const [ school, setSchool ] = useStickyState<ISchool>({} as ISchool, 'school')
  const [ appId, setAppId ] = useStickyState<string>('', 'appId')
  const [ isAuthenticated, setIsAuthenticated ] = useState<boolean>(false)
  const [ permissions, setPermissions ] = useState<string[]>([])
  const [ isLoading, setIsLoading ] = useState<boolean>(true)

  return {
    user,
    setUser,
    token,
    setToken,
    client,
    setClient,
    school,
    setSchool,
    isAuthenticated,
    setIsAuthenticated,
    permissions,
    setPermissions,
    isLoading,
    setIsLoading,
    appId,
    setAppId
  } as IAuth
}

export default useAuth
