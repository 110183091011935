import axios from 'axios'
import IRegistrationEntity from '@interfaces/IRegistrationEntity'

export const all = (token: string) : Promise<any> => {
  return axios.get(`users/entity-registration/${token}`)
}

export const store = (roleId: number, token: string, entities: IRegistrationEntity[]) : Promise<any> => {
  return axios.post(`users/entity-registration/${token}`, {
    roleId,
    entities,
  })
}

export const errors = (token: string, rowId: number) : Promise<any> => {
  return axios.get(`users/entity-registration/${token}/${rowId}`)
}

export const update = (token: string, rowId: number, entity: IRegistrationEntity) : Promise<any> => {
  return axios.put(`users/entity-registration/${token}/${rowId}`, entity)
}

export const updateMany = (token: string, entities: IRegistrationEntity[]) : Promise<any> => {
  return axios.put(`users/entity-registration/${token}`, entities)
}

export const registrationDestroy = (token: string, rowId: number) : Promise<any> => {
  return axios.delete(`users/entity-registration/${token}/${rowId}`)
}

export const registrationDestroyAll = (token: string) : Promise<any> => {
  return axios.delete(`users/entity-registration/${token}`)
}

export const duplicated = (token: string, rowId: number, errorId: number) : Promise<any> => {
  return axios.get(`users/entity-registration/${token}/${rowId}/${errorId}/duplicated`)
}

export const finish = (token: string) : Promise<any> => {
  return axios.post(`users/entity-registration/${token}/import`)
}
