import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Select from '../Select/Select'
import AuthContext from '../../contexts/Auth'
import { ReactComponent as User } from './../../resources/svg/user.svg'
import PanelContext from '../../contexts/Panel'
import * as $Platform from '@services/Platform'
import * as $Auth from '@services/Auth'
import ISchool from '@interfaces/ISchool'
import { Dropdown, Spinner } from 'react-bootstrap'
import IPlatform from '@interfaces/IPlatform'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Figure, HeaderComponent } from './Header.styles'
import Logotipo from '@components/Logotipo/Logotipo'
import Permission from '@enums/Permission.enum'
import NavigationContext from '@contexts/Navigation'

const Header: React.FC = () => {
  const { hideNavigation } = useContext(NavigationContext)
  const { platforms, sidebarCollapsed } = useContext(PanelContext)
  const { client, user, school, setSchool, setIsLoading, logout, permissions, year, setYear, years, appId } = useContext(AuthContext)

  const [ schools, setSchools ] = useState<ISchool[]>([])
  const [ parsedSchools, setParsedSchools ] = useState<any[]>([])
  const [ isRedirectLoading, setIsRedirectLoading ] = useState<boolean>(false)

  const navigate = useNavigate()

  const SweetAlert = withReactContent(Swal)

  useEffect(() => {
    $Auth.schools().then(({ data }: any) => {
      setSchools(data)
      setParsedSchools(data.map(({ id, fantasyName }: ISchool) => ({ label: fantasyName, value: id })))
    })
  }, [])

  const handleSetSchool = ({ value: schoolId }: any) => {
    const school = schools.find((school: ISchool) => school.id == schoolId)

    if (school != null) {
      setIsLoading(true)
      $Auth.setDefaultSchool(school).then(() => setSchool(school)).finally(() => {
        setTimeout(() => {
          window.location.href = '/'
        }, 200)
      })
    }
  }

  const handleSetYear = ({ value: year }: any) => setYear(year)

  const redirectTo = (platform: IPlatform) => {
    setIsRedirectLoading(true)

    $Platform.redirectUrl(school.id, user.id as number, platform).then(({ data }: any) => {
      if (data?.ResultAccessUrl[0] != null) {
        window.location.href = data.ResultAccessUrl[0]
      }
    }).catch(() => {
      SweetAlert.fire({
        title: 'Ops!',
        text: 'Não é possível acessar essa plataforma no momento, tente nva vez mais tarde.',
        icon: 'error'
      })
    }).finally(() => setIsRedirectLoading(false))
  }

  const defaultSchoolValue = { label: school?.fantasyName, value: school?.id }

  const parsedYears = years.sort().reverse().map((year: number) => ({
    label: year,
    value: year
  }))

  return (
    <HeaderComponent>
      <div className="container-fluid">
        <div className="row">
          <Figure className={'logotipo col-3 col-sm-2 ' + (sidebarCollapsed ? 'col-md-1 collapsed' : 'col-md-2')}>
            <Link to="/">
              <Logotipo
                width={200}
                height={60}
                className={'logotipo logotipo-full' + (sidebarCollapsed ? '' : ' active')}
                src={client.logotipoUrl}
                color={client.primaryColor}
              />

              <Logotipo
                width={135}
                height={60}
                className={'logotipo logotipo-min' + (sidebarCollapsed ? ' active' : '')}
                src={client.logotipoMinUrl}
                color={client.primaryColor}
              />
            </Link>
          </Figure>

          <div className={'header-bar col-9 col-sm-10 ' + (sidebarCollapsed ? 'col-md-11' : 'col-md-10')}>
            <div className="env-selector">
              {parsedSchools.length > 0 && !hideNavigation && (
                <div className="select-field select-schools me-3">
                  <Select
                    type="outline"
                    onChange={handleSetSchool}
                    defaultValue={defaultSchoolValue}
                    options={parsedSchools}
                    isSearchable={true}
                    placeholder="Selecione a escola"
                  />
                </div>
              )}

              {years.length > 0 && !hideNavigation && (
                <div className="select-field select-years">
                  <Select
                    type="outline"
                    onChange={handleSetYear}
                    options={parsedYears}
                    defaultValue={{ label: year, value: year }}
                    isSearchable={true}
                    placeholder="Selecione o ano"
                  />
                </div>
              )}
            </div>

            <div className="menu">
              <div className="user">
                <Dropdown>
                  <Dropdown.Toggle variant="light">
                    <User />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item className="text">
                      <small>ID Escola</small>
                      {school.id}
                    </Dropdown.Item>

                    <Dropdown.Item className="text">
                      <small>ID Usuário</small>
                      {user.id}
                    </Dropdown.Item>

                    <Dropdown.Item className="text">
                      {user.name}
                    </Dropdown.Item>

                    <Dropdown.Divider />

                    {permissions.includes(Permission.ADMIN_EVOLUCIONAL) && (
                      <Dropdown.Item onClick={() => navigate('/historico-de-alteracoes')}>Histórico de alterações</Dropdown.Item>
                    )}
                    <Dropdown.Item onClick={logout}>Sair</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>

              <div className="platform" style={{ cursor: isRedirectLoading ? 'wait' : 'default' }}>
                <Dropdown>
                  <Dropdown.Toggle id="sidebarWeb" variant="danger">
                    Gestão de Usuários {isRedirectLoading && (
                      <Spinner animation="border" variant="light" size="sm" className="ms-2" />
                    )}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {platforms?.filter(platform => !platform.isCurrentPlatform).map((platform: IPlatform) => (
                      <Dropdown.Item key={platform.id} onClick={() => redirectTo(platform)}>{platform.name}</Dropdown.Item>
                    ))}

                    {appId?.length > 0 && (
                      <>
                        <Dropdown.Divider />
                        <a href={`https://docs.evolucional.com.br/login?appId=${appId}`} className="dropdown-item" target="_blank" rel="noreferrer">API Docs</a>
                      </>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>
    </HeaderComponent>
  )
}

export default Header
