import React, { useContext, useEffect, useState } from 'react'
import PanelContext from '@contexts/Panel'
import AuthContext from '@contexts/Auth'
import MenuItem from './MenuItem/MenuItem'
import Permission from '@enums/Permission.enum'
import { ReactComponent as Collapse } from '@resources/svg/collapse.svg'
import { Aside, CollapseButton, SidebarContainer } from './Sidebar.styles'
import * as $Reportment from '@services/Reportment'
import IReportment from '@interfaces/IReportment'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { AiOutlineReload } from 'react-icons/ai'

const Sidebar: React.FC = () => {
  const { permissions, reloadPermissions, permissionsFailure } = useContext(AuthContext)
  const { sidebarCollapsed, setSidebarCollapsed, platforms } = useContext(PanelContext)

  const [ reportments, setReportments ] = useState<IReportment[]>([])
  const [ isLoading, setIsLoading ] = useState(true)

  useEffect(() => {
    if (permissions.includes(Permission.VIEW_REPORTMENTS)) {
      $Reportment.all().then(({ data }) => setReportments(data)).finally(() => setIsLoading(false))
    } else if (permissions.length > 0) {
      setIsLoading(false)
    }
  }, [permissions])

  return (
    <SidebarContainer className={'sidebar col-12 ' + (sidebarCollapsed ? 'col-md-1 collapsed' : 'col-md-2')}>
      <Aside className="sidebar-sticky">
        {permissionsFailure?.length === 0 ? (
          <>
            <CollapseButton className="btn-collapse d-none d-md-block" onClick={() => setSidebarCollapsed(!sidebarCollapsed)}>
              <Collapse />
            </CollapseButton>

            <MenuItem title="Dashboard" icon="panel" path="/" />

            {permissions.length > 0 ? (
              <>
                {permissions.includes(Permission.VIEW_CLASS) && (
                  <MenuItem title="Turmas" icon="class" path="/turmas" />
                )}

                {permissions.includes(Permission.VIEW_STUDENTS) && (
                  <MenuItem title="Alunos" icon="student" path="/alunos" />
                )}

                {permissions.includes(Permission.VIEW_TEACHERS) && (
                  <MenuItem title="Professores" icon="teacher" path="/professores" />
                )}

                {platforms.length > 0 && platforms.some(platform => platform.id === 3) && permissions.includes(Permission.VIEW_REVISORS) && (
                  <MenuItem title="Corretores" icon="revisor" path="/corretores" />
                )}

                {permissions.includes(Permission.VIEW_MANAGERS) && (
                  <MenuItem title="Gestores" icon="manager" path="/gestores" />
                )}

                {permissions.includes(Permission.ASSOCIATE_STUDENTS) && (
                  <MenuItem title="Alunos e Produtos" icon="studentAnalysis" path="/alunos-e-produtos" />
                )}

                {permissions.includes(Permission.ASSOCIATE_TEACHERS) && (
                  <MenuItem title="Professores e Produtos" icon="evaluationsAndResolutions" path="/professores-e-produtos" />
                )}

                {permissions.includes(Permission.ADMIN_EVOLUCIONAL) && (
                  <MenuItem title="Produtos Disponíveis" icon="product" path="/produtos-disponiveis" />
                )}

                {permissions.includes(Permission.VIEW_REPORTMENTS) && (reportments.length > 0 ? (
                  <MenuItem title="Relatórios" icon="product" path="/relatorios" />
                ) : isLoading ? (
                  <SkeletonTheme color="#f0f0f0" highlightColor="#fff">
                    <Skeleton count={1} height={70} />
                  </SkeletonTheme>
                ) : null)}
              </>
            ) : (
              <SkeletonTheme color="#f0f0f0" highlightColor="#fff">
                <Skeleton count={1} height={70} />
              </SkeletonTheme>
            )}
          </>
        ) : (
          <div className="p-3">
            <div className="alert alert-info">
              <strong>Atenção!</strong> Ocorreu uma falha ao carregar suas permissões. Verifique sua conexão com a internet e tente novamente.
            </div>

            <div className="d-flex justify-content-center">
              <button className="btn btn-primary btn-rounded ps-3 pe-3 d-flex align-items-center" onClick={reloadPermissions}>
                <AiOutlineReload size={20} className="me-2" /> Recarregar
              </button>
            </div>
          </div>
        )}
      </Aside>
    </SidebarContainer>
  )
}

export default Sidebar
