const columns: any[] = [
  {
    Header: 'Usuário',
    accessor: 'userName',
  },
  {
    Header: 'Alteração',
    accessor: 'columnName',
  },
  {
    Header: 'Valor Anterior',
    accessor: 'oldValue',
  },
  {
    Header: 'Novo valor',
    accessor: 'newValue',
  },
  {
    Header: 'Ação',
    accessor: 'action',
  },
  {
    Header: 'Data',
    accessor: 'createdAt',
  },
]

export default columns
