import React, { useContext, useMemo, useState } from 'react'
import * as $Classroom from '@services/Class'
import { Link } from 'react-router-dom'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import ClassEditContext from '@contexts/ClassEdit'
import Pagination from '@components/Pagination/Pagination'
import { usePagination, useSortBy, useTable } from 'react-table'
import { Spinner } from 'react-bootstrap'
import { FaSort, FaSortDown, FaSortUp } from 'react-icons/fa'
import IUserSchool from '@interfaces/IUserSchool'
import useUserClassEditColumns from '@hooks/react-table/useUserClassEditColumns'
import * as Utils from '@helpers/Utils'
import Migrate from './Migrate/Migrate'
import AuthContext from '@contexts/Auth'

const ClassesEdit: React.FC<any> = () => {
  const { year } = useContext(AuthContext)
  const { classroom, setClassroom, isLoading, userSchools, selected, setIsLoading, setShowConfirmMigrate, currentYear } = useContext(ClassEditContext)

  const [ search, setSearch ] = useState<string>('')
  const SweetAlert = withReactContent(Swal)
  const columns = useUserClassEditColumns()

  const data = useMemo(() => {
    return userSchools.filter((users: IUserSchool) => {
      let exists = true

      if (search.length > 0) {
        exists = users.nickname.toLowerCase().includes(search.toLowerCase())
      }

      return exists
    })
  }, [userSchools, search])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: {
      pageIndex,
    },
  }: any = useTable({
    columns,
    data,
    initialState: {
      pageIndex: 0,
      pageSize: 10,
    } as any,
  }, useSortBy, usePagination)

  const handleUserChange = (e: any) => {
    const { name, value } = e.target

    setClassroom({
      ...classroom,
      [name]: value,
    })
  }

  const update = () => {
    setIsLoading(true)

    SweetAlert.showLoading()
    $Classroom.update(classroom.degreeId, classroom.id, classroom).then(() => {
      SweetAlert.fire({
        title: 'Sucesso!',
        text: 'Turma atualizada com sucesso!',
        icon: 'success'
      })
    }).catch(e => {
      SweetAlert.fire({
        title: 'Erro!',
        text: e.response?.data?.message ?? 'Erro ao atualizar turma!',
        icon: 'error'
      })
    }).finally(() => {
      setIsLoading(false)
      SweetAlert.hideLoading()
    })
  }

  const exportClass = () => {
    const data = userSchools.map((user: IUserSchool) => [
      user.identifier,
      user.userCode,
      user.nickname,
      classroom.degreeName ?? 'Não encontrado',
      classroom.name,
    ])

    data.unshift([
      'RA', 'RE', 'Nome (Apelido)', 'Série', 'Turma',
    ])

    Utils.exportXLSX(data as string[][], 'AlunosTurma', 'AlunosTurma.xlsx')
  }

  return (
    <>
      <Migrate />
      <div className="d-flex justify-content-end mb-3">
        <Link to="/turmas" className="btn btn-outline-primary me-2">Voltar</Link>
        { classroom.year >= currentYear && year >= currentYear && (
          <button className="btn btn-primary" onClick={update} disabled={isLoading}>Atualizar</button>
        )}
      </div>

      <div className="card mb-4">
        <div className="card-header">
          <div className="d-flex justify-content-between">
            <div>Editar turma ({classroom?.name})</div>
            <b>{userSchools?.length} alunos</b>
          </div>
        </div>

        <div className="card-body">
          <div className="row">
            <div className="col-md-6">
              <div className="form-group mb-3">
                <label htmlFor="degreeName">Série</label>
                <input type="text" className="form-control" name="degreeName" id="degreeName" placeholder="Série" defaultValue={classroom?.degreeName} disabled={true} />
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group mb-3">
                <label htmlFor="name">Turma</label>
                <input type="text" className="form-control" name="name" id="name" placeholder="Nome"
                  defaultValue={classroom?.name} onChange={handleUserChange} disabled={isLoading || year != currentYear || classroom.year != currentYear}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row align-items-end">
        <div className="col-12 col-md-4">
          <div className="form-group mb-3">
            <label htmlFor="search">Pesquisar:</label>
            <input type="text" id="search" name="search" className="form-control" placeholder="Pesquise pelo nome" onChange={e => setSearch(e.target.value)} />
          </div>
        </div>

        <div className="col-12 col-md-4 d-flex align-items-end mb-3">
          { year >= currentYear && (
            <button className="btn btn-outline-danger position-relative pe-3 ps-3 me-4" disabled={!selected.length || !userSchools.length} onClick={() => setShowConfirmMigrate(true)}>
              Migrar de turma
              <span className={'position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger ' + (!selected.length ? 'd-none' : '')}>
                {selected.length}
              </span>
            </button>
          )}
        </div>

        <div className="col-12 col-md-4 d-flex justify-content-end align-items-end mb-3">
          <button className="btn btn-outline-primary" onClick={exportClass} disabled={isLoading || !userSchools.length}>Exportar</button>
        </div>
      </div>

      <div className="card mb-3">
        <div className="card-header">
          Alunos ({userSchools?.length})
        </div>

        <div className="card-body">
          <div className="row p-0">
            {!isLoading ? (userSchools.length ? (
              <div className="table-responsive">
                <table {...getTableProps()} className="table table-default mb-0">
                  <thead>
                    {headerGroups.map((headerGroup: any) => {
                      const { key, ...props } = headerGroup.getHeaderGroupProps()

                      return (
                        <tr {...props} key={key}>
                          {headerGroup.headers.map((column: any) => (
                            <th
                              {...column.getHeaderProps({
                                ...column.getSortByToggleProps(),
                                style: {
                                  ...column.getHeaderProps().style,
                                  ...column.style,
                                }
                              })}
                              disabled={isLoading}
                              key={column.id}
                            >
                              {column.render('Header')}
                              {column.canSort && (!column.isSorted ? (
                                <FaSort className="ms-1" size={9} color="#707ca1" />
                              ) : column.isSortedDesc ? (
                                <FaSortDown className="ms-1" size={9} color="#00b7ff" />
                              ) : (
                                <FaSortUp className="ms-1" size={9} color="#00b7ff" />
                              ))}
                            </th>
                          ))}
                        </tr>
                      )
                    })}
                  </thead>

                  <tbody {...getTableBodyProps()}>
                    {page.map((row: any) => {
                      prepareRow(row)

                      return (
                        <tr {...row.getRowProps()} key={row.id}>
                          {row.cells.map((cell: any, index: number) => (
                            <td
                              align="middle"
                              {...cell.getCellProps({
                                style: {
                                  ...cell.getCellProps().style,
                                  ...cell.column.style,
                                },
                              })}
                              key={`cell-${index}`}
                            >
                              {cell.render('Cell')}
                            </td>
                          ))}
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="bg-light text-center p-3">
                Nenhum aluno vinculado.
              </div>
            )) : (
              <div className="d-flex justify-content-center p-3 bg-light">
                <Spinner animation="border" variant="primary" />
              </div>
            )}
          </div>
        </div>
        {pageCount > 1 && (
          <div className="card-footer d-flex justify-content-end">
            <Pagination
              page={pageIndex}
              pageCount={pageCount}
              canPreviousPage={canPreviousPage}
              canNextPage={canNextPage}
              nextPage={nextPage}
              previousPage={previousPage}
              onClick={(page: number) => gotoPage(page)}
            />
          </div>
        )}
      </div>
    </>
  )
}

export default ClassesEdit
