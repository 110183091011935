import axios from 'axios'

export const all: any = (schoolId?: number) => {
  return axios.get('schools', {
    params: {
      schoolId,
    }
  })
}

export const classes: any = (schoolId: number, params: any = {}) => axios.get(`schools/${schoolId}/classes`, {
  params,
})

export const get: any = (schoolId: number) => {
  return axios.get(`schools/${schoolId}`)
}

export const identifiers = (schoolId: number, identifiers: string[]) : Promise<any> => {
  return axios.post(`schools/${schoolId}/validation/identifiers`, {
    identifiers,
  })
}

export const emails = (schoolId: number, emails: string[]) : Promise<any> => {
  return axios.post(`schools/${schoolId}/validation/emails`, {
    emails,
  })
}

export const nicknames = (schoolId: number, nicknames: string[]) : Promise<any> => {
  return axios.post(`schools/${schoolId}/validation/nicknames`, {
    nicknames,
  })
}

export const years = (schoolId: number) : Promise<any> => {
  return axios.get(`schools/${schoolId}/years`)
}
