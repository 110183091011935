import axios from 'axios'

export const all = (year: number): Promise<any> => axios.get('/degree-groups', {
  params: {
    year,
  },
})

export const degrees = (degreeGroupId: number, params: any = {}): Promise<any> => {
  return axios.get(`/degree-groups/${degreeGroupId}/degrees`, {
    params,
  })
}
