import React, { useContext, useMemo, useState } from 'react'
import AuthContext from '@contexts/Auth'
import Pagination from '@components/Pagination/Pagination'
import { usePagination, useSortBy, useTable } from 'react-table'
import { Spinner } from 'react-bootstrap'
import { FaSort, FaSortDown, FaSortUp } from 'react-icons/fa'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import { getCssProperty } from '@helpers/Theme'
import useClassColumns from '@hooks/react-table/useClassColumns'
import ClassroomContext from '@contexts/Classes'
import IClass from '@interfaces/IClass'
import * as $Classroom from '@services/Class'
import Create from './Create/Create'
import Permission from '@enums/Permission.enum'

const Classes: React.FC<any> = () => {
  const { permissions, year } = useContext(AuthContext)
  const { classes, setClasses, selected, isLoading, setSelected, setShowClassCreate } = useContext(ClassroomContext)

  const [ search, setSearch ] = useState<string>('')
  const [ filterAllClasses, setFilterAllClasses ] = useState<number>(0)
  const [ currentYear ] = useState<number>(new Date().getFullYear())
  const columns = useClassColumns()
  const SweetAlert = withReactContent(Swal)

  const data = useMemo(() => {
    return classes.filter((classroom: IClass) => {
      let exists = true

      if (search.length > 0) {
        exists = classroom.name.toLowerCase().includes(search.toLowerCase())
      }

      if (exists && filterAllClasses === 1)
        exists = classroom.amountStudents === 0

      if (exists && filterAllClasses === 2)
        exists = classroom.amountStudents !== 0

      return exists
    })
  }, [classes, search, filterAllClasses])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: {
      pageIndex,
    },
  }: any = useTable({
    columns,
    data,
    initialState: {
      pageIndex: 0,
      pageSize: 10,
    } as any,
  }, useSortBy, usePagination)

  const handleDeleteAll = () => {
    SweetAlert.fire({
      title: 'Deseja prosseguir?',
      text: 'Deseja prosseguir com a exclusão dessas turmas? Você não poderá voltar atrás dessa decisão.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: getCssProperty('--secondary-color'),
      cancelButtonColor: getCssProperty('--primary-color'),
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    }).then(result => {
      if (result.isConfirmed) {
        SweetAlert.fire({
          title: 'Aguarde...',
          didOpen: () => SweetAlert.showLoading(),
        })

        $Classroom.deleteAll(0, selected).then(() => {
          SweetAlert.fire({
            title: 'Sucesso!',
            text: 'Turmas excluídas com sucesso!',
            icon: 'success',
            confirmButtonColor: getCssProperty('--primary-color'),
          })

          setClasses(classes.filter((classroom: IClass) => !selected.includes(classroom.id)))
          setSelected([])
        }).catch(e => SweetAlert.fire({
          title: 'Erro!',
          text: e.response?.data?.message ?? 'Ocorreu um erro ao excluir as turmas. Tente novamente mais tarde.',
          icon: 'error',
          confirmButtonColor: getCssProperty('--primary-color'),
        }))
      }
    })
  }

  return (
    <>
      <Create />

      <div className="row align-items-end">
        <div className="col-12 col-md-3">
          <div className="form-group mb-3">
            <label htmlFor="search">Pesquisar:</label>
            <input type="text" id="search" name="search" className="form-control" placeholder="Pesquise pelo nome" onChange={e => setSearch(e.target.value)} />
          </div>
        </div>

        <div className="col-12 col-md-6 d-flex align-items-end mb-3">
          <button className="btn btn-outline-danger position-relative pe-3 ps-3 me-4" disabled={!selected.length} onClick={handleDeleteAll}>
            Excluir selecionados
            <span className={'position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger ' + (!selected.length ? 'd-none' : '')}>
              {selected.length}
            </span>
          </button>
          <div className="form-check me-2">
            <input className="form-check-input"
              type="radio" name="filterAllClasses"
              id="filterAllClasses" checked={filterAllClasses === 0 ? true : false}
              onChange={() => setFilterAllClasses(0)}
            />
            <label htmlFor="filterAllClasses">
              Todas
            </label>
          </div>
          <div className="form-check me-2">
            <input className="form-check-input"
              type="radio" name="filterAllClasses"
              id="filterAllClasses" checked={filterAllClasses === 2 ? true : false}
              onChange={() => setFilterAllClasses(2)}
            />
            <label htmlFor="filterClasses">
              Turmas com alunos
            </label>
          </div>
          <div className="form-check">
            <input className="form-check-input"
              type="radio" name="filterAllClasses"
              id="filterAllClasses" checked={filterAllClasses === 1 ? true : false}
              onChange={() => setFilterAllClasses(1)}
            />
            <label htmlFor="filterClasses">
              Turmas sem alunos
            </label>
          </div>
        </div>

        <div className="col-12 col-md-3 d-flex justify-content-end align-items-end mb-3">
          {permissions.includes(Permission.CREATE_CLASS) && year === currentYear && (
            <button className="btn btn-primary pe-3 ps-3" onClick={() => setShowClassCreate(true)}>Cadastrar</button>
          )}
        </div>
      </div>

      <div className="card">
        <div className="card-header">Turmas ({classes.length})</div>

        <div className="card-body p-0">
          {!isLoading ? (classes.length ? (
            <div className="table-responsive">
              <table {...getTableProps()} className="table table-default mb-0">
                <thead>
                  {headerGroups.map((headerGroup: any) => {
                    const { key, ...props } = headerGroup.getHeaderGroupProps()

                    return (
                      <tr {...props} key={key}>
                        {headerGroup.headers.map((column: any) => (
                          <th
                            {...column.getHeaderProps({
                              ...column.getSortByToggleProps(),
                              style: {
                                ...column.getHeaderProps().style,
                                ...column.style,
                              }
                            })}
                            disabled={isLoading}
                            key={column.id}
                          >
                            {column.render('Header')}
                            {column.canSort && (!column.isSorted ? (
                              <FaSort className="ms-1" size={9} color="#707ca1" />
                            ) : column.isSortedDesc ? (
                              <FaSortDown className="ms-1" size={9} color="#00b7ff" />
                            ) : (
                              <FaSortUp className="ms-1" size={9} color="#00b7ff" />
                            ))}
                          </th>
                        ))}
                      </tr>
                    )
                  })}
                </thead>

                <tbody {...getTableBodyProps()}>
                  {page.map((row: any) => {
                    prepareRow(row)

                    return (
                      <tr {...row.getRowProps()} key={row.id}>
                        {row.cells.map((cell: any, index: number) => (
                          <td
                            align="middle"
                            {...cell.getCellProps({
                              style: {
                                ...cell.getCellProps().style,
                                ...cell.column.style,
                              },
                            })}
                            key={`cell-${index}`}
                          >
                            {cell.render('Cell')}
                          </td>
                        ))}
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="bg-light text-center p-3">
              Nenhuma turma cadastrada.
            </div>
          )) : (
            <div className="d-flex justify-content-center p-3 bg-light">
              <Spinner animation="border" variant="primary" />
            </div>
          )}
        </div>
        {pageCount > 1 && (
          <div className="card-footer d-flex justify-content-end">
            <Pagination
              page={pageIndex}
              pageCount={pageCount}
              canPreviousPage={canPreviousPage}
              canNextPage={canNextPage}
              nextPage={nextPage}
              previousPage={previousPage}
              onClick={(page: number) => gotoPage(page)}
            />
          </div>
        )}
      </div>
    </>
  )
}

export default Classes
