import React from 'react'
import { getCssProperty } from '@helpers/Theme'
import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai'

type Props = {
  count: number
}

const PlatformCount: React.FC<any> = ({ count }: Props) => {
  if (count > 0) {
    return (
      <>
        <span>{count}</span>
        <AiFillCheckCircle size={18} color={getCssProperty('--primary-color')} />
      </>
    )
  }

  return <AiFillCloseCircle size={18} color="#999" />
}

export default PlatformCount
