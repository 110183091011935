import React, { useCallback, useContext, useEffect, useState } from 'react'
import RegistrationContext from '@contexts/Registration'
import IRegistrationEntity from '@interfaces/IRegistrationEntity'
import IRegistrationTypeColumn from '@interfaces/IRegistrationTypeColumn'
import Button from '@components/Button/Button'
import * as $DegreeGroup from '@services/DegreeGroup'
import IDegree from '@interfaces/IDegree'
import IClass from '@interfaces/IClass'
import Delete from '../Finish/Delete/Delete'
import SelectGroup from '@components/SelectGroup/SelectGroup'
import AuthContext from '@contexts/Auth'
import { Spinner } from 'react-bootstrap'
import { SelectGroupProvider } from '@contexts/SelectGroup'
import ISelectGroupValueItem from '@interfaces/ISelectGroupValueItem'
import * as $EntityRegistration from '@services/EntityRegistration'

type Props = {
  isLoading: boolean
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  onFinish: () => void
}

const Association: React.FC<any> = ({ isLoading, setIsLoading, onFinish }: Props) => {
  const { year } = useContext(AuthContext)
  const { token, degreeGroupId, entities, registrationTypeAction } = useContext(RegistrationContext)

  const [ degrees, setDegrees ] = useState<IDegree[]>([])
  const [ classes, setClasses ] = useState<IClass[]>([])
  const [ updatedEntities, setUpdatedEntities ] = useState<IRegistrationEntity[]>([])

  useEffect(() => {
    if (degreeGroupId) {
      $DegreeGroup.degrees(degreeGroupId, {
        year,
      }).then(({ data: degrees }) => {
        const classes = degrees.reduce((acc: IClass[], degree: IDegree) => [...acc, ...degree.classes], [])

        setDegrees(degrees)
        setClasses(classes)
        setUpdatedEntities(() => {
          const classesId = classes.map((classroom: IClass) => classroom.id).join(',')

          return entities.map((entity: IRegistrationEntity) => ({
            ...entity,
            classesId,
          }))
        })
      }).finally(() => setIsLoading(false))
    }

    return () => {
      setDegrees([])
      setClasses([])
      setUpdatedEntities([])
    }
  }, [year, degreeGroupId, setIsLoading, entities])

  const getDefaultValue = useCallback((entity: IRegistrationEntity) => {
    const classesId = entity.classesId ? entity.classesId.split(',').map(id => parseInt(id)) : []

    return classes.filter(classroom => classesId.includes(classroom.id)).map(classroom => ({
      id: classroom.id,
      name: classroom.name,
    } as ISelectGroupValueItem))
  }, [classes])

  const handleOnChange = (entity: IRegistrationEntity, value: ISelectGroupValueItem[]) => {
    const index = entities.findIndex(item => item.id === entity.id)

    if (index === -1)
      return

    setUpdatedEntities(updatedEntities => {
      const index = updatedEntities.findIndex(item => item.id === entity.id)

      if (index === -1)
        return [...updatedEntities, entity]

      updatedEntities[index].classesId = value.map(item => item.id).join(',')

      return [...updatedEntities]
    })
  }

  const data = degrees.map(degree => ({
    id: degree.id,
    name: degree.shortname,
    checkable: false,
    children: degree.classes.map((classItem: IClass) => ({
      id: classItem.id,
      name: classItem.name,
      checkable: true,
    })),
  }))

  const done = () => {
    setIsLoading(true)

    $EntityRegistration.updateMany(token, updatedEntities).then(() => {
      setUpdatedEntities([])
      onFinish()
    }).catch(() => setIsLoading(false))
  }

  return (
    <>
      {degrees.length > 0 ? (
        <table className="table table-striped table-dashed mb-0">
          <thead>
            <tr>
              {registrationTypeAction?.getLabels().map((label: string, index: number) => (
                <th key={index}>
                  <strong>{label}</strong>
                </th>
              ))}
              <th style={{ width: 350 }}>
                <strong>Turmas</strong>
              </th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {entities.map((entity: IRegistrationEntity) => (
              <tr key={entity.id}>
                {registrationTypeAction?.getColumns().map((column: IRegistrationTypeColumn) => (
                  <td valign="middle" key={column.key}>
                    {column?.replace?.((entity as any)[column.key] ?? column.placeholder) ?? (entity as any)[column.key] ?? column.placeholder}
                  </td>
                ))}
                <td>
                  {classes.length > 0 && (
                    <SelectGroupProvider
                      defaultValue={getDefaultValue(entity)}
                      onChange={(value: ISelectGroupValueItem[]) => handleOnChange(entity, value)}
                    >
                      <SelectGroup data={data} placeholder="Associar turmas" />
                    </SelectGroupProvider>
                  )}
                </td>
                <td>
                  <div className="d-flex justify-content-end">
                    <Delete entity={entity} />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="d-flex justify-content-center p-3">
          <Spinner animation="border" />
        </div>
      )}

      <div className="card-footer d-flex justify-content-end">
        <Button
          className="btn btn-primary pe-3 ps-3"
          disabled={!entities.length}
          isLoading={isLoading}
          onClick={done}
        >
          Confirmar e finalizar
        </Button>
      </div>
    </>
  )
}

export default Association
