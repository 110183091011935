const columns: any[] = [
  {
    Header: 'Apelido',
    accessor: 'nickname',
  },
  {
    Header: 'Nome',
    accessor: 'name',
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
]

export default columns
