import React, { useMemo, useState } from 'react'

type Props = {
  teacherId: number
  classes: string[]
}

const TeacherClasses: React.FC<any> = ({ teacherId, classes }: Props) => {
  const [ showAll, setShowAll ] = useState<boolean>(false)

  const data = useMemo(() => {
    const length = classes.length

    if (length > 5) {
      return showAll ? classes : classes.slice(0, 5)
    }

    return classes
  }, [classes, showAll])

  return (
    <>
      {data.map((classroom: string, index: number) => (
        <div className="badge badge-tag mb-1 me-1" key={`${teacherId}-classroom-${index}`}>
          {classroom}
        </div>
      ))}

      {classes.length > 5 && (
        <button className="btn pt-1 pb-1 pe-2 ps-2 badge badge-tag mb-1 me-1" onClick={() => setShowAll(!showAll)}>
          {showAll ? 'Menos...' : 'Mais...'}
        </button>
      )}
    </>
  )
}

export default TeacherClasses
