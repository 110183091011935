import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import StudentsAndProductsContext from '@contexts/StudentsAndProducts'
import { Modal, Spinner } from 'react-bootstrap'
import IPlatformAssociationUpdate from '@interfaces/IPlatformAssociationUpdate'
import * as $Student from '@services/Student'
import IDegreeGroup from '@interfaces/IDegreeGroup'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'
import { getCssProperty } from '@helpers/Theme'
import { DegreeGroup, Section } from './Update.styles'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import axios from 'axios'
import AssociationError from '@components/AssociationError/AssociationError'
import AuthContext from '@contexts/Auth'

const Update: React.FC<any> = () => {
  const { year } = useContext(AuthContext)
  const { associationUpdate, setAssociationUpdate, refresh } = useContext(StudentsAndProductsContext)

  const [ isLoading, setIsLoading ] = useState<boolean>(false)
  const [ activeDegreeGroupIds, setActiveDegreeGroupIds ] = useState<number[]>([])
  const [ degreeGroups, setDegreeGroups ] = useState<any[]>([])
  const [ associations, setAssociations ] = useState<any[]>([])
  const [ data, setData ] = useState<any[]>([])

  const SweetAlert = withReactContent(Swal)

  const { student, platform } = useMemo(() => associationUpdate || {} as IPlatformAssociationUpdate, [associationUpdate])

  useEffect(() => {
    if (associationUpdate && student?.id && student?.schoolId && platform?.id && student?.degreeId && student?.classId) {
      setIsLoading(true)

      axios.all([
        $Student.associations(student.id as number, platform.id as number, {
          degreeId: student.degreeId,
          year,
        }),
        $Student.degreeGroups(student.id as number, platform.id as number, {
          degreeId: student.degreeId as number,
          year,
        })
      ]).then(axios.spread(({ data }: any, { data: degreeGroups }: any) => {
        const activeDegreeGroupIds: number[] = []

        degreeGroups.map((items: IDegreeGroup[]) => {
          for (const item of items) {
            if (item.currentAccessStudent && !activeDegreeGroupIds.includes(item.degreeGroupId)) {
              activeDegreeGroupIds.push(item.degreeGroupId)
            }
          }
        })

        setData([
          ...data,
        ])

        setAssociations([
          ...data,
        ])

        setDegreeGroups(degreeGroups)
        setActiveDegreeGroupIds(activeDegreeGroupIds)
      })).finally(() => setIsLoading(false))
    }
  }, [associationUpdate, platform?.id, student, year])

  const handleActiveDegreeGroupId = useCallback((degreeGroupId: number) => {
    if (activeDegreeGroupIds.includes(degreeGroupId)) {
      setActiveDegreeGroupIds(activeDegreeGroupIds.filter((id: number) => id !== degreeGroupId))
    } else {
      setActiveDegreeGroupIds([...activeDegreeGroupIds, degreeGroupId])
    }
  }, [activeDegreeGroupIds])

  const onChange = (e: any, productId: number) => {
    const index = associations.findIndex(association => association.productId === productId)

    if (associations[index]) {
      associations[index] = {
        ...associations[index],
        associated: e.target.checked,
        hasChanged: data[index].associated !== e.target.checked,
      }

      setAssociations([
        ...associations
      ])
    }
  }

  const show = useMemo(() => !!associationUpdate, [associationUpdate])

  const degreeGroupAccessAmount = useCallback((degreeGroup: IDegreeGroup) => {
    const association = associations.find(a => a.productId === degreeGroup.productId)
    const accessAmount = degreeGroup.currentAccessAmount - (degreeGroup.currentAccessStudent ? 1 : 0)

    if (association) {
      return accessAmount + (association.associated ? 1 : 0)
    } else {
      return accessAmount
    }
  }, [associations])

  const isChecked = useCallback((degreeGroup: IDegreeGroup) => {
    return associations.some(a => a.currentDegreeId === degreeGroup.currentDegreeId && a.productId === degreeGroup.productId && a.associated)
  }, [associations])

  const isValid = useMemo(() => {
    if (degreeGroups.length === 0)
      return false

    for (const items of degreeGroups) {
      if (!items.length)
        continue

      for (const item of items) {
        const checked: boolean = isChecked(item)

        if (item.maxAccessAmount === -1 || !checked)
          continue

        if (degreeGroupAccessAmount(item) > item.maxAccessAmount) {
          return false
        }
      }
    }

    return true
  }, [degreeGroupAccessAmount, degreeGroups, isChecked])

  const hide = () => {
    setIsLoading(false)
    setAssociationUpdate(null)
    setDegreeGroups([])
    setAssociations([])
  }

  const update = () => {
    setIsLoading(true)
    SweetAlert.showLoading()

    $Student.associate(student.id as number, platform.id as number, associations, year).then(() => {
      refresh()
      hide()

      SweetAlert.fire({
        icon: 'success',
        title: 'Associação do aluno atualizada com sucesso!',
        text: 'A associação entre o aluno e produtos foi atualizada com sucesso.',
      })
    }).catch(e => SweetAlert.fire({
      icon: 'error',
      title: 'Erro ao atualizar associações do aluno',
      html: <AssociationError data={e?.response?.data?.errors} />,
    })).finally(() => {
      setIsLoading(false)
      SweetAlert.hideLoading()
    })
  }

  return (
    <Modal
      show={show}
      onHide={hide}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{associationUpdate?.student.nickname}</Modal.Title>
      </Modal.Header>

      <Modal.Body className="pb-0">
        {degreeGroups.length > 0 ? degreeGroups.map((items: IDegreeGroup[]) => {
          const degreeGroup: IDegreeGroup = items[0]
          const isActive: boolean = activeDegreeGroupIds.includes(degreeGroup.degreeGroupId)

          return (
            <Section
              className="mb-3"
              key={degreeGroup.degreeGroupId}
            >
              <DegreeGroup.Header onClick={() => handleActiveDegreeGroupId(degreeGroup.degreeGroupId)}>
                <label>{items[0]?.degreeGroupName}</label>
                <div>
                  {isActive ? (
                    <FiChevronUp
                      color={getCssProperty('--secondary-color')}
                      size={20}
                    />
                  ) : (
                    <FiChevronDown
                      color={getCssProperty('--secondary-color')}
                      size={20}
                    />
                  )}
                </div>
              </DegreeGroup.Header>

              {isActive && items.length > 0 && (
                <DegreeGroup.Items className="pt-3">
                  {items.map((item: IDegreeGroup) => {
                    const accessAmount = degreeGroupAccessAmount(item)

                    return (
                      <DegreeGroup.Item key={item.currentDegreeId + '-' + item.productId}>
                        <div className="form-check d-flex">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            style={{
                              minWidth: 14,
                            }}
                            checked={isChecked(item)}
                            onChange={e => onChange(e, item.productId)}
                          />
                          <label className="form-check-label">{item.productName ?? item.platformName}</label>
                        </div>

                        <div className={'badge p-1 ' + (accessAmount === 0 || item.maxAccessAmount === -1 ? 'bg-secondary' : (accessAmount > item.maxAccessAmount ? 'bg-danger' : 'bg-primary'))}>
                          {accessAmount} {item.maxAccessAmount !== -1 ? `/ ${item.maxAccessAmount}` : ''}
                        </div>
                      </DegreeGroup.Item>
                    )
                  })}
                </DegreeGroup.Items>
              )}
            </Section>
          )
        }) : (isLoading ? (
          <div className="d-flex justify-content-center p-3 bg-light mb-3">
            <Spinner animation="border" variant="primary" />
          </div>
        ) : (
          <div className="alert bg-light text-center">Não há resultados a serem exibidos.</div>
        ))}
      </Modal.Body>

      <Modal.Footer>
        <button
          className="btn btn-primary"
          disabled={isLoading || !isValid || !associations.some(a => a.hasChanged)}
          onClick={update}
        >Atualizar associação</button>
      </Modal.Footer>
    </Modal>
  )
}

export default Update
