import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { getCssProperty } from '@helpers/Theme'
import IClass from '@interfaces/IClass'
import IDegree from '@interfaces/IDegree'
import IDiscipline from '@interfaces/IDiscipline'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'
import { Accordion, Body, Container, Header, Labels, Toggle } from './Degrees.styles'
import { IDisciplineAssociation } from '../TeachersAndProducts'
import IPlatform from '@interfaces/IPlatform'

interface IProps {
  platform?: IPlatform;
  degrees: IDegree[];
  disciplines: IDiscipline[];
  associations: IDisciplineAssociation[];
  degree: IDegree;
  label: (data: IDisciplineAssociation[], index: number) => ReactElement;
  render: (data: IDisciplineAssociation[], class_: IClass, discipline: IDiscipline) => ReactElement;
}

const Degrees: React.FC<any> = ({ platform, degrees, disciplines, associations, label, render }: IProps) => {
  const [ activeDegreeId, setActiveDegreeId ] = useState<number|null>(-1)

  useEffect(() => {
    if (degrees.length && activeDegreeId === -1) {
      setActiveDegreeId(degrees[0].id)
    }
  }, [activeDegreeId, degrees])

  const setActive = (degree: IDegree) => {
    if (degree.id === activeDegreeId) {
      setActiveDegreeId(null)
    } else {
      setActiveDegreeId(degree.id)
    }
  }

  const getAssociations = useCallback((degree: IDegree, discipline: IDiscipline, class_?: IClass) => {
    return associations?.length ? associations.filter((association: IDisciplineAssociation) => {
      return association.disciplineId === discipline.id &&
        association.degreeId === degree.id &&
        (!platform || association.platformId === platform.id) &&
        (!class_ || association.classId === class_.id)
    }) : []
  }, [associations, platform])

  return (
    <Container>
      <Header className="header">
        <div className="toggle"></div>

        <div className="class-name">
          <span>Série/Turma</span>
        </div>

        {disciplines.map((discipline: any) => (
          <div className="discipline" key={discipline.name}>
            <span>{discipline.name}</span>
          </div>
        ))}
      </Header>

      {degrees.filter(degree => degree?.classes?.length > 0 && (platform == null || platform?.degreeIds?.includes(degree.id))).map((degree: any) => (
        <Accordion className="accordion" key={degree.id}>
          <Labels className="labels">
            <div className="toggle">
              <Toggle onClick={() => setActive(degree)}>
                {activeDegreeId === degree.id ? <FiChevronUp size={20} color={getCssProperty('--secondary-color')} /> : <FiChevronDown size={20} color={getCssProperty('--secondary-color')} />}
              </Toggle>
            </div>

            <div className="class-name">
              <span>{degree.shortname}</span>
            </div>

            {disciplines.map((discipline: any, index: number) => {
              return label(getAssociations(degree, discipline), index)
            })}
          </Labels>

          <Body style={{ display: activeDegreeId === degree.id ? 'block' : 'none' }} className="body">
            {degree.classes.length ? degree.classes.map((class_: IClass) => (
              <div className="class-item" key={class_.id}>
                <div className="toggle"></div>

                <div className="class-name">
                  <span>{class_.name}</span>
                </div>

                {disciplines.map((discipline: any) => (
                  <div className="discipline" key={'teachers-' + discipline.name}>
                    {render(getAssociations(degree, discipline, class_), class_, discipline)}
                  </div>
                ))}
              </div>
            )) : (
              <div className="text-center p-3">
                <span>Nenhuma turma cadastrada para esta série.</span>
              </div>
            )}
          </Body>
        </Accordion>
      ))}
    </Container>
  )
}

export default Degrees
