import styled from 'styled-components'
import { getCssProperty } from '@helpers/Theme'

export const Button = styled.button`
  width: 38px;
  height: 38px;
  border-radius: 100%;
  background-color: ${getCssProperty('--light-color')};
  padding: 0;
  border: 0;
`