import React, { createContext, useState } from 'react'
import ISelectGroupValueItem from '@interfaces/ISelectGroupValueItem'
import useUpdateEffect from '@hooks/useUpdateEffect'

type SelectGroupContextProps = {
  value: ISelectGroupValueItem[]
  setValue: React.Dispatch<React.SetStateAction<ISelectGroupValueItem[]>>
}

type Props = {
  defaultValue: ISelectGroupValueItem[]
  onChange: (value: ISelectGroupValueItem[]) => void
  children: React.ReactNode
}

const SelectGroupContext = createContext<SelectGroupContextProps>({ } as SelectGroupContextProps)

export const SelectGroupProvider: React.FC<any> = ({ defaultValue, onChange, children }: Props) => {
  const [ value, setValue ] = useState<ISelectGroupValueItem[]>(defaultValue || [])

  useUpdateEffect(() => onChange(value), [value])

  return (
    <SelectGroupContext.Provider value={{ value, setValue }}>
      {children}
    </SelectGroupContext.Provider>
  )
}

export default SelectGroupContext
