import axios from 'axios'
import IRegistrationEntity from '@interfaces/IRegistrationEntity'
import IUser from '@interfaces/IUser'

export const find = (id: number): Promise<any> => {
  return axios.get(`/revisors/${id}`)
}

export const all = (params: any = {}): Promise<any> => {
  return axios.get('revisors', {
    params,
  })
}

export const create: any = (revisors: IRegistrationEntity[]) => {
  return axios.post('revisors', revisors)
}

export const update = (id: number, revisor: IUser): Promise<any> => {
  return axios.put('revisors/' + id, revisor)
}
