import React, { useCallback, useContext, useMemo } from 'react'
import IClass from '@interfaces/IClass'
import { Link } from 'react-router-dom'
import AuthContext from '@contexts/Auth'
import Permission from '@enums/Permission.enum'
import ClassesContext from '@contexts/Classes'
import { Tooltip } from '@screens/Classes/Classes.styles'

const useClassColumns: any = () => {
  const { permissions, year } = useContext(AuthContext)
  const { classes, selected, setSelected, handleDelete } = useContext(ClassesContext)

  const handleCheckboxChange = useCallback(({ target: { checked, value } }: React.ChangeEvent<HTMLInputElement>) => {
    const id = Number(value)

    if (checked) {
      setSelected([...selected, id])
    } else {
      setSelected(selected.filter((i: number) => i !== id))
    }
  }, [selected, setSelected])

  return useMemo(() => [
    {
      accessor: 'cb',
      disableSortBy: true,
      Header: () => (
        <input
          type="checkbox"
          className="form-check-input"
          checked={selected.length > 0}
          onChange={({ target: { checked } }) => {
            if (checked) {
              setSelected(classes.filter((classroom: IClass) => classroom?.amountStudents != undefined && classroom.amountStudents === 0).map((classroom: IClass) => classroom.id) as number[])
            } else {
              setSelected([])
            }
          }}
        />
      ),
      Cell: ({ row: { original: { id, amountStudents } } }: any) => (
        <input
          type="checkbox"
          className="form-check-input"
          disabled={amountStudents > 0 ? true : false}
          checked={selected.includes(id)}
          onChange={handleCheckboxChange}
          value={id}
        />
      )
    },
    {
      Header: 'Série',
      accessor: 'degreeName',
    },
    {
      Header: 'Turma',
      accessor: 'name',
    },
    {
      Header: 'N° de alunos',
      accessor: 'amountStudents',
    },
    {
      Header: 'Ações',
      Cell: ({ row: { original: { amountStudents, id, degreeId } } }: any) => {
        return <>
          <div className="d-flex align-items-center">
            {permissions.includes(Permission.DELETE_CLASS) && (
              amountStudents > 0 ? (
                <Tooltip className="platform" key={id}>
                  <div className="tooltip-container">
                    <button className="btn btn-outline-danger me-3" disabled={true}>Excluir</button>
                    <div className="tooltip">Somente turmas sem alunos podem ser excluídas</div>
                  </div>
                </Tooltip>
              ):
                <button className="btn btn-outline-danger me-3"
                  disabled={amountStudents > 0 ? true : false}
                  onClick={() => handleDelete(id, degreeId)}
                >Excluir</button>
            )}
            {permissions.includes(Permission.EDIT_CLASS) && (
              <Link to={`/turmas/${id}/editar`} className="btn btn-outline-primary">
                { year >= new Date().getFullYear() ? 'Editar' : 'Ver alunos' }
              </Link>
            )}
          </div>
        </>
      },
      style: {
        width: 300,
      }
    },
  ], [selected, setSelected, classes, handleCheckboxChange, permissions, year, handleDelete])
}

export default useClassColumns
