import React, { createContext, useEffect, useState, useContext } from 'react'
import IUser from '@interfaces/IUser'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import * as $Teacher from '@services/Teacher'
import * as $Access from '@services/Access'
import * as $Degree from '@services/Degree'
import * as $DegreeGroup from '@services/DegreeGroup'
import IDiscipline from '@interfaces/IDiscipline'
import IDegree from '@interfaces/IDegree'
import AuthContext from './Auth'

type TeacherContextProps = {
  teacher: IUser
  setTeacher: React.Dispatch<React.SetStateAction<IUser>>
  isLoading: boolean
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  disciplines: IDiscipline[]
  setDisciplines: React.Dispatch<React.SetStateAction<IDiscipline[]>>
  degrees: IDegree[]
  setDegrees: React.Dispatch<React.SetStateAction<IDegree[]>>
  disciplineId: number
  setDisciplineId: React.Dispatch<React.SetStateAction<number>>
  associations: any[]
  setAssociations: React.Dispatch<React.SetStateAction<any[]>>
  degreeGroups: any[]
  setDegreeGroups: React.Dispatch<React.SetStateAction<any[]>>
}

const TeacherContext = createContext<TeacherContextProps>({ } as TeacherContextProps)

export const TeacherProvider: React.FC<any> = () => {
  const { year } = useContext(AuthContext)

  const [ teacher, setTeacher ] = useState<IUser>({} as IUser)
  const [ isLoading, setIsLoading ] = useState<boolean>(true)
  const [ disciplines, setDisciplines ] = useState<IDiscipline[]>([])
  const [ degrees, setDegrees ] = useState<IDegree[]>([])
  const [ associations, setAssociations ] = useState<any[]>([])
  const [ disciplineId, setDisciplineId ] = useState<number>(0)
  const [ degreeGroups, setDegreeGroups ] = useState<any[]>([])

  const { id: teacherId }: any = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    axios.all([
      $Access.disciplines({
        teacherId,
        year,
      }),
      $Degree.all({
        year,
      }),
      $Teacher.find(teacherId),
      $DegreeGroup.all(year),
      $Teacher.associations(teacherId, year),
    ]).then(axios.spread(({ data: disciplines }: any, { data: degrees }: any, { data: teacher }: any, { data: degreeGroups }: any, { data: associations }: any) => {
      if (teacher.year !== year) {
        navigate('/professores')
        return
      }

      setDisciplines(disciplines)
      setDegrees(degrees)
      setTeacher(teacher)
      setDegreeGroups(degreeGroups)
      setAssociations(associations)
      setDisciplineId(disciplines[0]?.id ?? 0)
    })).finally(() => setIsLoading(false))
  }, [navigate, teacherId, year])

  return (
    <TeacherContext.Provider
      value={{
        teacher,
        setTeacher,
        isLoading,
        setIsLoading,
        disciplines,
        setDisciplines,
        degrees,
        setDegrees,
        disciplineId,
        setDisciplineId,
        associations,
        setAssociations,
        degreeGroups,
        setDegreeGroups,
      }}
    >
      <Outlet />
    </TeacherContext.Provider>
  )
}

export default TeacherContext
