import React, { useCallback, useContext, useState } from 'react'
import Button from '@components/Button/Button'
import UserRegistrationContext from '@contexts/UserRegistration'
import * as XLSX from 'xlsx'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import IUserRegistrationEntity from '@interfaces/IUserRegistrationEntity'
import { random } from '@helpers/Utils'

const Upload: React.FC<any> = () => {
  const { isLoading, setIsLoading, pushEntities } = useContext(UserRegistrationContext)

  const [ file, setFile ] = useState<File|null>()

  const SweetAlert = withReactContent(Swal)

  const upload = useCallback(() => {
    setIsLoading(true)

    const error = (text: string, index?: number) => {
      setIsLoading(false)

      SweetAlert.fire({
        title: 'Erro!',
        text: index ? `${text}, na linha ${index + 1}` : text,
        icon: 'error',
        confirmButtonText: 'Ok'
      })
    }

    if (file) {
      const reader = new FileReader()

      reader.onload = (e: any) => {
        const workbook = XLSX.read(e.target.result, {
          type: 'binary'
        })

        const first_sheet_name = workbook.SheetNames[0]
        const worksheet = workbook.Sheets[first_sheet_name]

        const rows = XLSX.utils.sheet_to_json(worksheet, {
          header: 1
        }) as string[][]

        rows.shift()

        if (!rows.length) {
          error('O arquivo não contém dados válidos!')
          return
        }

        const entities: IUserRegistrationEntity[] = []

        if (rows[0].length < 11) {
          error('O arquivo não contém todas as colunas necessárias!')
          return
        }

        for (let i = 0; i < rows.length; i++) {
          const row = rows[i]

          if (!row.length)
            continue

          const toDelete = row[11] && row[11].toString().length > 0 ? ['sim', 's', '1'].includes(row[11].toString().toLowerCase()) : false
          const roleId: number|null = row[5] ? parseInt(row[5]) : null

          if (isNaN(parseInt(row[0]))) {
            error('A coluna "ID da Escola" não contém um ID válido', i)
            return
          }

          if (roleId && roleId == 1) {
            if (isNaN(parseInt(row[1]))) {
              error('A coluna "RA" não contém um número válido', i)
              return
            }
          } else if (roleId && roleId > 1) {
            if (row[3] == null) {
              error('O preenchimento do "E-mail" é obrigatório', i)
              return
            }
          } else {
            error('O preenchimento do "role ID" é obrigatório', i)
            return
          }

          if (!toDelete) {
            if (row[2] == null) {
              error('O preenchimento do "nome" é obrigatório', i)
              return
            }
          }

          entities.push({
            id: random(),
            schoolId: parseInt(row[0]),
            identifier: isNaN(parseInt(row[1])) ? null : parseInt(row[1]),
            name: row[2],
            email: row[3],
            password: row[4],
            role: row[5],
            degreeName: row[6],
            className: row[7],
            disciplineName: row[8],
            platformId: isNaN(parseInt(row[9])) ? null : parseInt(row[9]),
            assessmentId: isNaN(parseInt(row[10])) ? null : parseInt(row[10]),
            delete: toDelete,
          })
        }

        pushEntities(entities).then(() => setFile(null)).finally(() => setIsLoading(false))
      }

      reader.readAsBinaryString(file)
    }
  }, [SweetAlert, file, pushEntities, setIsLoading])

  return (
    <div className="input-group">
      <input
        type="file"
        id="file-input"
        className="form-control"
        accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        onChange={e => e.target.files?.length && setFile(e.target.files[0])}
      />

      <Button
        className="btn btn-primary pe-3 ps-3"
        loadingText="Aguarde..."
        isLoading={isLoading}
        disabled={!file || isLoading}
        onClick={upload}
      >Carregar planilha</Button>
    </div>
  )
}

export default Upload
