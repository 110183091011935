import React, { useContext, useEffect, useMemo, useState } from 'react'
import IEntityValidationError from '@interfaces/IEntityValidationError'
import { Alert, Modal } from 'react-bootstrap'
import EntityValidationErrorUsageEnum from '@enums/EntityValidationErrorUsageEnum.enum'
import RegistrationContext from '@contexts/Registration'
import * as $EntityRegistration from '@services/EntityRegistration'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import IRegistrationEntity from '@interfaces/IRegistrationEntity'
import { getCssProperty } from '@helpers/Theme'
import { OutlineBox } from './Resolve.styles'

type IResolveProps = {
  error: IEntityValidationError;
  onSaved: (erros: IEntityValidationError[]) => void;
  onCanceled: () => void;
}

const Resolve: React.FC<IResolveProps> = ({ error, onSaved, onCanceled }: IResolveProps) => {
  const { entity, token, entityLabels, updateEntity, setValidations, registrationTypeAction, degrees, disciplines } = useContext(RegistrationContext)

  const [ isLoading, setIsLoading ] = useState<boolean>(false)
  const [ newValue, setNewValue ] = useState<any>()
  const [ duplicated, setDuplicated ] = useState<IRegistrationEntity[]>([])

  const SweetAlert = withReactContent(Swal)

  useEffect(() => {
    if (error.usage === EntityValidationErrorUsageEnum.CONFIRM_OR_REFUSE && entity?.id) {
      setIsLoading(true)
      $EntityRegistration.duplicated(token, entity.id, error.id).then(({ data }: any) => setDuplicated(data)).finally(() => setIsLoading(false))
    }
  }, [token, entity, error])

  const column = useMemo(() => {
    return registrationTypeAction?.getColumns().find(column => column.errors?.includes(error.name))
  }, [error.name, registrationTypeAction])

  const value = useMemo(() => {
    if (column && entity) {
      return (entity as any)[column.key]
    }
  }, [column, entity])

  const handleNewValueChange = ({ target: { value } }: any) => {
    if (column) {
      setNewValue(column.worker ? column.worker(value) : value)
    }
  }

  const update = (newEntity: IRegistrationEntity) => {
    if (entity?.id) {
      $EntityRegistration.update(token, entity.id, newEntity).then(({ data: { errors, validations } }: any) => {
        setValidations(validations)
        updateEntity(newEntity)

        SweetAlert.fire({
          title: 'Informações atualizadas com sucesso!',
          icon: 'success',
        })

        setTimeout(() => onSaved(errors), 500)
      }).catch(() => {
        SweetAlert.fire({
          title: 'Ocorreu um erro ao atualizar as informações!',
          icon: 'error',
        })
      }).finally(() => {
        SweetAlert.hideLoading()
        setIsLoading(false)
      })
    }
  }

  const confirm = () => {
    SweetAlert.fire({
      title: 'Atenção!',
      text: `Essa ação irá impactar na identificação desse ${entityLabels.singular.toLowerCase()} em aplicações passadas. Só prossiga se houver certeza que é o mesmo estudante. Em caso de dúvidas, entre em contato com nosso suporte.`,
      icon: 'warning',
      confirmButtonColor: getCssProperty('--primary-color'),
      cancelButtonColor: '#aaa',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    }).then((result: any) => {
      if (result.value) {
        update({
          ...entity,
          authorized: true,
        } as IRegistrationEntity)
      }
    })
  }

  const save = () => {
    if (column && entity) {
      setIsLoading(true)

      SweetAlert.fire({
        title: 'Aguarde, atualizando informações...',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => SweetAlert.showLoading()
      })

      update({
        ...entity,
        [column.key]: newValue,
      })
    }
  }

  const disabled = useMemo(() => {
    if (error.usage === EntityValidationErrorUsageEnum.CONFIRM_OR_REFUSE) {
      return duplicated.length === 0 || isLoading
    }

    if (column == null || (column.required && (newValue == null || newValue == value))) {
      return true
    }

    return isLoading
  }, [error.usage, column, newValue, value, isLoading, duplicated])

  return (
    <>
      <Modal.Body className="pb-0">
        <Alert variant="danger" className="text-center">{error.description}</Alert>

        {error.usage === EntityValidationErrorUsageEnum.EDIT && (
          <>
            <div className="form-group mb-3">
              <label>Informação atual:</label>
              <input type="text" className="form-control" defaultValue={value} readOnly />
            </div>

            <div className="form-group mb-3">
              <label>Nova informação:</label>
              <input
                type="text"
                className="form-control"
                placeholder={column?.label}
                onChange={handleNewValueChange} disabled={!column || isLoading}
              />
            </div>
          </>
        )}

        {error.usage === EntityValidationErrorUsageEnum.SELECT && (
          <>
            <div className="form-group mb-3">
              <label>Informação atual:</label>
              <input type="text" className="form-control" defaultValue={value} readOnly />
            </div>

            <div className="form-group mb-3">
              <label>Nova informação:</label>
              {column?.key === 'degreeName' && (
                <select className="form-control" onChange={handleNewValueChange} disabled={isLoading}>
                  <option value="">Selecione a série</option>
                  {degrees.filter(d => d.classes?.length > 0).map((degree: any) => (
                    <option value={degree.name} key={degree.id}>{degree.name}</option>
                  ))}
                </select>
              )}

              {column?.key === 'className' && (
                <select className="form-control" onChange={handleNewValueChange} disabled={isLoading}>
                  <option value="">Selecione a turma</option>
                  {degrees.find(degree => degree.name == entity?.degreeName)?.classes?.map((class_: any) => (
                    <option value={class_.name} key={class_.id}>{class_.name}</option>
                  ))}
                </select>
              )}

              {column?.key === 'disciplineName' && (
                <select className="form-control" onChange={handleNewValueChange} disabled={isLoading}>
                  <option value="">Selecione a disciplina</option>
                  {disciplines.map((discipline: any) => (
                    <option value={discipline.name} key={discipline.id}>{discipline.name}</option>
                  ))}
                </select>
              )}
            </div>
          </>
        )}

        {error.usage === EntityValidationErrorUsageEnum.CONFIRM_OR_REFUSE && duplicated.map((entity: any) => (
          <div className="form-group mb-3" key={entity.id}>
            <label>{entity.type == 2 ? 'Informação atual' : 'Nova informação'}:</label>
            <OutlineBox>
              {entity.identifier != null && (
                <div className="item">
                  <h4>RA:</h4>
                  <span>{entity.identifier}</span>
                </div>
              )}

              {entity.name?.length > 0 && (
                <div className="item">
                  <h4>Nome:</h4>
                  <span>{entity.name}</span>
                </div>
              )}

              {entity.nickanme?.length > 0 && (
                <div className="item">
                  <h4>Apelido:</h4>
                  <span>{entity.nickanme}</span>
                </div>
              )}

              {entity.email?.length > 0 && (
                <div className="item">
                  <h4>E-mail:</h4>
                  <span>{entity.email}</span>
                </div>
              )}
            </OutlineBox>
          </div>
        ))}
      </Modal.Body>

      <Modal.Footer className="d-flex justify-content-between">
        <button className="btn btn-outline-secondary" onClick={onCanceled}>Voltar</button>

        <button
          className="btn btn-success"
          onClick={() => error.usage === EntityValidationErrorUsageEnum.CONFIRM_OR_REFUSE ? confirm() : save()}
          disabled={disabled}
        >{error.usage === EntityValidationErrorUsageEnum.CONFIRM_OR_REFUSE ? 'Confirmar' : 'Salvar'}</button>
      </Modal.Footer>
    </>
  )
}

export default Resolve
