import React, { useContext } from 'react'
import RegistrationContext from '@contexts/Registration'
import { BsChevronRight } from 'react-icons/bs'
import { Buttons } from './DegreeGroups.styles'
import * as $DegreeGroup from '@services/DegreeGroup'
import RegistrationStepEnum from '@enums/RegistrationStep.enum'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import AuthContext from '@contexts/Auth'
import IDegree from '@interfaces/IDegree'
import IClass from '@interfaces/IClass'

const DegreeGroups: React.FC<any> = () => {
  const { year } = useContext(AuthContext)
  const { setDegreeGroupId, setStep, additionalData, setAdditionalData, isLoading, degreeGroups } = useContext(RegistrationContext)

  const handleDegreeGroupId = (id: number) => {
    $DegreeGroup.degrees(id, {
      year,
    }).then(({ data: degrees }) => {
      const classesId = degrees.reduce((acc: number[], degree: IDegree) => {
        const classesId = degree.classes.map((classItem: IClass) => classItem.id)
        return [...acc, ...classesId]
      }, []).join(',')

      setAdditionalData({
        ...additionalData,
        classesId,
      })

      setDegreeGroupId(id)
      setStep(RegistrationStepEnum.Selection)
    })
  }

  return (
    <div className="row p-5 pb-5">
      <div className="col-12 col-md-6">
        <Buttons className="row">
          {degreeGroups.length > 0 ? degreeGroups.map(degreeGroup => (
            <div className="col-12 col-md-6 mb-3" key={degreeGroup.id}>
              <button className="btn btn-outline-primary btn-option" onClick={() => handleDegreeGroupId(degreeGroup.id)}>
                {degreeGroup.name} <BsChevronRight size={22} />
              </button>
            </div>
          )) : (
            isLoading ?
              <div className="col-12 col-md-6 mb-3">
                <SkeletonTheme color="#f0f0f0" highlightColor="#fff">
                  <Skeleton count={1} height={70} />
                </SkeletonTheme>
              </div>
              : <div className="col-12 mb-3">
                Não é possível cadastrar professores sem existir turmas cadastradas no ano.
              </div>
          )}
        </Buttons>
      </div>
      { !isLoading && degreeGroups.length > 0 ? (
        <div className="col-12 col-md-6">
          <div className="description mb-3">
            Inscreva os professores de ensino Fundamental e de Ensino Médio usando os botões ao lado.
          </div>
        </div>
      ): null }
    </div>
  )
}

export default DegreeGroups
