import React, { useContext, useEffect, useState } from 'react'
import { IoMdClose } from 'react-icons/io'
import RegistrationContext from '@contexts/Registration'
import { random } from '@helpers/Utils'
import IRegistrationEntity from '@interfaces/IRegistrationEntity'
import IRegistrationTypeColumn from '@interfaces/IRegistrationTypeColumn'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import RegistrationStepEnum from '@enums/RegistrationStep.enum'
import Button from '@components/Button/Button'
import SelectField from './SelectField/SelectField'

const Manual: React.FC<any> = ({ instruction }: any) => {
  const { setStep, entities, storeEntities, registrationTypeAction, additionalData } = useContext(RegistrationContext)

  const [ isLoading, setIsLoading ] = useState<boolean>(false)
  const [ data, setData ] = useState<IRegistrationEntity[]>(entities)

  const SweetAlert = withReactContent(Swal)

  useEffect(() => {
    if (data.length == 0) {
      setData([ { id: random() } as IRegistrationEntity ])
    }
  }, [data])

  const handleInputChange = (e: React.ChangeEvent<HTMLSelectElement|HTMLInputElement>, entity: IRegistrationEntity, column: IRegistrationTypeColumn) => {
    const index = data.findIndex((item: IRegistrationEntity) => item.id == entity.id)

    if (index > -1) {
      const value = column.worker ? column.worker(e.target.value) : e.target.value

      data[index] = {
        ...entity,
        [column.key]: value,
        ...additionalData,
      }

      if ((index + 1) === data.length) {
        data.push({ id: random() } as IRegistrationEntity)
      }

      setData([ ...data ])
    }
  }

  const addEntity = () => {
    data.push({ id: random() } as IRegistrationEntity)
    setData([ ...data ])
  }

  const deleteEntity = (entity: IRegistrationEntity) => {
    const index = data.findIndex(e => e.id === entity.id)

    if (index !== -1) {
      data.splice(index, 1)
      setData([ ...data ])
    }
  }

  const save = () => {
    setIsLoading(true)

    const columns = registrationTypeAction?.getColumns()

    if (columns?.length) {
      for (let i = 0; i < data.length; i++) {
        const entity: any = data[i]

        let everyEmpty = true

        for (const column of columns) {
          if (column.key !== 'id' && entity[column.key]) {
            everyEmpty = false
            data[i].index = i
            break
          }
        }

        if (everyEmpty) {
          data.splice(i, 1)
          i--
        }
      }
    }

    storeEntities(data).then(() => setStep(RegistrationStepEnum.Finish)).catch(() => {
      SweetAlert.fire({
        title: 'Erro!',
        text: 'Ocorreu um erro ao processar os dados informados!',
        icon: 'error'
      })
    }).finally(() => setIsLoading(false))
  }

  return (
    <>
      <div className="card-body">
        <div className="row">
          <div className="col-12 col-md-8">
            <table className="table table-striped table-bordered mb-0">
              <thead>
                <tr>
                  {registrationTypeAction?.getLabels().map((label: string, index: number) => (
                    <th key={index}>{label}</th>
                  ))}
                  <th></th>
                </tr>
              </thead>

              <tbody>
                {data.map((entity: any) => (
                  <tr key={entity.id}>
                    {registrationTypeAction?.getColumns().map((column: IRegistrationTypeColumn, index: number) => (
                      <td style={{ verticalAlign: 'middle' }} key={index}>
                        {column.type === 'select' ? <SelectField entity={entity} column={column} onChange={handleInputChange} /> : (
                          <input type="text" className="form-control" value={entity[column.key]} onChange={e => handleInputChange(e, entity, column)} />
                        )}
                      </td>
                    ))}

                    <td style={{ verticalAlign: 'middle' }}>
                      <button className="btn btn-danger btn-sm ps-2 pe-2 text-white" onClick={() => deleteEntity(entity)} disabled={data.length <= 1}>
                        <IoMdClose />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="col-12 col-md-4">
            <h5 className="mb-0">Instruções de preenchimento</h5>
            <hr/>
            {instruction}
          </div>
        </div>
      </div>

      <div className="card-footer d-flex justify-content-between">
        <button className="btn btn-outline-primary" onClick={() => addEntity()}>Adicionar +</button>
        <Button className="btn btn-primary" onClick={save} disabled={isLoading || data.length === 0} isLoading={isLoading}>Continuar</Button>
      </div>
    </>
  )
}

export default Manual
