import IPlatform from '@interfaces/IPlatform'
import React from 'react'

interface IProps {
  platforms: IPlatform[],
  platformId: number,
  onClick: (platform: IPlatform) => void,
}

const Platforms: React.FC<any> = ({ platforms, platformId, onClick }: IProps) => {
  return (
    <div className="platforms d-flex flex-wrap">
      {platforms?.filter(platform => !platform.isCurrentPlatform).map((platform: IPlatform) => (
        <div key={platform.id} className="platform me-3">
          <button className={'btn mb-3 btn-rounded pt-1 pb-1 p-3 btn-' + (platform.id === platformId ? 'primary' : 'outline-primary')} onClick={() => onClick(platform)}>
            {platform.name}
          </button>
        </div>
      ))}
    </div>
  )
}

export default Platforms
