import IUserRegistrationEntity from '@interfaces/IUserRegistrationEntity'
import React, { createContext, useState } from 'react'
import { Outlet } from 'react-router-dom'

interface IUserRegistrationContextProps {
  token: string
  setToken: React.Dispatch<React.SetStateAction<string>>
  entities: IUserRegistrationEntity[]
  setEntities: React.Dispatch<React.SetStateAction<IUserRegistrationEntity[]>>
  isLoading: boolean
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  pushEntities: (entities: IUserRegistrationEntity[]) => Promise<void>
}

const UserRegistrationContext = createContext<IUserRegistrationContextProps>({ } as IUserRegistrationContextProps)

export const UserRegistrationProvider: React.FC = () => {
  const generateToken = () => Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)

  const [ token, setToken ] = useState<string>(generateToken())
  const [ entities, setEntities ] = useState<IUserRegistrationEntity[]>([])
  const [ isLoading, setIsLoading ] = useState<boolean>(false)

  const pushEntities = (entities: IUserRegistrationEntity[]) : Promise<void> => {
    return new Promise((resolve) => {
      setEntities(entities)

      setTimeout(() => resolve(), 1000)
    })
  }

  const value = {
    token,
    setToken,
    entities,
    setEntities,
    isLoading,
    setIsLoading,
    pushEntities,
  }

  return (
    <UserRegistrationContext.Provider value={value}>
      <Outlet />
    </UserRegistrationContext.Provider>
  )
}

export default UserRegistrationContext
