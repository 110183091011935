import IUser from '@interfaces/IUser'
import React, { useRef, useState } from 'react'
import ReactTags, { Tag } from 'react-tag-autocomplete'

const AutoComplete: React.FC<any> = ({ value, teachers, onAddition, onDelete }: any) => {
  const [ tags, setTags ] = useState<Tag[]>(value ?? [])

  const reactTags = useRef<any>()

  const handleOnDelete = (tagIndex: number) => {
    if (tagIndex == -1) return

    setTags((tags: Tag[]) => {
      onDelete(tags[tagIndex])
      tags = tags.filter((_, i) => i !== tagIndex)
      return tags
    })
  }

  const handleOnAddition = (newTag: Tag) => {
    if (newTag == null) return

    setTags((tags: Tag[]) => {
      onAddition(newTag)
      tags = [...tags, newTag]
      return tags
    })
  }

  return (
    <ReactTags
      ref={reactTags}
      tags={tags}
      suggestions={teachers.map((teacher: IUser) => ({ id: teacher.id, name: teacher?.nickname }))}
      onDelete={handleOnDelete}
      onAddition={handleOnAddition}
      placeholderText="Adicionar professor"
    />
  )
}

export default AutoComplete
