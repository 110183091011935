import React, { useContext } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import useTheme from '@hooks/useTheme'
import AuthContext, { AuthProvider } from '@contexts/Auth'
import Dashboard from '@screens/Dashboard/Dashboard'
import TeachersAndProducts from '@screens/TeachersAndProducts/TeachersAndProducts'
import StudentsAndProducts from '@screens/StudentsAndProducts/StudentsAndProducts'
import MassRegistration from '@screens/TeachersAndProducts/MassRegistration/MassRegistration'
import Students from '@screens/Students/Students'
import AppLayout from '@layouts/App/App'
import Login from '@screens/Login/Login'
import PanelContext, { PanelProvider } from '@contexts/Panel'
import Revisors from '@screens/Revisors/Revisors'
import Managers from '@screens/Managers/Managers'
import Teachers from '@screens/Teachers/Teachers'
import TeachersEdit from '@screens/Teachers/Teachers.edit'
import RevisorsEdit from '@screens/Revisors/Revisors.edit'
import ManagersEdit from '@screens/Managers/Managers.edit'
import StudentsEdit from '@screens/Students/Students.edit'
import { RegistrationProvider } from '@contexts/Registration'
import ManagersRegister from '@screens/Managers/Managers.register'
import TeachersRegister from '@screens/Teachers/Teachers.register'
import RevisorsRegister from '@screens/Revisors/Revisors.register'
import { StudentsAndProductsProvider } from '@contexts/StudentsAndProducts'
import UserRegistration from '@screens/UserRegistration/UserRegistration'
import { UserRegistrationProvider } from '@contexts/UserRegistration'
import Permission from '@enums/Permission.enum'
import LogUsers from '@screens/UserLogs/UserLogs'
import AvailableProducts from '@screens/AvailableProducts/AvailableProducts'
import Reportments from '@screens/Reportments/Reportments'
import Reportment from '@screens/Reportments/Reportment/Reportment'
import { ReportmentProvider } from '@contexts/Reportment'
import { NavigationProvider } from '@contexts/Navigation'
import Loading from '@components/Loading/Loading'
import { TeachersProvider } from '@contexts/Teachers'
import { TeacherProvider } from '@contexts/Teacher'
import Classes from '@screens/Classes/Classes'
import ClassesEdit from '@screens/Classes/Classes.edit'
import { ClassEditProvider } from '@contexts/ClassEdit'
import { MigrationProvider } from '@contexts/Migration'
import { ClassesProvider } from '@contexts/Classes'
import StudentsRegister from '@screens/Students/Students.register'

const Panel: React.FC = () => {
  const { client, permissions, isLoading } = useContext(AuthContext)
  const { platforms } = useContext(PanelContext)

  useTheme(client)

  return (
    <>
      <Loading text="Aguarde..." isLoading={isLoading} />

      <Routes>
        <Route element={<AppLayout />}>
          <Route index element={<Dashboard />} />

          {/* Students */}
          <Route path="/alunos" element={<Students />} />

          {permissions.includes(Permission.EDIT_STUDENTS) && (
            <Route path="/alunos/:id/editar" element={<StudentsEdit />} />
          )}

          {permissions.includes(Permission.CREATE_STUDENTS) && (
            <Route element={<RegistrationProvider />}>
              <Route element={<MigrationProvider />}>
                <Route path="/alunos/cadastrar" element={<StudentsRegister />} />
              </Route>
            </Route>
          )}

          {/* Teachers */}
          <Route element={<TeachersProvider />}>
            <Route path="/professores" element={<Teachers />} />
          </Route>

          {permissions.includes(Permission.EDIT_TEACHERS) && (
            <Route element={<TeacherProvider />}>
              <Route path="/professores/:id/editar" element={<TeachersEdit />} />
            </Route>
          )}

          {permissions.includes(Permission.CREATE_TEACHERS) && (
            <Route element={<RegistrationProvider />}>
              <Route path="/professores/cadastrar" element={<TeachersRegister />} />
            </Route>
          )}

          {/* Managers */}
          <Route path="/gestores" element={<Managers />} />

          {permissions.includes(Permission.EDIT_MANAGERS) && (
            <Route path="/gestores/:id/editar" element={<ManagersEdit />} />
          )}

          {permissions.includes(Permission.CREATE_MANAGERS) && (
            <Route element={<RegistrationProvider />}>
              <Route path="/gestores/cadastrar" element={<ManagersRegister />} />
            </Route>
          )}

          {/* Students And products */}
          {permissions.includes(Permission.ASSOCIATE_STUDENTS) && (
            <Route element={<StudentsAndProductsProvider />}>
              <Route path="/alunos-e-produtos" element={<StudentsAndProducts />} />
            </Route>
          )}

          {/* Teachers And Products */}
          {permissions.includes(Permission.ASSOCIATE_TEACHERS) && (
            <>
              <Route path="/professores-e-produtos" element={<TeachersAndProducts />} />
              <Route path="/professores-e-produtos/cadastro-em-massa" element={<MassRegistration />} />
            </>
          )}

          {/* Revisors */}
          {platforms.length > 0 && platforms.some((platform: any) => platform.id === 3) && (
            <>
              {permissions.includes(Permission.VIEW_REVISORS) && (
                <Route path="/corretores" element={<Revisors />} />
              )}

              {permissions.includes(Permission.EDIT_REVISORS) && (
                <Route path="/corretores/:id/editar" element={<RevisorsEdit />} />
              )}

              {permissions.includes(Permission.CREATE_REVISORS) && (
                <Route element={<RegistrationProvider />}>
                  <Route path="/corretores/cadastrar" element={<RevisorsRegister />} />
                </Route>
              )}
            </>
          )}

          <Route element={<UserRegistrationProvider />}>
            <Route path="/usuarios/cadastro-em-massa" element={<UserRegistration />} />
          </Route>

          <Route path="/historico-de-alteracoes" element={<LogUsers />} />

          {permissions.includes(Permission.ADMIN_EVOLUCIONAL) && (
            <Route path="/produtos-disponiveis" element={<AvailableProducts />} />
          )}

          {permissions.includes(Permission.VIEW_REPORTMENTS) && (
            <>
              <Route path="/relatorios" element={<Reportments />} />
              <Route element={<ReportmentProvider />}>
                <Route path="/relatorios/:id" element={<Reportment />} />
              </Route>
            </>
          )}

          {/* Classes */}
          {permissions.includes(Permission.VIEW_CLASS) && (
            <Route element={<ClassesProvider />}>
              <Route path="/turmas" element={<Classes />} />
            </Route>
          )}

          {permissions.includes(Permission.EDIT_CLASS) && (
            <Route element={<ClassEditProvider />}>
              <Route path="/turmas/:id/editar" element={<ClassesEdit />} />
            </Route>
          )}

          <Route path="*" element={<Dashboard />} />
        </Route>
      </Routes>
    </>
  )
}

const Root: React.FC = () => (
  <BrowserRouter>
    <Routes>
      <Route element={<AuthProvider />}>
        <Route element={<NavigationProvider />}>
          <Route element={<PanelProvider />}>
            <Route path="*" element={<Panel />} />
          </Route>

          <Route path="login" element={<Login />} />
        </Route>
      </Route>
    </Routes>
  </BrowserRouter>
)

export default Root
