import React, { forwardRef, useRef, useImperativeHandle } from 'react'
import * as FlexmonsterReact from 'react-flexmonster'

interface Ref {
  refresh: () => void
}

type Props = {
  ref?: React.Ref<Ref>
  report: any
}

const Flexmonster: React.FC<any> = forwardRef<Ref, Props>(({ report }: Props, ref) => {
  const pivotRef = useRef<FlexmonsterReact.Pivot>(null)

  useImperativeHandle(ref, () => ({
    refresh: () => {
      if (pivotRef.current !== null)
        pivotRef.current.flexmonster.refresh()
    },
  }))

  return (
    <FlexmonsterReact.Pivot
      ref={pivotRef}
      toolbar={true}
      beforetoolbarcreated={(toolbar: any) => {
        let tabs = toolbar.getTabs()

        toolbar.getTabs = () => {
          tabs = tabs.filter((tab: any) => {
            return ![
              'fm-tab-connect',
              'fm-tab-open',
              'fm-tab-share',
              'fm-tab-save',
            ].includes(tab.id)
          })

          return tabs
        }
      }}
      shareReportConnection={{
        url: 'https://olap.flexmonster.com:9500'
      }}
      width="100%"
      height={600}
      report={report}
    />
  )
})

Flexmonster.displayName = 'Flexmonster'

export default Flexmonster
