import axios from 'axios'
import IPlatform from '@interfaces/IPlatform'
import { IManagerAssociation } from '@interfaces/IManagerAssociation'
import { ISelectedAssociation } from '@interfaces/ISelectedAssociation'

const SSO_TOKEN = 'CB6A8856-8973-4E6B-A247-838BE5B22C91'

export const all = (): Promise<any> => {
  return axios.get('platforms')
}

export const associations = (params: any = {}): Promise<any> => {
  return axios.get('platforms/associations', {
    params,
  })
}

export const degreeGroups = (params: any = {}): Promise<any> => {
  return axios.get('platforms/degree-groups', {
    params,
  })
}

export const associate = (selectedAssociation: ISelectedAssociation, year: number): Promise<any> => {
  return axios.post(`platforms/${year}/associate`, selectedAssociation)
}

export const disassociate = (platformId: number, selectedAssociation: ISelectedAssociation, year: number): Promise<any> => {
  return axios.post(`platforms/${platformId}/disassociate/${year}`, selectedAssociation)
}

export const managers = (params: any = {}): Promise<any> => {
  return axios.get('platforms/managers', {
    params,
  })
}

export const managerAssociations = (associations: IManagerAssociation[]): Promise<any> => {
  return axios.put('platforms/managers', associations)
}

export const redirectUrl = (schoolId: number, userId: number, platform: IPlatform): Promise<any> => {
  return axios.post(`platforms/${platform.id}/redirect-url`, {
    userId,
    schoolId,
    token: SSO_TOKEN,
    assessmentModelId: platform.assessmentModelId,
  })
}
