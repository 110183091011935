import React, { useContext, useEffect, useMemo, useState } from 'react'
import * as $Student from '@services/Student'
import * as $Degree from '@services/Degree'
import * as Utils from '@helpers/Utils'
import IUser from '@interfaces/IUser'
import axios from 'axios'
import IDegree from '@interfaces/IDegree'
import IClass from '@interfaces/IClass'
import { Link, useNavigate } from 'react-router-dom'
import AuthContext from '@contexts/Auth'
import Pagination from '@components/Pagination/Pagination'
import { Spinner } from 'react-bootstrap'
import { usePagination, useSortBy, useTable } from 'react-table'
import columns from './../../data/ReactTableStudentColumns'
import { ReactComponent as Sort } from '@resources/svg/sort.svg'
import Delete from './Delete/Delete'
import DeleteAll from './DeleteAll/DeleteAll'
import Permission from '@enums/Permission.enum'
import Password from '@components/Password/Password'
import { Buttons } from './Students.styles'
import { PasswordProvider } from '@contexts/Password'

const Students: React.FC<any> = () => {
  const { school: { id: schoolId }, permissions, year } = useContext(AuthContext)

  const [ students, setStudents ] = useState<IUser[]>([])
  const [ degrees, setDegrees ] = useState<IDegree[]>([])
  const [ isLoading, setIsLoading ] = useState<boolean>(true)
  const [ search, setSearch ] = useState<string>('')
  const [ degreeName, setDegreeName ] = useState<string>('')
  const [ className, setClassName ] = useState<string>('')

  const navigate = useNavigate()

  useEffect(() => {
    setIsLoading(true)
    setDegreeName('')
    setClassName('')

    axios.all([
      $Student.all(year),
      $Degree.all({
        empty: false,
        year,
      }),
    ]).then(axios.spread(({ data: students }: any, { data: degrees }: any) => {
      setStudents(students)
      setDegrees(degrees)
    })).finally(() => setIsLoading(false))
  }, [schoolId, year])

  const handleOnDelete = (studentId: number) => {
    setStudents(students => students.filter(student => student.id !== studentId))
  }

  const data = useMemo(() => {
    return students.filter((student: IUser) => {
      let exists = true

      if (search.length > 0) {
        exists = student.name.toLowerCase().includes(search.toLowerCase()) ||
          student.nickname.toLowerCase().includes(search.toLowerCase()) ||
          student.email === search ||
          student.identifier.toString().includes(search) ||
          (student.usercode ? student.usercode.toString().includes(search) : false)
      }

      if (exists && degreeName.length > 0)
        exists = student.degreeName === degreeName

      if (exists && className.length > 0)
        exists = student.className === className

      return exists
    })
  }, [students, search, degreeName, className])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: {
      pageIndex,
    },
  }: any = useTable({
    columns,
    data,
    initialState: {
      pageIndex: 0,
      pageSize: 10,
    } as any,
  }, useSortBy, usePagination)

  const degree = useMemo(() => {
    return degrees.find((degree: IDegree) => degree.shortname === degreeName)
  }, [degrees, degreeName])

  const canDeleteAll = useMemo(() => students.every(student => student.accessCount === 0), [students])

  const exportStudents = () => {
    const data = students.map((student: IUser) => ([
      student.nickname,
      student.email,
      student.identifier,
      student.usercode,
      student.degreeName,
      student.className,
      student.password && student.password?.length > 20 ? 'Senha alterada' : student.password,
    ] as string[]))

    data.unshift([
      'Nome', 'Email', 'RA', 'RE', 'Série', 'Turma', 'Senha',
    ])

    Utils.exportXLSX(data, 'Alunos', 'Alunos.xlsx')
  }

  return (
    <>
      <div className="row align-items-end mb-3">
        <div className="col-12 col-md-7">
          <div className="row">
            {degrees.length > 0 && (
              <div className={'col-12 ' + (degrees.length ? (degree && degree.classes.length ? 'col-md-3' : 'col-md-4') : '')}>
                <div className="form-group mb-3">
                  <label htmlFor="degreeName">Série:</label>
                  <select
                    id="degreeName"
                    name="degreeName"
                    className="form-control"
                    onChange={e => {
                      setDegreeName(e.target.value)
                      setClassName('')
                    }}
                  >
                    <option value="">Todas</option>
                    {degrees.map((degree: IDegree) => (
                      <option value={degree.shortname} key={degree.id}>{degree.name}</option>
                    ))}
                  </select>
                </div>
              </div>
            )}

            {degree && degree?.classes?.length > 0 && (
              <div className="col-12 col-md-3">
                <div className="form-group mb-3">
                  <label htmlFor="className">Turma:</label>
                  <select id="className" name="className" className="form-control" onChange={e => setClassName(e.target.value)}>
                    <option value="">Todas</option>
                    {degree.classes.map((class_: IClass) => (
                      <option value={class_.name} key={class_.id}>{class_.name}</option>
                    ))}
                  </select>
                </div>
              </div>
            )}

            <div className="col-12 col-md-4">
              <div className="form-group mb-3">
                <label htmlFor="search">Pesquisar:</label>
                <input type="text" id="search" name="search" className="form-control" placeholder="Pesquise pelo nome, RA ou RE" onChange={e => setSearch(e.target.value)} />
              </div>
            </div>
          </div>
        </div>

        <Buttons className="col-12 col-md-5 d-flex justify-content-end mb-3">
          {permissions.includes(Permission.EXPORT_STUDENTS) && (
            <button className="btn btn-outline-primary" onClick={exportStudents}>Exportar</button>
          )}

          {permissions.includes(Permission.CREATE_STUDENTS) && year === new Date().getFullYear() && (
            <button className="btn btn-primary ms-2" onClick={() => navigate('/alunos/cadastrar')}>Cadastrar</button>
          )}
        </Buttons>
      </div>

      <div className="card">
        <div className="card-header">Alunos</div>

        <div className="p-0">
          {!isLoading ? (data.length ? (
            <div className="table-responsive">
              <table {...getTableProps()} className="table table-default mb-0">
                <thead>
                  {headerGroups.map((headerGroup: any, headerGroupIndex: number) => (
                    <tr {...headerGroup.getHeaderGroupProps()} key={headerGroupIndex}>
                      {headerGroup.headers.map((column: any, columnIndex: number) => (
                        <th {...column.getHeaderProps(column.getSortByToggleProps())} key={columnIndex}>
                          {column.render('Header')}
                          <Sort className={'sort-icon ms-1 ' + (column.isSorted ? column.isSortedDesc ? 'sorted-up' : 'sorted-down' : 'unsorted')} />
                        </th>
                      ))}
                      <th style={{ width: 120 }}>Senha</th>
                      {permissions.includes(Permission.EDIT_STUDENTS) && (
                        <th style={{ width: 120 }}>Ações</th>
                      )}
                    </tr>
                  ))}
                </thead>

                <tbody {...getTableBodyProps()}>
                  <PasswordProvider>
                    {page.map((row: any, rowIndex: number) => {
                      prepareRow(row)
                      return (
                        <tr {...row.getRowProps()} key={rowIndex}>
                          {row.cells.map((cell: any, index: number) => {
                            return (
                              <td style={{ verticalAlign: 'middle' }} {...cell.getCellProps()} key={index}>
                                {cell.render('Cell')}
                              </td>
                            )
                          })}

                          <td style={{ verticalAlign: 'middle' }}>
                            <Password userId={row.original.id} passwordLength={row.original.passwordLength} disabled={!row.original?.email?.length} />
                          </td>

                          {permissions.includes(Permission.EDIT_STUDENTS) && (
                            <td style={{ verticalAlign: 'middle' }}>
                              <div className="d-flex justify-content-end">
                                {year === new Date().getFullYear() ? (
                                  <>
                                    <Link to={`/alunos/${row.original.id}/editar`} className="btn btn-sm ps-4 pe-4 btn-primary">Editar</Link>
                                    {row.original.accessCount == 0 && (
                                      <Delete year={year} student={row.original} onDelete={handleOnDelete} />
                                    )}
                                  </>
                                ) : (
                                  <Link to={`/alunos/${row.original.id}/editar`} className="btn btn-sm ps-4 pe-4 btn-primary">+ Detalhes</Link>
                                )}
                              </div>
                            </td>
                          )}
                        </tr>
                      )
                    })}
                  </PasswordProvider>
                </tbody>
              </table>
            </div>
          ) : (
            <div className="bg-light text-center p-3">
              Nenhum aluno encontrado.
            </div>
          )) : (
            <div className="d-flex justify-content-center p-3 bg-light">
              <Spinner animation="border" variant="primary" />
            </div>
          )}
        </div>

        {(canDeleteAll || pageCount > 1) && (
          <div className="card-footer d-flex justify-content-between align-items-center">
            <div>
              {!isLoading && students?.length > 0 && year === new Date().getFullYear() && students.every(student => student.accessCount === 0) && permissions.includes(Permission.DELETE_STUDENTS) && (
                <DeleteAll year={year} />
              )}
            </div>

            <div>
              {pageCount > 1 && (
                <Pagination
                  page={pageIndex}
                  pageCount={pageCount}
                  canPreviousPage={canPreviousPage}
                  canNextPage={canNextPage}
                  nextPage={nextPage}
                  previousPage={previousPage}
                  onClick={(page: number) => gotoPage(page)}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default Students
