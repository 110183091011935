import React, { useContext, useEffect, useState } from 'react'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import * as $Teacher from '@services/Teacher'
import * as $Discipline from '@services/Discipline'
import { Modal } from 'react-bootstrap'
import TeacherContext from '@contexts/Teacher'
import IDiscipline from '@interfaces/IDiscipline'
import AuthContext from '@contexts/Auth'

type Props = {
  show: boolean
  onHide: () => void
}

const Associate: React.FC<any> = ({ show, onHide }: Props) => {
  const { year } = useContext(AuthContext)
  const { teacher, disciplineId, setDisciplineId, disciplines: teacherDisciplines, setDisciplines: setTeacherDisciplines, setAssociations, degreeGroups } = useContext(TeacherContext)

  const [ disciplines, setDisciplines ] = useState<IDiscipline[]>([])

  const SweetAlert = withReactContent(Swal)

  useEffect(() => {
    $Discipline.all().then(({ data }) => setDisciplines(data))
  }, [setDisciplines])

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    SweetAlert.fire({
      title: 'Aguarde...',
      text: 'Associando nova disciplina...',
      icon: 'info',
      didOpen: () => SweetAlert.showLoading(),
    })

    $Teacher.associateDiscipline(teacher.id as number, year, {
      disciplineId: parseInt(event.currentTarget.disciplineId.value),
      degreeGroupId: parseInt(event.currentTarget.degreeGroupId.value),
    }).then(({ data: { disciplines, associations } }: any) => {
      setTeacherDisciplines(disciplines)
      setAssociations(associations)

      if (disciplineId === 0)
        setDisciplineId(disciplines[0]?.id ?? 0)

      onHide()

      SweetAlert.fire({
        title: 'Sucesso!',
        text: 'Disciplina associada com sucesso!',
        icon: 'success',
      })
    }).catch(e => {
      SweetAlert.fire({
        title: 'Erro!',
        text: e.response?.data?.message ?? 'Erro ao associar a disciplina!',
        icon: 'error',
      })
    })
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Associar nova disciplina</Modal.Title>
      </Modal.Header>

      <form onSubmit={onSubmit}>
        <Modal.Body>
          {degreeGroups.length ? (
            <>
              <div className="select-group mb-3">
                <label htmlFor="disciplineId">Disciplina:</label>
                <select name="disciplineId" id="disciplineId" className="form-control" required>
                  <option value="">Selecione uma disciplina</option>
                  {disciplines.filter(discipline => !teacherDisciplines.find(teacherDiscipline => teacherDiscipline.id === discipline.id)).map(d => (
                    <option key={d.id} value={d.id}>{d.name}</option>
                  ))}
                </select>
              </div>

              <div className="select-group">
                <label htmlFor="degreeGroupId">Segmento:</label>
                <select name="degreeGroupId" id="degreeGroupId" className="form-control" required>
                  <option value="">Selecione um segmento</option>
                  {degreeGroups.map(degreeGroup => (
                    <option key={degreeGroup.id} value={degreeGroup.id}>{degreeGroup.name}</option>
                  ))}
                </select>
              </div>
            </>
          ) : (
            <div className="alert alert-warning">
              Não foram cadastradas turmas para o ano vigente.
            </div>
          )}
        </Modal.Body>

        <Modal.Footer className="d-flex justify-content-end">
          <button type="submit" className="btn btn-primary" disabled={!degreeGroups.length}>Associar</button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default Associate
