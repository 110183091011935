import React, { useEffect, useState } from 'react'
import IUser from '@interfaces/IUser'
import * as $Coordinator from '@services/Manager'
import { Link, useNavigate, useParams } from 'react-router-dom'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'

const CoordinatorsEdit: React.FC<any> = () => {
  const { id: managerId }: any = useParams()

  const [ manager, setManager ] = useState<IUser>({} as IUser)
  const [ isLoading, setIsLoading ] = useState<boolean>(true)

  const SweetAlert = withReactContent(Swal)
  const navigate = useNavigate()

  useEffect(() => {
    $Coordinator.find(managerId).then(({ data: manager }) => setManager(manager)).finally(() => setIsLoading(false))
  }, [managerId, navigate])

  const handleUserChange = (e: any) => {
    const { name, value } = e.target

    setManager({
      ...manager,
      [name]: value,
    })
  }

  const update = () => {
    setIsLoading(true)

    SweetAlert.showLoading()

    $Coordinator.update(manager.id as number, {
      name: manager.name,
      email: manager.email,
      nickname: manager?.nickname,
      password: manager.password,
      passwordConfirmation: manager.passwordConfirmation,
    } as IUser).then(() => {
      SweetAlert.fire({
        title: 'Sucesso!',
        text: 'Gestor atualizado com sucesso!',
        icon: 'success'
      })
    }).catch(e => {
      SweetAlert.fire({
        title: 'Erro!',
        text: e.response?.data?.message ?? 'Erro ao atualizar o gestor!',
        icon: 'error'
      })
    }).finally(() => {
      setIsLoading(false)
      SweetAlert.hideLoading()
    })
  }

  return (
    <>
      <div className="d-flex justify-content-end mb-3">
        <Link to="/gestores" className="btn btn-outline-primary me-2">Voltar</Link>
        <button className="btn btn-primary" onClick={update} disabled={isLoading}>Atualizar</button>
      </div>

      <div className="card mb-3">
        <div className="card-header">
          Editar gestor ({manager?.nickname})
        </div>

        <div className="card-body">
          <div className="row">
            <div className="col-md-4">
              <div className="form-group mb-3">
                <label htmlFor="nickname">Apelido</label>
                <input type="text" className="form-control" name="nickname" id="nickname" placeholder="E-mail" defaultValue={manager?.nickname} onChange={handleUserChange} />
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group mb-3">
                <label htmlFor="name">Nome</label>
                <input type="text" className="form-control" name="name" id="name" placeholder="Nome" defaultValue={manager?.name} onChange={handleUserChange} />
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group mb-3">
                <label htmlFor="email">E-mail</label>
                <input type="email" className="form-control" name="email" id="email" placeholder="E-mail" defaultValue={manager?.email} onChange={handleUserChange} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card mb-3">
        <div className="card-header">
          Alteração de senha
        </div>

        <div className="card-body pb-0">
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="form-group mb-3">
                <label htmlFor="password">Nova senha (mínimo 6 caracteres)</label>
                <input type="password" name="password" className="form-control" id="password" placeholder="Nova senha" onChange={handleUserChange} autoComplete="new-password" />
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="form-group mb-3">
                <label htmlFor="passwordConfirmation">Confirmação da senha</label>
                <input type="password" name="passwordConfirmation" className="form-control" id="passwordConfirmation" placeholder="Confirmação da senha" onChange={handleUserChange} autoComplete="new-password" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CoordinatorsEdit
