import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { Spinner } from 'react-bootstrap'
import IReportment from '@interfaces/IReportment'
import * as $Reportment from '@services/Reportment'
import Pagination from '@components/Pagination/Pagination'
import { ReactComponent as Sort } from '@resources/svg/sort.svg'
import columns from './../../data/ReactTableReportmentColumns'
import { usePagination, useSortBy, useTable } from 'react-table'
import * as Utils from '@helpers/Utils'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import PanelContext from '@contexts/Panel'
import NavigationContext from '@contexts/Navigation'
import axios from 'axios'

const Reportments: React.FC<any> = () => {
  const { platforms } = useContext(PanelContext)
  const { hideNavigation } = useContext(NavigationContext)

  const [ search, setSearch ] = useState<string>('')
  const [ platformId, setPlatformId ] = useState<number>(0)
  const [ reportments, setReportments ] = useState<IReportment[]>([] as IReportment[])
  const [ authorizations, setAuthorizations ] = useState<any[]>([] as any[])
  const [ isLoading, setIsLoading ] = useState(true)

  const SweetAlert = withReactContent(Swal)

  useEffect(() => {
    axios.all([
      $Reportment.authorizations(),
      $Reportment.all(),
    ]).then(axios.spread(({ data: authorizations }, { data: reportments }) => {
      setAuthorizations(authorizations)
      setReportments(reportments)
    })).finally(() => setIsLoading(false))
  }, [])

  const data = useMemo(() => reportments.filter((reportment: IReportment) => {
    let exists = true

    if (search.length > 0) {
      exists = reportment.name.toLowerCase().includes(search.toLowerCase()) || reportment.description.toLowerCase().includes(search.toLowerCase())
    }

    if (platformId > 0) {
      exists = exists && reportment.platformId === platformId
    }

    return exists
  }), [platformId, reportments, search])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: {
      pageIndex,
    },
  }: any = useTable({
    columns,
    data,
    initialState: {
      pageIndex: 0,
      pageSize: 10,
    } as any,
  }, useSortBy, usePagination)

  const exportReportment = (reportment: IReportment) => {
    setIsLoading(true)

    SweetAlert.fire({
      title: 'Aguarde, gerando relatório...',
      text: 'Essa atividade pode demorar alguns minutos dependendo do relatório.',
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => SweetAlert.showLoading(),
    })

    $Reportment.download(reportment.id).then(({ data }) => {
      Utils.exportCSV(data, `${reportment.name}.csv`)

      SweetAlert.fire({
        title: 'Relatório gerado com sucesso!',
        text: 'Arquivos CSV são formatados utilizando a separação por ponto-e-vírgula.',
        icon: 'success',
        didOpen: () => SweetAlert.hideLoading(),
      })
    }).catch(() => SweetAlert.fire({
      title: 'Erro!',
      text: 'Não foi possível exportar o relatório, tente novamente mais tarde.',
      icon: 'error'
    })).finally(() => setIsLoading(false))
  }

  const handlePlatformIdChange = ({ target: { value: platformId } }: React.ChangeEvent<HTMLSelectElement>) => {
    if (isNaN(parseInt(platformId))) {
      setPlatformId(0)
    } else {
      setPlatformId(parseInt(platformId))
    }
  }

  const requestAuthorization = (reportment_id: number) => {
    SweetAlert.fire({
      title: 'Solicitação de autorização de relatório',
      text: 'Aguarde, solicitação em andamento...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      didOpen: () => SweetAlert.showLoading(),
    })

    $Reportment.requestAuthorization(reportment_id).then(({ data }) => {
      setAuthorizations([
        ...authorizations,
        data,
      ])

      SweetAlert.fire({
        title: 'Solicitação de autorização de relatório',
        text: 'Solicitação enviada com sucesso!',
        icon: 'success',
      })
    }).catch(e => SweetAlert.fire({
      title: 'Solicitação de autorização de relatório',
      text: e.response?.data?.message ?? 'Erro ao solicitar autorização!',
      icon: 'error',
    }))
  }

  return (
    <>
      <div className="row align-items-center">
        <div className="col-12 col-md-6 mb-3">
          <h1 className="mb-0">Relatórios</h1>
          {!hideNavigation && (
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item"><Link to="/">Dashboard</Link></li>
              <li className="breadcrumb-item active" aria-current="page">Relatórios</li>
            </ol>
          )}
        </div>

        <div className="col-12 col-md-6 mb-3">
          <div className="row">
            <div className="col-12 col-md-8">
              <div className="form-group">
                <label htmlFor="search">Pesquisar:</label>
                <input
                  type="text"
                  id="search"
                  name="search"
                  className="form-control"
                  placeholder="Pesquise pelo nome ou descrição"
                  onChange={e => setSearch(e.target.value)}
                />
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="form-group">
                <label htmlFor="search">Plataforma:</label>
                <select className="form-control" onChange={handlePlatformIdChange}>
                  <option value="">-- Todas as plataformas --</option>
                  {platforms.map(platform => (
                    <option value={platform.id} key={platform.id}>{platform.name}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-header">Relatórios ({data.length})</div>

        <div className="card-body p-0">
          {data.length ? (
            <div className="table-responsive">
              <table {...getTableProps()} className="table table-default mb-0">
                <thead>
                  {headerGroups.map((headerGroup: any, headerGroupIndex: number) => (
                    <tr {...headerGroup.getHeaderGroupProps()} key={headerGroupIndex}>
                      {headerGroup.headers.map((column: any, columnIndex: number) => (
                        <th {...column.getHeaderProps(column.getSortByToggleProps())} key={columnIndex}>
                          {column.render('Header')}
                          <Sort className={'sort-icon ms-1 ' + (column.isSorted ? column.isSortedDesc ? 'sorted-up' : 'sorted-down' : 'unsorted')} />
                        </th>
                      ))}
                      <th>Plataforma</th>
                      <th style={{ width: 120 }}>Ações</th>
                    </tr>
                  ))}
                </thead>

                <tbody {...getTableBodyProps()}>
                  {page.map((row: any, rowIndex: number) => {
                    prepareRow(row)
                    return (
                      <tr {...row.getRowProps()} key={rowIndex}>
                        {row.cells.map((cell: any, index: number) => {
                          return (
                            <td style={{ verticalAlign: 'middle' }} {...cell.getCellProps()} key={index}>
                              {cell.render('Cell')}
                            </td>
                          )
                        })}
                        <td style={{ verticalAlign: 'middle' }}>
                          {platforms.find(platform => platform.id === row.original.platformId)?.name ?? (
                            <span className="text-muted">
                              {platforms.length ? 'N/A' : 'Carregando...'}
                            </span>
                          )}
                        </td>
                        <td style={{ verticalAlign: 'middle' }}>
                          <div className="d-flex justify-content-end">
                            {row.original.canPreview ? (
                              <Link to={`/relatorios/${row.original.id}`} className="btn btn-sm btn-primary pe-3 ps-3">Visualizar</Link>
                            ) : row.original.needsAuthorization && !row.original.hasAuthorization ? (() => {
                              const authorization = authorizations.find(a => a.reportmentId === row.original.id)

                              return (
                                <button
                                  className="btn btn-primary pe-3 ps-3"
                                  onClick={() => requestAuthorization(row.original.id)}
                                  disabled={authorization && !authorization.isApproved}
                                >
                                  {authorization ? authorization.isApproved === null ? (
                                    <>Authorização solicitada</>
                                  ) : !authorization.isApproved && (
                                    <>Autorização negada</>
                                  ) : (
                                    <>Solicitar autorização</>
                                  )}
                                </button>
                              )
                            })() : (
                              <button className="btn btn-sm btn-primary pe-3 ps-3" onClick={() => exportReportment(row.original)} disabled={isLoading}>Exportar CSV</button>
                            )}
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          ) : isLoading ? (
            <div className="d-flex justify-content-center p-3 bg-light">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : (
            <div className="bg-light text-center p-3">
              Nenhum relatório disponível.
            </div>
          )}
        </div>

        {pageCount > 1 && (
          <div className="card-footer d-flex justify-content-end">
            <Pagination
              page={pageIndex}
              pageCount={pageCount}
              canPreviousPage={canPreviousPage}
              canNextPage={canNextPage}
              nextPage={nextPage}
              previousPage={previousPage}
              onClick={(page: number) => gotoPage(page)}
            />
          </div>
        )}
      </div>
    </>
  )
}

export default Reportments
