import React, { useState } from 'react'
import * as $Student from '@services/Student'
import Button from '@components/Button/Button'
import { getCssProperty } from '@helpers/Theme'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'

type IDeleteProps = {
  year: number;
  onDelete: () => void;
}

const DeleteAll: React.FC<any> = ({ year, onDelete }: IDeleteProps) => {
  const [ isLoading, setIsLoading ] = useState<boolean>(false)

  const SweetAlert = withReactContent(Swal)

  const destroy = () => {
    SweetAlert.fire({
      title: 'Deseja prosseguir?',
      text: 'Deseja prosseguir com a exclusão de todos os alunos? Você não poderá voltar atrás dessa decisão.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: getCssProperty('--secondary-color'),
      cancelButtonColor: getCssProperty('--primary-color'),
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    }).then((result: any) => {
      if (result.value) {
        setIsLoading(true)

        $Student.destroyAll(year).then(() => onDelete()).finally(() => {
          setIsLoading(false)
        })
      }
    })
  }

  return (
    <Button className="btn btn-sm btn-danger text-white ps-4 pe-4" onClick={destroy} disabled={isLoading} isLoading={isLoading}>Excluir todos os alunos</Button>
  )
}

export default DeleteAll
