import styled from 'styled-components'
import Indeterminate from '@components/Indeterminate/Indeterminate'

export const Checkbox = styled(Indeterminate)`
  width: 18px;
  height: 18px;
`

export const Remove = styled.button`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  padding: 0;

  > svg {
    position: relative;
    top: -1px;
  }

  &:hover {
    border-color: var(--secondary-color);
    background-color: var(--secondary-color);
    color: #fff;
  }
`
