import React, { useContext } from 'react'
import { Modal } from 'react-bootstrap'
import { AiOutlineWarning } from 'react-icons/ai'
import RegistrationContext from '@contexts/Registration'
import Validation from '../Validation/Validation'
import IRegistrationTypeColumn from '@interfaces/IRegistrationTypeColumn'
import Button from '@components/Button/Button'
import Delete from './Delete/Delete'
import ReactTooltip from 'react-tooltip'
import RoleEnum from '@enums/Role.enum'

type Props = {
  onFinish: () => void
  isLoading: boolean
  nextStep?: any
}

const Finish: React.FC<Props> = ({ onFinish, isLoading, nextStep }: Props) => {
  const { entity, setEntity, entityLabels, entities, validations, registrationTypeAction, usersUpdate, role } = useContext(RegistrationContext)

  const onHide = () => setEntity(null)

  return (
    <>
      <Modal
        backdrop="static"
        keyboard={false}
        show={!!entity}
        onHide={onHide}
      >
        <Validation hide={onHide} />
      </Modal>

      <div className="card">
        <div className="card-body p-0">
          <div className="p-3">
            {entities.length > 0 ? (
              <>
                <div className="alert alert-info">
                  {validations.length > 0 ? (
                    <>
                      <strong>Atenção:</strong> Verifique se os dados estão corretos e execute as ações de validação necessárias antes de finalizar o cadastro.
                    </>
                  ) : (
                    <>
                      <strong>Atenção:</strong> Verifique se os dados estão corretos antes de finalizar o cadastro.
                    </>
                  )}
                </div>

                <div className="table-responsive border">
                  <table className="table table-striped table-dashed mb-0">
                    <thead>
                      <tr>
                        <th></th>
                        {registrationTypeAction?.getLabels().map((label: string, index: number) => (
                          <th key={index}>{label}</th>
                        ))}
                        <th></th>
                      </tr>
                    </thead>

                    <tbody>
                      {entities.map((entity: any) => {
                        const validation = validations.find(validation => validation?.rowId === entity?.id)
                        const hasRowUpdates = usersUpdate.find(hasRowUpdate => hasRowUpdate?.rowId === entity?.id)

                        return entity?.id && (
                          <tr key={entity.id}>
                            <td>
                              {validation ?
                                <AiOutlineWarning className="text-danger" />
                                : (role === RoleEnum.Manager || role === RoleEnum.Teacher) && hasRowUpdates &&
                                <>
                                  <AiOutlineWarning className="text-warning" data-tip data-for="infoUpdate" />
                                  <ReactTooltip id="infoUpdate" place="top" effect="solid">
                                    {entityLabels.singular} já existente. <br />Dados serão atualizados.
                                  </ReactTooltip>
                                </>}
                            </td>

                            {registrationTypeAction?.getColumns().map((column: IRegistrationTypeColumn) => (
                              <td valign="middle" className={validation ? 'text-danger' : ''} key={column.key}>
                                {entity[column.key] ?? column.placeholder}
                              </td>
                            ))}

                            <td>
                              <div className="d-flex justify-content-end">
                                {validation && (
                                  <button className="btn btn-sm btn-success me-2 text-white" onClick={() => setEntity(entity)}>Corrigir</button>
                                )}

                                <Delete entity={entity} />
                              </div>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </>
            ) : (
              <div className="alert alert-warning text-center mb-0">
                Nenhum {entityLabels.singular.toLocaleLowerCase()} foi adicionado.
              </div>
            )}
          </div>
        </div>

        <div className="card-footer d-flex justify-content-end">
          <Button
            className="btn btn-primary pe-3 ps-3"
            disabled={isLoading || !entities.length || validations.length > 0}
            onClick={onFinish}
            isLoading={isLoading}
          >
            {nextStep?.label ?? 'Confirmar e finalizar'}
          </Button>
        </div>
      </div>
    </>
  )
}

export default Finish
