import React, { useContext, useEffect } from 'react'
import AuthContext from '@contexts/Auth'
import NavigationContext from '@contexts/Navigation'
import { useNavigate, useLocation } from 'react-router-dom'
import Loading from '@components/Loading/Loading'

const Login: React.FC<any> = () => {
  const { login, redirectToLogin } = useContext(AuthContext)
  const { setHideNavigation } = useContext(NavigationContext)

  const navigate = useNavigate()
  const { search } = useLocation()

  useEffect(() => {
    const params = new URLSearchParams(search)

    if (params.has('token')) {
      const token: string = params.get('token') as string

      login(token).then(() => {
        if (params.has('hideNavigation') && params.get('hideNavigation') === '1') {
          setHideNavigation(true)
        } else {
          setHideNavigation(false)
        }

        if (params.has('redirectTo')) {
          navigate(params.get('redirectTo') ?? '/')
        } else {
          navigate('/')
        }
      }).catch(redirectToLogin)
    }
  }, [search, login, setHideNavigation, navigate, redirectToLogin])

  return <Loading text="Aguarde..." isLoading={true} />
}

export default Login
