import React, { useContext, useEffect, useMemo, useState } from 'react'
import AuthContext from '@contexts/Auth'
import { List, Tooltip } from '../Migration.styles'
import IClass from '@interfaces/IClass'
import * as $Class from '@services/Class'
import MigrationContext from '@contexts/Migration'
import { TiArrowSortedUp, TiArrowSortedDown, TiArrowUnsorted } from 'react-icons/ti'

const CurrentYear: React.FC<any> = () => {
  const { year } = useContext(AuthContext)
  const { migrated, undo, setUndo, nextClassId, setNextClassId, classes, isLoading, setIsLoading } = useContext(MigrationContext)

  const [ students, setStudents ] = useState<any[]>([])
  const [ display, setDisplay ] = useState<string>('all')
  const [ order, setOrder ] = useState<string>('asc')
  const [ orderBy, setOrderBy ] = useState<string>('name')

  useEffect(() => {
    if (classes.length > 0 && nextClassId > 0) {
      const classroom = classes.find((c: IClass) => c.id === nextClassId)

      if (!classroom)
        return

      $Class.students(classroom.degreeId, nextClassId, {
        year,
      }).then(({ data }: any) => setStudents(data)).finally(() => setIsLoading(false))
    }
  }, [nextClassId, classes, year, setIsLoading])

  const handleUndoStudent = ({ target: { checked } }: React.ChangeEvent<HTMLInputElement>, student: any) => {
    if (checked && !undo.some((s: any) => s.id === student.id)) {
      setUndo([...undo, student])
    } else if (!checked && undo.some((s: any) => s.id === student.id)) {
      setUndo(undo.filter(s => s.id !== student.id))
    }
  }

  const data = useMemo(() => {
    return (display === 'all' ? [...students, ...migrated] : migrated).sort((a: any, b: any) => {
      if (order === 'asc') {
        return a[orderBy].toString().localeCompare(b[orderBy].toString())
      } else {
        return b[orderBy].toString().localeCompare(a[orderBy].toString())
      }
    }).filter((s: any) => {
      return s.classId === nextClassId
    })
  }, [display, migrated, nextClassId, order, orderBy, students])

  const handleOrderBy = (accessor: string) => {
    setOrderBy(accessor)
    setOrder(order === 'asc' ? 'desc' : 'asc')
  }

  const handleSelectAll = ({ target: { checked } }: React.ChangeEvent<HTMLInputElement>) => {
    if (checked) {
      setUndo([...migrated])
    } else {
      setUndo([])
    }
  }

  return (
    <>
      <table className="table table-default table-borderless table-sm mb-0">
        <tbody>
          <tr>
            <td valign="middle">
              <label htmlFor="year">Ano</label>
            </td>
            <td>
              <select id="year" className="form-control" disabled>
                <option>{year}</option>
              </select>
            </td>
          </tr>

          <tr>
            <td valign="middle">
              <label htmlFor="nextClassId">Série - Turma</label>
            </td>
            <td>
              <select
                id="nextClassId"
                className="form-control"
                onChange={e => setNextClassId(parseInt(e.target.value))}
                disabled={isLoading}
              >
                <option value={0}>Selecione</option>
                {classes.map((classroom: IClass) => {
                  const length = migrated.filter(s => s.classId === classroom.id).length

                  return (
                    <option value={classroom.id} key={`class-${classroom.id}`}>
                      {classroom.degreeShortname} - {classroom.name}&nbsp;
                      {length > 0 ? `(${length} aluno${length !== 1 ? 's' : ''} em migração)` : ''}
                    </option>
                  )
                })}
              </select>
            </td>
          </tr>

          <tr>
            <td valign="middle">
              <label htmlFor="all-2">Exibir</label>
            </td>
            <td>
              <div className="d-flex">
                <div className="form-check me-3">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="current__display"
                    id="current-2"
                    value="current"
                    onChange={({ target: { value } }) => setDisplay(value)}
                    defaultChecked
                  />
                  <label className="form-check-label" htmlFor="current-2">Apenas em migração</label>
                </div>

                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="current__display"
                    id="all-2"
                    value="all"
                    onChange={({ target: { value } }) => setDisplay(value)}
                  />
                  <label className="form-check-label" htmlFor="all-2">Todos</label>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <List>
        <table className="table table-bordered table-default mb-0">
          <thead className="table-light">
            <tr>
              <th style={{ width: 60 }} scope="col">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="undo-all"
                    disabled={!migrated?.length}
                    onChange={handleSelectAll}
                    checked={migrated?.length > 0 && undo.length === migrated.length}
                  />
                </div>
              </th>
              <th scope="col">
                <button className="btn btn-link p-0" onClick={() => handleOrderBy('identifier')}>
                  {orderBy === 'identifier' ? order === 'asc' ? (
                    <TiArrowSortedUp size={14} />
                  ) : (
                    <TiArrowSortedDown size={14} />
                  ) : (
                    <TiArrowUnsorted size={14} />
                  )} RA
                </button>
              </th>
              <th scope="col">
                <button className="btn btn-link p-0" onClick={() => handleOrderBy('usercode')}>
                  {orderBy === 'usercode' ? order === 'asc' ? (
                    <TiArrowSortedUp size={14} />
                  ) : (
                    <TiArrowSortedDown size={14} />
                  ) : (
                    <TiArrowUnsorted size={14} />
                  )} RE
                </button>
              </th>
              <th scope="col">
                <button className="btn btn-link p-0" onClick={() => handleOrderBy('name')}>
                  {orderBy === 'name' ? order === 'asc' ? (
                    <TiArrowSortedUp size={14} />
                  ) : (
                    <TiArrowSortedDown size={14} />
                  ) : (
                    <TiArrowUnsorted size={14} />
                  )} Nome
                </button>
              </th>
            </tr>
          </thead>

          <tbody>
            {data.map((student: any) => {
              const isMigrated = migrated.some((s: any) => s.id === student.id)

              return (
                <Tooltip className={isMigrated ? 'active' : ''} key={`student-${student.id}`}>
                  <td>
                    {isMigrated && (
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          checked={isMigrated && undo.some((s: any) => s.id === student.id)}
                          onChange={e => handleUndoStudent(e, student)}
                        />
                      </div>
                    )}
                  </td>
                  <td>{student.identifier}</td>
                  <td>{student.usercode}</td>
                  <td className="tooltip-container">
                    {student.name}
                    {student.tooltip?.length > 0 ? (
                      <div className="tooltip" dangerouslySetInnerHTML={{ __html: student.tooltip }} />
                    ) : isMigrated ? (
                      <div className="tooltip">Em processo de migração</div>
                    ) : null}
                  </td>
                </Tooltip>
              )
            })}
          </tbody>
        </table>

        {data.length === 0 && (
          <div className="text-center text-muted py-5">
            {isLoading ? 'Carregando...' : (nextClassId > 0 ? 'Nenhum aluno encontrado' : 'Selecione uma turma para exibir os alunos')}
          </div>
        )}
      </List>
    </>
  )
}

export default CurrentYear
