import styled from 'styled-components'

export const ContainerFluid = styled.div`
  height: calc(100vh - 100px);

  > .row {
    height: 100%;

    > .main {
      padding: var(--spacing-padding);
      padding-bottom: var(--spacing-padding);

      @media (min-width: 768px) {
        > .main {
          padding-bottom: var(--spacing-padding);
        }
      }
    }

    @media (min-width: 800px) {
      > .sidebar,
      > .main {
        transition: width 0.2s ease;
      }
    }
  }
`
