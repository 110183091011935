enum Permission {
  VIEW_STUDENTS = 'VIEW_STUDENTS',
  EDIT_STUDENTS = 'EDIT_STUDENTS',
  CREATE_STUDENTS = 'CREATE_STUDENTS',
  DELETE_STUDENTS = 'DELETE_STUDENTS',
  VIEW_TEACHERS = 'VIEW_TEACHERS',
  EDIT_TEACHERS = 'EDIT_TEACHERS',
  CREATE_TEACHERS = 'CREATE_TEACHERS',
  DELETE_TEACHERS = 'DELETE_TEACHERS',
  VIEW_REVISORS = 'VIEW_REVISORS',
  EDIT_REVISORS = 'EDIT_REVISORS',
  CREATE_REVISORS = 'CREATE_REVISORS',
  DELETE_REVISORS = 'DELETE_REVISORS',
  VIEW_MANAGERS = 'VIEW_MANAGERS',
  EDIT_MANAGERS = 'EDIT_MANAGERS',
  CREATE_MANAGERS = 'CREATE_MANAGERS',
  DELETE_MANAGERS = 'DELETE_MANAGERS',
  ADMIN_EVOLUCIONAL = 'ADMIN_EVOLUCIONAL',
  ASSOCIATE_STUDENTS = 'ASSOCIATE_STUDENTS',
  ASSOCIATE_TEACHERS = 'ASSOCIATE_TEACHERS',
  VIEW_REPORTMENTS = 'VIEW_REPORTMENTS',
  EXPORT_STUDENTS = 'EXPORT_STUDENTS',
  VIEW_CLASS = 'VIEW_CLASS',
  EDIT_CLASS = 'EDIT_CLASS',
  CREATE_CLASS = 'CREATE_CLASS',
  DELETE_CLASS = 'DELETE_CLASS',
}

export default Permission
