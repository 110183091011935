import React, { createContext, Dispatch, SetStateAction, useCallback, useEffect, useState, useContext } from 'react'
import axios from 'axios'
import * as $Degree from '@services/Degree'
import * as $Platform from '@services/Platform'
import IDegree from '@interfaces/IDegree'
import { ISelectedAssociation } from '@interfaces/ISelectedAssociation'
import { IPlatformSelection } from '@interfaces/IPlatformSelection'
import { IStudentSelection } from '@interfaces/IStudentSelection'
import IPlatformAssociationUpdate from '@interfaces/IPlatformAssociationUpdate'
import { Outlet } from 'react-router-dom'
import AuthContext from './Auth'

interface IStudentsAndProductsContextProps {
  degrees: IDegree[]
  degree: IDegree
  setDegree: Dispatch<SetStateAction<IDegree>>
  students: any
  associations: any
  platformCounts: any
  degreeGroups: any
  isLoading: boolean
  setIsLoading: Dispatch<SetStateAction<boolean>>
  selectedAssociation: ISelectedAssociation
  setSelectedAssociation: Dispatch<SetStateAction<ISelectedAssociation>>
  onCheckAll: (e: any, degreeId: number, classId: number) => void
  onCheck: (e: any, degreeId: number, classId: number, studentId: number) => void
  refresh: () => void
  pushPlatform: (platformId: number, productId?: number) => void
  removePlatform: (platformId: number, productId?: number) => void
  associationUpdate: IPlatformAssociationUpdate|null
  setAssociationUpdate: Dispatch<SetStateAction<IPlatformAssociationUpdate|null>>
  doSearch: (search: string) => void,
  year: number,
}

const StudentsAndProductsContext = createContext<IStudentsAndProductsContextProps>({ } as IStudentsAndProductsContextProps)

export const StudentsAndProductsProvider: React.FC = () => {
  const { year } = useContext(AuthContext)

  const [ degrees, setDegrees ] = useState<IDegree[]>([])
  const [ degree, setDegree ] = useState<IDegree>({} as IDegree)
  const [ students, setStudents ] = useState<any>({})
  const [ associations, setAssociations ] = useState<any>({})
  const [ platformCounts, setPlatformCounts ] = useState<any>({})
  const [ degreeGroups, setDegreeGroups ] = useState<any>({})
  const [ selectedAssociation, setSelectedAssociation ] = useState<ISelectedAssociation>({} as ISelectedAssociation)
  const [ isLoading, setIsLoading ] = useState<boolean>(true)
  const [ associationUpdate, setAssociationUpdate ] = useState<IPlatformAssociationUpdate|null>(null)

  const getData = useCallback((search = '') => {
    setIsLoading(true)

    axios.all([
      $Degree.all({
        search,
        empty: false,
        year,
      }),
      $Platform.degreeGroups({
        year,
      }),
      $Platform.associations({
        year,
        search,
      }),
    ]).then(axios.spread(({ data: degrees }: any, { data: degreeGroups }: any, { data: { platformCounts, associations, students } }: any) => {
      setDegrees(degrees)
      setDegree(degrees[0])
      setPlatformCounts(platformCounts)
      setDegreeGroups(degreeGroups)
      setAssociations(associations)
      setStudents(students)
    })).finally(() => setIsLoading(false))
  }, [year])

  useEffect(() => {
    getData()
  }, [getData])

  const handleSelection = (degreeId: number) => {
    if (selectedAssociation?.degreeId !== degreeId) {
      selectedAssociation.degreeId = degreeId
      selectedAssociation.platforms = []
      selectedAssociation.students = []
    }
  }

  const onCheckAll = ({ target: { checked } }: any, degreeId: number, classId: number) => {
    handleSelection(degreeId)

    if (checked && students[degreeId] != null && students[degreeId][classId] != null) {
      Object.entries(students[degreeId][classId])?.forEach(([ studentId ]) => {
        const index = selectedAssociation.students.findIndex((student: IStudentSelection) => student.id === parseInt(studentId) && student.classId === classId)

        if (index === -1) {
          selectedAssociation.students.push({
            id: parseInt(studentId),
            classId,
          })
        }
      })
    } else {
      selectedAssociation.students = selectedAssociation.students.filter((student: IStudentSelection) => student.classId !== classId)
    }

    setSelectedAssociation({ ...selectedAssociation })
  }

  const onCheck = ({ target: { checked } }: any, degreeId: number, classId: number, studentId: number) => {
    handleSelection(degreeId)

    const index = selectedAssociation.students.findIndex((student: IStudentSelection) => student.id === studentId && student.classId === classId)

    if (checked && index === -1) {
      selectedAssociation.students.push({
        id: studentId,
        classId,
      })
    } else if (!checked && index !== -1) {
      selectedAssociation.students.splice(index, 1)
    }

    setSelectedAssociation({ ...selectedAssociation })
  }

  const pushPlatform = (platformId: number, productId?: number) => {
    const platformIndex = selectedAssociation.platforms.findIndex((platform: IPlatformSelection) => platform.id === platformId)

    if (platformIndex === -1) {
      selectedAssociation.platforms.push({
        id: platformId,
        productIds: productId ? [ productId ] : [],
      })
    } else if (platformIndex !== -1 && productId) {
      const productIndex = selectedAssociation.platforms[platformIndex].productIds.findIndex((id: number) => id === productId)

      if (productIndex === -1) {
        selectedAssociation.platforms[platformIndex].productIds.push(productId)
      }
    }

    setSelectedAssociation({ ...selectedAssociation })
  }

  const removePlatform = (platformId: number, productId?: number) => {
    const platformIndex = selectedAssociation.platforms.findIndex((platform: IPlatformSelection) => platform.id === platformId)

    if (platformIndex !== -1) {
      if (productId) {
        const productIndex = selectedAssociation.platforms[platformIndex].productIds.findIndex((id: number) => id === productId)

        if (productIndex !== -1) {
          selectedAssociation.platforms[platformIndex].productIds.splice(productIndex, 1)

          if (selectedAssociation.platforms[platformIndex].productIds.length === 0) {
            selectedAssociation.platforms.splice(platformIndex, 1)
          }
        }
      } else {
        selectedAssociation.platforms.splice(platformIndex, 1)
      }
    }

    setSelectedAssociation({ ...selectedAssociation })
  }

  const refresh = () => {
    setIsLoading(true)

    setSelectedAssociation({
      degreeId: 0,
      platforms: [],
      students: [],
    })

    axios.all([
      $Platform.degreeGroups({
        year,
      }),
      $Platform.associations({
        year,
      }),
    ]).then(axios.spread(({ data: degreeGroups }: any, { data: { associations, students } }: any) => {
      setDegreeGroups(degreeGroups)
      setAssociations(associations)
      setStudents(students)
    })).finally(() => setIsLoading(false))
  }

  const doSearch = (search = '') => getData(search)

  const value = {
    degrees,
    degree,
    setDegree,
    students,
    associations,
    platformCounts,
    degreeGroups,
    isLoading,
    setIsLoading,
    selectedAssociation,
    setSelectedAssociation,
    associationUpdate,
    setAssociationUpdate,
    onCheckAll,
    onCheck,
    refresh,
    pushPlatform,
    removePlatform,
    doSearch,
    year,
  }

  return (
    <StudentsAndProductsContext.Provider value={value}>
      <Outlet />
    </StudentsAndProductsContext.Provider>
  )
}

export default StudentsAndProductsContext
