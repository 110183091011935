import React, { useEffect, useState } from 'react'
import UserLogsComponent from '@components/UserLogs/UserLogs'
import * as $UserLog from '@services/UserLog'
import IUserLog from '@interfaces/IUserLog'
import { Spinner } from 'react-bootstrap'

const UserLogs: React.FC = () => {

  const [ userLogs, setUserLogs ] = useState<IUserLog[]>([])
  const [ isLoading, setIsLoading ] = useState<boolean>(true)

  useEffect(() => {
    $UserLog.all().then(({ data }) => setUserLogs(data)).finally(() => setIsLoading(false))
  }, [])

  return (
    <>
      {!isLoading ? (
        <UserLogsComponent data={userLogs} />
      ) : (
        <div className="d-flex justify-content-center p-3 bg-light">
          <Spinner animation="border" variant="primary" />
        </div>
      )}
    </>
  )
}

export default UserLogs
