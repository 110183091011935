import styled from 'styled-components'

export const RegisteredAnswers = styled.div`
  text-align: left;
  border: 1px solid #f0f0f0;
  border-radius: 5px;
  padding: 16px;

  > .title {
    font-weight: 500;
    line-height: 1.3rem;
    font-size: 1rem;
    color: #000;
  }

  > .items {
    > div {
      margin-bottom: 10px;

      > .student {
        font-size: 0.9rem;
        margin-bottom: 4px;
      }

      > .product {
        font-size: 0.9rem;
        color: #4f6587;
      }

      &:not(:last-child)::after {
        width: 40px;
        height: 1px;
        display: block;
        background-color: #999;
        margin-top: 10px;
        content: '';
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`
