import React, { useContext, useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai'
import AutoComplete from '../Components/Autocomplete'
import { Tag } from 'react-tag-autocomplete'
import * as $Teacher from '@services/Teacher'
import * as $Degree from '@services/Degree'
import * as $Discipline from '@services/Discipline'
import axios from 'axios'
import { getCssProperty } from '@helpers/Theme'
import { useNavigate } from 'react-router'
import { ProductsContainer } from './MassRegistration.styles'
import { IDisciplineAssociation, ITeacherDiscipline } from '../TeachersAndProducts'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import IPlatform from '@interfaces/IPlatform'
import IDegree from '@interfaces/IDegree'
import IDiscipline from '@interfaces/IDiscipline'
import Degrees from '../Components/Degrees'
import IClass from '@interfaces/IClass'
import PanelContext from '@contexts/Panel'
import AuthContext from '@contexts/Auth'

const MassRegistration: React.FC<any> = () => {
  const { year } = useContext(AuthContext)
  const { platforms } = useContext(PanelContext)

  const [ degrees, setDegrees ] = useState<IDegree[]>([])
  const [ selectedPlatforms, setSelectedPlatforms ] = useState<number[]>([])
  const [ teachers, setTeachers ] = useState<any[]>([])
  const [ disciplines, setDisciplines ] = useState<any[]>([])
  const [ data, setData ] = useState<ITeacherDiscipline[]>([])
  const [ isLoading, setIsLoading ] = useState<boolean>(true)
  const [ associations, setAssociations ] = useState<IDisciplineAssociation[]>([])

  const SweetAlert = withReactContent(Swal)
  const navigate = useNavigate()

  useEffect(() => {
    axios.all([
      $Teacher.all({
        year,
      }),
      $Teacher.disciplines({
        year,
      }),
      $Degree.all({
        year,
      }),
      $Discipline.all(),
    ]).then(axios.spread(({ data: teachers }: any, { data: associations }: any, { data: degrees }: any, { data: disciplines }: any) => {
      setTeachers(teachers)
      setAssociations(associations)
      setDegrees(degrees)
      setDisciplines(disciplines)
    })).finally(() => setIsLoading(false))
  }, [year])

  useEffect(() => {
    if (platforms.length) {
      setSelectedPlatforms(platforms.map((platform: IPlatform) => platform.id))
    }
  }, [platforms])

  const handleSelectPlatform = (e: any) => {
    const { checked, value } = e.target
    const platformId = parseInt(value)

    if (checked) {
      const index = selectedPlatforms.indexOf(platformId)
      if (index === -1) {
        setSelectedPlatforms([ ...selectedPlatforms, platformId ])
      }
    } else {
      const index = selectedPlatforms.indexOf(platformId)
      if (index !== -1) {
        selectedPlatforms.splice(index, 1)
        setSelectedPlatforms([ ...selectedPlatforms ])
      }
    }
  }

  const handleOnAddition = (tag: Tag, disciplineId: number, degreeId: number, classId: number) => {
    setData([
      ...data,
      {
        userId: tag.id,
        disciplineId,
        degreeId,
        classId,
      },
    ])
  }

  const handleOnDelete = (tag: Tag, disciplineId: number, degreeId: number, classId: number) => {
    const index = data.findIndex((item: ITeacherDiscipline) => item.userId === tag.id && item.classId === classId && item.disciplineId === disciplineId)
    if (index !== -1) {
      setData(data.filter((_, i: number) => i !== index))
    }
  }

  const cancel = () => {
    navigate('/professores-e-produtos')
  }

  const save = () => {
    setIsLoading(true)

    SweetAlert.fire({
      title: 'Cadastrando professores...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => SweetAlert.showLoading()
    })

    $Teacher.massRegistration(selectedPlatforms, data).then(() => {
      SweetAlert.fire({
        title: 'Professores cadastrados com sucesso!',
        icon: 'success',
        didOpen: () => SweetAlert.hideLoading(),
      }).then(() => {
        setIsLoading(false)
        navigate('/professores-e-produtos')
      })
    }).catch(() => {
      SweetAlert.fire({
        title: 'Erro ao cadastrar professores!',
        icon: 'error',
        didOpen: () => SweetAlert.hideLoading(),
      }).then(() => setIsLoading(false))
    })
  }

  return (
    <div className="teachers-and-products-page">
      <div className="card mb-4">
        <div className="card-header">
          <span>Cadastro em massa</span>
        </div>

        <div className="card-body pb-0">
          <div className="mb-3 pt-3">
            <h6>Professores</h6>
          </div>

          <ProductsContainer className="mb-3">
            <h6 className="mb-4">Adicionar para os seguintes produtos</h6>
            <div className="d-flex justify-content-between align-items-center products-checkbox-with-label">
              {platforms?.filter(platform => !platform.isCurrentPlatform).map((platform: IPlatform) => (
                <div key={platform.id} className="form-check">
                  <input type="checkbox" id={`platform-${platform.id}`} className="form-check-input" value={platform.id} defaultChecked={true} onChange={handleSelectPlatform} />
                  <label htmlFor={`platform-${platform.id}`} className="form-check-label">
                    <strong>{platform.name}</strong>
                  </label>
                </div>
              ))}
            </div>
          </ProductsContainer>

          {degrees?.length > 0 ? (
            <Degrees
              degrees={degrees}
              disciplines={disciplines}
              associations={associations}
              label={(data: IDisciplineAssociation[], index: number) => {
                return (
                  <div className="discipline" key={`length-${index}`}>
                    <span>
                      {data.length} {data.length > 0 ? <AiFillCheckCircle size={18} color={getCssProperty('--primary-color')} /> : <AiFillCloseCircle size={18} color="#999" />}
                    </span>
                  </div>
                )
              }}
              render={(data: IDisciplineAssociation[], class_: IClass, discipline: IDiscipline) => (
                <div className="teachers">
                  <AutoComplete
                    teachers={teachers}
                    onAddition={(tag: Tag) => handleOnAddition(tag, discipline.id, class_.degreeId, class_.id)}
                    onDelete={(tag: Tag) => handleOnDelete(tag, discipline.id, class_.degreeId, class_.id)}
                  />
                </div>
              )}
            />
          ) : (isLoading ? (
            <div className="d-flex justify-content-center p-3 mb-3 bg-light">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : (
            <div className="alert bg-light text-center">Não há resultados a serem exibidos.</div>
          ))}

          <div className="d-flex justify-content-end mb-3">
            <button className="btn btn-outline-primary me-3" onClick={cancel}>Cancelar</button>
            <button className="btn btn-primary" onClick={save}>Salvar</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MassRegistration
