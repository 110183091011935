import React, { useContext } from 'react'
import AuthContext from '@contexts/Auth'
import { MdNavigateBefore, MdNavigateNext } from 'react-icons/md'
import LastYears from './LastYears/LastYears'
import CurrentYear from './CurrentYear/CurrentYear'
import { Buttons, Container } from './Migration.styles'
import MigrationContext from '@contexts/Migration'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import RegistrationContext from '@contexts/Registration'
import RegistrationStepEnum from '@enums/RegistrationStep.enum'

const Migration: React.FC<any> = () => {
  const { year } = useContext(AuthContext)
  const { setStep, storeEntities, registrationTypeAction } = useContext(RegistrationContext)
  const { lastYear, selected, setSelected, migrated, setMigrated, undo, setUndo, lastClassId, nextClassId: classId, classes, isLoading, setIsLoading } = useContext(MigrationContext)

  const SweetAlert = withReactContent(Swal)

  const doMigrate = () => {
    if (lastYear === year) {
      SweetAlert.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Não é possível migrar para o mesmo ano!',
      })

      return
    }

    const classroom = classes.find(c => c.id === classId)

    if (!classroom)
      return

    selected.filter(student => {
      return !migrated.includes((s: any) => s.id === student.id)
    }).forEach(s => migrated.push({
      ...s,
      year,
      classId,
      lastClassId,
      className: classroom.name,
      degreeId: classroom.degreeId,
      degreeName: classroom.degreeName,
    }))

    console.log(migrated)

    setMigrated([ ...migrated ])
    setSelected([])
  }

  const undoMigrate = () => {
    setMigrated([
      ...migrated.filter(student => !undo.some((s: any) => s.id === student.id)),
    ])

    setSelected([
      ...selected.filter(student => !undo.some((s: any) => s.id === student.id)),
    ])

    setUndo([])
  }

  const doSave = () => {
    setIsLoading(true)

    const data: any[] = []
    const columns = registrationTypeAction?.getColumns()

    if (columns?.length) {
      migrated.forEach(student => {
        const entity: any = {}

        columns.forEach(column => {
          entity[column.key] = student[column.key] ?? null
        })

        data.push(entity)
      })
    }

    storeEntities(data).then(() => setStep(RegistrationStepEnum.Finish)).catch(() => SweetAlert.fire({
      title: 'Erro!',
      text: 'Ocorreu um erro ao processar os dados informados!',
      icon: 'error'
    })).finally(() => setIsLoading(false))
  }

  return (
    <Container>
      <div className="card-header d-flex justify-content-between">
        <div className="mb-0">
          Selecione os alunos que deseja migrar para as séries e turmas do ano seguinte.
        </div>

        <div className="text-muted mb-0">
          {migrated.length} aluno{migrated.length === 1 ? '' : 's'} selecionado{migrated.length === 1 ? '' : 's'} para migração.
        </div>
      </div>

      <div className="card-body">
        <div className="row">
          <div className="col-12 col-md-5">
            <LastYears />
          </div>

          <Buttons className="col-12 col-md-2">
            <button className="btn btn-outline-primary" disabled={!selected.length || classId === 0 || isLoading} onClick={doMigrate}>
              <MdNavigateNext size={24} />
            </button>

            <button className="btn btn-outline-primary" disabled={!undo.length || classId === 0 || isLoading} onClick={undoMigrate}>
              <MdNavigateBefore size={24} />
            </button>
          </Buttons>

          <div className="col-12 col-md-5">
            <CurrentYear />
          </div>
        </div>
      </div>

      <div className="card-footer d-flex align-items-center justify-content-between">
        <div className="text-muted mb-0 me-2">
          {migrated.length} aluno{migrated.length === 1 ? '' : 's'} selecionado{migrated.length === 1 ? '' : 's'} para migração.
        </div>

        <button className="btn btn-primary btn-sm" disabled={!migrated.length} onClick={doSave}>Continuar</button>
      </div>
    </Container>
  )
}

export default Migration
