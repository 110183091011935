import moment from 'moment'
import React from 'react'
import { RegisteredAnswers } from './AssociationError.styles'

const AssociationError: React.FC<any> = ({ data }) => {
  return (
    <>
      {data?.inscriptionDateEnded && (
        <div className="alert alert-warning">
          O período de inscrição da escola finalizou em: {moment(data.inscriptionDateEnded.endedAt).format('D/MM/YYYY [ás] H:mm:ss')}.
        </div>
      )}

      {data?.registeredAnswers?.length > 0 && (
        <RegisteredAnswers className="mb-3">
          <div className="title mb-3">Não é possível desassociar alunos de simulados desse lote pois ao menos um deles já possui nota em alguma avaliação. Os alunos nessa situação são:</div>

          <div className="items">
            {data.registeredAnswers.map((registeredAnswer: any, index: number) => (
              <div key={index}>
                <div className="student">{registeredAnswer.student}</div>
                <div className="product">{registeredAnswer.product}</div>
              </div>
            ))}
          </div>
        </RegisteredAnswers>
      )}

      <div className="alert alert-info mb-0">
        Nenhum aluno, mesmo que não esteja indicado acima, foi desassociado de avaliações.
      </div>
    </>
  )
}

export default AssociationError
