import React, { useState } from 'react'
import { AiFillSave } from 'react-icons/ai'
import { getCssProperty } from '@helpers/Theme'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import * as $Class from '@services/Class'

const Edit: React.FC<any> = ({ degree, class_, onUpdated }: any) => {
  const [ isLoading, setIsLoading ] = useState<boolean>(false)

  const SweetAlert = withReactContent(Swal)

  const handleClick = () => {
    if (!class_.name.length) return

    setIsLoading(true)

    $Class.update(degree.id, class_.id, class_)
      .then(() => onUpdated(degree, class_))
      .catch((e) => SweetAlert.fire({
        title: e.response?.data?.message ?? 'Erro ao atualizar a turma',
        icon: 'error',
      }))
      .finally(() => setIsLoading(false))
  }

  return (
    <div className="d-flex justify-content-between align-items-center">
      <input
        type="text"
        className="form-control-plaintext ps-2 pe-2 me-2"
        defaultValue={class_.name}
        size={class_.name.length}
        onChange={(e) => class_.name = e.target.value}
        disabled={isLoading}
        autoFocus
      />
      <AiFillSave size={26} color={getCssProperty('--primary-color')} onClick={handleClick} />
    </div>
  )
}

export default Edit
