import styled from 'styled-components'

export const MenuItemContainer = styled.div`
  @media (min-width: 768px) {
    width: 100%;
    border-bottom: 1px solid #e5e5f0;
  }

  @media (max-width: 767px) {
    width: 100px;
    min-width: 100px;
    border-right: 1px solid #e5e5f0;
  }
`

export const Button = styled.button`
  width: 100%;
  height: 67px;
  background-color: transparent;
  position: relative;
  padding: 0;
  border: 0;

  > .btn-inner {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    transition: all 0.2s ease;
    position: relative;
    padding: 0 8px;
    z-index: 2;

    > .icon {
      width: 24px;
      min-width: 24px;
      height: 24px;
      transition: all 0.2s ease;

      > svg {
        max-width: 24px;
        max-height: 24px;
        fill: var(--primary-color);
      }
    }

    > .title {
      width: 100%;
      display: block;
      text-align: center;
      font-size: 0.825rem;
      transition: all 0.2s ease;
      color: var(--primary-color);
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      opacity: 1;
    }
  }

  &::after {
    width: 100%;
    height: 100%;
    content: '';
    display: block;
    position: absolute;
    transition: all 0.2s ease;
    background-color: var(--primary-color);
    opacity: 0;
    bottom: 0;
    left: 0;
  }

  @media (min-width: 768px) {
    > .btn-inner {
      flex-direction: row;
      justify-content: flex-start;

      > .icon {
        margin-right: calc(var(--spacing-padding) / 2);
      }

      > .title {
        text-align: left;
        font-size: 0.975rem;
      }
    }
  }

  @media (min-width: 992px) {
    > .btn-inner {
      > .title {
        font-size: 1.143rem;
      }
    }
  }
`
