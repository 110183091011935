import React, { useState, useContext } from 'react'
import { Modal } from 'react-bootstrap'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import ClassroomContext from '@contexts/Classes'
import * as $Classroom from '@services/Class'
import { getCssProperty } from '@helpers/Theme'

const Create: React.FC<any> = () => {
  const { showClassCreate, setShowClassCreate, degrees, getClasses } = useContext(ClassroomContext)
  const [ degreeId, setDegreeId ] = useState<number>(0)
  const [ className, setClassName ] = useState<string>('')

  const SweetAlert = withReactContent(Swal)

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (degreeId == 0 || className?.trim().length == 0) {
      SweetAlert.fire({
        title: 'Erro!',
        text: 'Insira informações válidas.',
        icon: 'error',
        confirmButtonColor: getCssProperty('--primary-color'),
      })

      return
    }

    SweetAlert.fire({
      title: 'Aguarde...',
      showConfirmButton: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => SweetAlert.showLoading(),
    })

    $Classroom.create(degreeId, className).then(() => {
      SweetAlert.fire({
        title: 'Sucesso!',
        text: 'Turma criada com sucesso!',
        icon: 'success',
        confirmButtonColor: getCssProperty('--primary-color'),
      })

      getClasses()
      hideModal()
    }).catch(e => SweetAlert.fire({
      title: 'Erro!',
      text: e.response?.data?.message ?? 'Ocorreu um erro ao criar turma. Tente novamente mais tarde.',
      icon: 'error',
      confirmButtonColor: getCssProperty('--primary-color'),
    }))
  }

  const handleReset = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    hideModal()
  }

  const hideModal = () => {
    setShowClassCreate(false)
    setDegreeId(0)
    setClassName('')
  }

  return (
    <Modal
      show={showClassCreate}
      onHide={() => setShowClassCreate(false)}
      centered
    >
      <form onSubmit={handleSubmit} onReset={handleReset}>
        <Modal.Header>
          <h5 className="mb-0">Criar turma</h5>
        </Modal.Header>

        <Modal.Body className="mt-3 mb-3">
          <div className="form-group mb-3">
            <label>Série</label>
            <select className="form-control" name="degreeId" onChange={(e: any) => setDegreeId(e.target.value)}>
              <option value="0">Selecione uma série</option>
              {degrees.map((degree: any) => (
                <option value={degree.id} key={degree.id}>{degree.name}</option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label>Turma</label>
            <input type="text" className="form-control" name="className" onChange={(e: any) => setClassName(e.target.value)} />
          </div>
        </Modal.Body>

        <Modal.Footer className="d-flex justify-content-end">
          <button className="btn btn-outline-primary me-2" type="reset">Cancelar</button>
          <button className="btn btn-primary" type="submit">Criar</button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default Create
