import styled from 'styled-components'
import { BsChevronRight as BsChevronRightComponent } from 'react-icons/bs'

export const BsChevronRight = styled(BsChevronRightComponent)`
  transition: transform 0.2s;
  cursor: pointer;

  &.is-expanded {
    transform: rotate(90deg);
  }
`
