import React, { useContext } from 'react'
import { ToastContainer } from './Notifications.styles'
import AuthContext from '@contexts/Auth'
import INotification from '@interfaces/INotification'
import * as $Notification from '@services/Notification'
import { useNavigate } from 'react-router-dom'
import { Toast } from 'react-bootstrap'
import moment from 'moment'

const Notifications: React.FC<any> = () => {
  const { notifications, setNotifications } = useContext(AuthContext)

  const navigate = useNavigate()

  const setNotificationAsRead = (notification: INotification) => {
    const index = notifications.findIndex((n) => n.id === notification.id)
    notifications[index].deletedAt = new Date()
    setNotifications([...notifications])
  }

  const onClose = (notification: INotification) => $Notification.destroy(notification.id).then(() => setNotificationAsRead(notification))

  const onClick = (notification: INotification) => $Notification.destroy(notification.id).then(() => {
    setNotificationAsRead(notification)
    navigate(notification.url)
  })

  return (
    <ToastContainer position="bottom-end">
      {notifications.map(notification => (
        <Toast
          show={!notification.deletedAt}
          bg={notification.type}
          key={notification.id}
          onClose={() => onClose(notification)}
        >
          <Toast.Header>
            <strong className="me-auto">{notification.title}</strong>
            <small>
              {moment(notification.createdAt).fromNow()}
            </small>
          </Toast.Header>

          <Toast.Body>
            {notification.message}
          </Toast.Body>

          <Toast.Body className="d-flex justify-content-end pt-1 pb-1">
            <button className="btn btn-link" onClick={() => onClick(notification)}>
              <small>+ detalhes</small>
            </button>
          </Toast.Body>
        </Toast>
      ))}
    </ToastContainer>
  )
}

export default Notifications
