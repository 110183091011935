import React, { createContext } from 'react'
import useStickyState from '@hooks/useStickyState'
import { Outlet } from 'react-router-dom'

type NavigationContextProps = {
  hideNavigation: boolean
  setHideNavigation: React.Dispatch<React.SetStateAction<boolean>>
}

const NavigationContext = createContext<NavigationContextProps>({ } as NavigationContextProps)

export const NavigationProvider: React.FC = () => {
  const [ hideNavigation, setHideNavigation ] = useStickyState<boolean>(false, 'hideNavigation')

  return (
    <NavigationContext.Provider value={{ hideNavigation, setHideNavigation }}>
      <Outlet />
    </NavigationContext.Provider>
  )
}

export default NavigationContext
