import React, { useContext, useState } from 'react'
import { getCssProperty } from '@helpers/Theme'
import { AiFillCloseCircle } from 'react-icons/ai'
import { BiEdit } from 'react-icons/bi'
import RegistrationContext from '@contexts/Registration'
import IClass from '@interfaces/IClass'
import IDegree from '@interfaces/IDegree'
import * as $Class from '@services/Class'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import Edit from './Class.Edit'
import Create from './Class.Create'
import RegistrationStepEnum from '@enums/RegistrationStep.enum'
import { Spinner } from 'react-bootstrap'

const Degrees: React.FC<any> = () => {
  const { degrees, setDegrees, setStep, isLoading } = useContext(RegistrationContext)

  const [ currentClass, setCurrentClass ] = useState<IClass|null>(null)

  const SweetAlert = withReactContent(Swal)

  const removeClass = (degree: IDegree, class_: IClass) => {
    SweetAlert.fire({
      title: 'Deseja remover a turma?',
      text: `A turma ${class_.name} será removida da série ${degree.name}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: getCssProperty('--secondary-color'),
      cancelButtonColor: getCssProperty('--primary-color'),
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    }).then((result: any) => {
      if (result.value) {
        $Class.remove(degree.id, class_.id).then(() => onDeleted(degree, class_)).catch(() => SweetAlert.fire({
          title: 'Erro ao remover turma',
          icon: 'error',
        }))
      }
    })
  }

  const onDeleted = (degree: IDegree, class_: IClass) => {
    const index = degree.classes.findIndex((c: IClass) => c.id === class_.id)
    degree.classes.splice(index, 1)
    setDegrees(degrees.map((d: IDegree) => d.id === degree.id ? { ...d, classes: degree.classes } : d))
  }

  const onUpdated = (degree: IDegree, class_: IClass) => {
    const index = degree.classes.findIndex((c: IClass) => c.id === class_.id)
    degree.classes[index] = class_
    setDegrees(degrees.map((d: IDegree) => d.id === degree.id ? { ...d, classes: degree.classes } : d))

    setCurrentClass(null)
  }

  const onSaved = (degree: IDegree, class_: IClass) => {
    const degreeIndex = degrees.findIndex((d: IDegree) => d.id === degree.id)

    setDegrees([
      ...degrees.slice(0, degreeIndex),
      {
        ...degree,
        classes: [
          ...degree.classes,
          class_,
        ],
      },
      ...degrees.slice(degreeIndex + 1),
    ])

    setCurrentClass(null)
  }

  return (
    <div className="students-register-degrees-page">
      <div className="card-body pb-0">
        {!isLoading && degrees.length > 0 ? degrees.map((degree: any) => (
          <div className="row align-items-center mb-3" key={`degree-${degree.id}`}>
            <div className="col-12 col-md-2">
              <span>{degree.name}</span>
            </div>

            <div className="col-12 col-md-7 d-flex justify-content-evenly flex-wrap">
              {degree.classes.length ? degree.classes.map((class_: IClass) => (
                <div className="d-flex align-items-center" key={`${degree.id}-${class_.name}`}>
                  {!class_.hasAnyStudent && (
                    <AiFillCloseCircle className="me-2" color={getCssProperty('--secondary-color')} size={18} onClick={() => removeClass(degree, class_)} />
                  )}

                  {currentClass?.id === class_.id ? (
                    <Edit degree={degree} class_={class_} onUpdated={onUpdated} />
                  ) : (
                    <>
                      <span className="me-2 pt-1">{class_.name}</span>
                      <BiEdit size={16} color={getCssProperty('--primary-color')} onClick={() => setCurrentClass(class_)} />
                    </>
                  )}
                </div>
              )) : (
                <span className="text-muted text-center">Turmas não cadastradas</span>
              )}
            </div>

            <div className="col-12 col-md-3">
              <Create degree={degree} onSaved={onSaved} />
            </div>
          </div>
        )) : (
          <div className="d-flex justify-content-center p-3 bg-light mb-3">
            <Spinner animation="border" variant="primary" />
          </div>
        )}
      </div>

      <div className="card-footer d-flex justify-content-end">
        <button className="btn btn-primary" onClick={() => setStep(RegistrationStepEnum.Selection)} disabled={isLoading}>Continuar</button>
      </div>
    </div>
  )
}

export default Degrees
