import React, { useContext, useState } from 'react'
import IPlatform from '@interfaces/IPlatform'
import { Nav, Spinner, Tab } from 'react-bootstrap'
import PanelContext from '@contexts/Panel'
import Degree from './Degree/Degree'
import { Checkbox, Remove } from './Association.styles'
import TeacherContext from '@contexts/Teacher'
import Associate from './Associate/Associate'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import { getCssProperty } from '@helpers/Theme'
import * as $Discipline from '@services/Discipline'
import IDiscipline from '@interfaces/IDiscipline'
import { AiOutlineClose } from 'react-icons/ai'
import AuthContext from '@contexts/Auth'

const Association: React.FC<any> = () => {
  const { year } = useContext(AuthContext)
  const { platforms } = useContext(PanelContext)
  const { teacher, disciplines, setDisciplines, associations, setAssociations, degrees, disciplineId, setDisciplineId, isLoading } = useContext(TeacherContext)
  const [ showModal, setShowModal ] = useState(false)

  const SweetAlert = withReactContent(Swal)

  const associatedLength = associations.filter(a => a.isAssociated).length

  const isAllChecked = associations.length === associatedLength
  const isAllIndeterminate = associatedLength > 0 && associatedLength < associations.length

  const handleAssociateAll = ({ target: { checked } }: React.ChangeEvent<HTMLInputElement>, disciplineId: number) => {
    associations.filter(a => a.disciplineId === disciplineId).forEach(a => a.isAssociated = checked)
    setAssociations([...associations])
  }

  const onSelect = (key: any) => setDisciplineId(Number(key))

  const handleShowModal = () => {
    SweetAlert.fire({
      title: 'Você tem certeza?',
      text: 'Caso você crie uma nova disciplina, todas as alterações serão perdidas. Salve as alterações antes de criar uma nova disciplina.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: getCssProperty('--primary-color'),
      cancelButtonColor: getCssProperty('--secondary-color'),
      confirmButtonText: 'Sim, tenho certeza!',
      cancelButtonText: 'Não, quero voltar!'
    }).then((result) => {
      if (result.isConfirmed) {
        setShowModal(true)
      }
    })
  }

  const handleRemoveDiscipline = (discipline: IDiscipline) => {
    SweetAlert.fire({
      title: `Você tem certeza que deseja remover a disciplina ${discipline.name}?`,
      text: 'Você não poderá voltar atrás dessa decisão.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: getCssProperty('--primary-color'),
      cancelButtonColor: getCssProperty('--secondary-color'),
      confirmButtonText: 'Sim, tenho certeza!',
      cancelButtonText: 'Não, quero voltar!'
    }).then((result) => {
      if (result.isConfirmed) {
        SweetAlert.fire({
          title: 'Aguarde...',
          didOpen: () => SweetAlert.showLoading(),
        })

        $Discipline.deleteTeacherAssociation(discipline.id, teacher.id as number).then(({ data: { disciplines, associations } }: any) => {
          setDisciplines(disciplines)
          setAssociations(associations)

          if (!disciplines.some((d: IDiscipline) => d.id === disciplineId))
            setDisciplineId(disciplines[0]?.id ?? 0)

          SweetAlert.fire({
            title: 'Sucesso!',
            text: 'Disciplina desassociada com sucesso!',
            icon: 'success',
          })
        }).catch(e => SweetAlert.fire({
          title: 'Erro!',
          text: e.response?.data?.message ?? 'Erro ao desassociar a disciplina!',
          icon: 'error',
        }))
      }
    })
  }

  return (
    <>
      <Associate
        show={showModal}
        onHide={() => setShowModal(false)}
      />

      <div className="card mb-4">
        {disciplineId > 0 ? (
          <Tab.Container defaultActiveKey={disciplineId} onSelect={onSelect}>
            <div className="card-header p-0 has-tabs">
              <Nav variant="tabs">
                {disciplines.map(discipline => (
                  <Nav.Item key={`nav-${discipline.id}`}>
                    <Nav.Link eventKey={discipline.id}>
                      {discipline.name}
                      {year === new Date().getFullYear() && (
                        <Remove
                          className="btn btn-light btn-sm ms-2"
                          onClick={() => handleRemoveDiscipline(discipline)}
                        >
                          <AiOutlineClose size={10} />
                        </Remove>
                      )}
                    </Nav.Link>
                  </Nav.Item>
                ))}

                {year === new Date().getFullYear() && (
                  <Nav.Item onClick={handleShowModal}>
                    <Nav.Link>Nova disciplina</Nav.Link>
                  </Nav.Item>
                )}
              </Nav>
            </div>

            <Tab.Content>
              {disciplines.map(discipline => (
                <Tab.Pane eventKey={discipline.id} key={`tab-${discipline.id}`}>
                  <div className="card-body p-0">
                    <div className="table-responsive">
                      <table className="table table-default mb-0">
                        <thead>
                          <tr>
                            <th>
                              <div className="form-check">
                                <Checkbox
                                  className="form-check-input cursor-pointer d-none"
                                  checked={isAllChecked}
                                  indeterminate={isAllIndeterminate}
                                  onChange={(e: any) => handleAssociateAll(e, discipline.id)}
                                  disabled={year !== new Date().getFullYear()}
                                  type="checkbox"
                                />
                              </div>
                            </th>
                            {platforms.map((platform: IPlatform) => (
                              <th
                                key={`th-${platform.id}`}
                                className="text-center"
                              >{platform.name}</th>
                            ))}
                          </tr>
                        </thead>

                        <tbody>
                          {degrees.map(degree => degree.classes.length ? (
                            <Degree degree={degree} key={degree.id} />
                          ) : null)}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Tab.Pane>
              ))}
            </Tab.Content>
          </Tab.Container>
        ) : isLoading ? (
          <div className="card-body">
            <div className="d-flex justify-content-center p-3 bg-light">
              <Spinner animation="border" variant="primary" />
            </div>
          </div>
        ) : (
          <div className="card-body">
            <div className="d-flex justify-content-center p-3 bg-light">
              <button className="btn btn-primary" onClick={handleShowModal}>Nova disciplina</button>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default Association
