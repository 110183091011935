import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { Spinner, Tab, Tabs } from 'react-bootstrap'
import ReportmentContext from '@contexts/Reportment'
import NavigationContext from '@contexts/Navigation'
import { AiOutlineDownload } from 'react-icons/ai'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import * as $Reportment from '@services/Reportment'
import * as Utils from '@helpers/Utils'
import Flexmonster from './Flexmonster/Flexmonster'

const Reportment: React.FC<any> = () => {
  const { hideNavigation } = useContext(NavigationContext)
  const { reportment, isLoading } = useContext(ReportmentContext)

  const [ activeKey, setActiveKey ] = useState<string>('')

  const chartRefs = useRef<any[]>([])

  const SweetAlert = withReactContent(Swal)

  useEffect(() => {
    if (activeKey === '' && reportment && reportment.charts && reportment.charts.length > 0) {
      setActiveKey(`${reportment.charts[0].id}`)
    }
  }, [activeKey, reportment])

  const exportXLSX = () => {
    SweetAlert.fire({
      title: 'Aguarde, gerando relatório...',
      text: 'Essa atividade pode demorar alguns minutos dependendo do relatório.',
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => SweetAlert.showLoading(),
    })

    $Reportment.download(reportment.id).then(({ data }) => {
      Utils.exportCSV(data, `${reportment.name}.csv`)

      SweetAlert.fire({
        title: 'Relatório gerado com sucesso!',
        text: 'Arquivos CSV são formatados utilizando a separação por ponto-e-vírgula.',
        icon: 'success',
        didOpen: () => SweetAlert.hideLoading(),
      })
    }).catch(() => SweetAlert.fire({
      title: 'Erro!',
      text: 'Não foi possível exportar o relatório, tente novamente mais tarde.',
      icon: 'error'
    }))
  }

  const onSelect = (key: string) => {
    setActiveKey(key)

    const index = reportment.charts.findIndex(({ id }) => id.toString() === key)

    if (index !== -1)
      setTimeout(() => chartRefs.current[index].refresh(), 100)
  }

  return (
    <>
      <div className="row align-items-center">
        <div className="col-12 col-md-6 mb-3">
          <h1 className="mb-0">
            {reportment.name ?? 'Carregando...'}
          </h1>

          {!hideNavigation && (
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item"><Link to="/">Dashboard</Link></li>
              <li className="breadcrumb-item"><Link to="/relatorios">Relatórios</Link></li>
              <li className="breadcrumb-item active" aria-current="page">{reportment.name ?? 'Relatório'}</li>
            </ol>
          )}
        </div>

        <div className="col-12 col-md-6 d-flex justify-content-end mb-3">
          <Link to="/relatorios" className="btn btn-outline-primary pe-3 ps-3 me-2">Voltar</Link>
          <button className="btn btn-primary pe-3 ps-3" disabled={!reportment?.id} onClick={exportXLSX}>
            Exportar relatório <AiOutlineDownload size={18} />
          </button>
        </div>
      </div>

      {reportment?.canPreview ? (
        <Tabs activeKey={activeKey} onSelect={(key: any) => onSelect(key)}>
          {reportment.charts.map((chart: any) => (
            <Tab eventKey={chart.id} title={chart.name} key={chart.id}>
              <div className="card">
                <div className="card-body">
                  <Flexmonster
                    ref={(ref: any) => chartRefs.current.push(ref)}
                    report={{
                      dataSource: {
                        data: reportment.data,
                      },
                      slice: chart.slice,
                      options: chart.options,
                    }}
                  />
                </div>
              </div>
            </Tab>
          ))}
        </Tabs>
      ) : (
        <div className="card">
          <div className="card-body">
            {reportment?.id ? (
              <div className="bg-light text-center p-3">
                Para visualizar o relatório é necessário exportá-lo.
                <br/>
                <button className="btn btn-link" disabled={!reportment?.id} onClick={exportXLSX}>
                  Exportar relatório <AiOutlineDownload size={18} />
                </button>
              </div>
            ) : isLoading ? (
              <div className="d-flex justify-content-center p-3 bg-light">
                <Spinner animation="border" variant="primary" />
              </div>
            ) : (
              <div className="text-center">
                Os dados do relatório não foram carregados, tente novamente mais tarde.
              </div>
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default Reportment
