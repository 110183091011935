import React, { useContext, useEffect, useMemo, useState } from 'react'
import AuthContext from '@contexts/Auth'
import { List, Tooltip } from '../Migration.styles'
import IClass from '@interfaces/IClass'
import * as $School from '@services/School'
import * as $Class from '@services/Class'
import MigrationContext from '@contexts/Migration'
import { TiArrowSortedUp, TiArrowSortedDown, TiArrowUnsorted } from 'react-icons/ti'

const LastYears: React.FC<any> = () => {
  const { school, years, year } = useContext(AuthContext)
  const { migrated, selected, setSelected, lastYear, setLastYear, lastClassId, setLastClassId } = useContext(MigrationContext)

  const [ classes, setClasses ] = useState<IClass[]>([])
  const [ isLoading, setIsLoading ] = useState<boolean>(true)
  const [ students, setStudents ] = useState<any[]>([])
  const [ display, setDisplay ] = useState<string>('current')
  const [ order, setOrder ] = useState<string>('asc')
  const [ orderBy, setOrderBy ] = useState<string>('name')

  useEffect(() => {
    if (lastYear > 0) {
      $School.classes(school.id, {
        year: lastYear,
      }).then(({ data }: any) => setClasses(data)).finally(() => setIsLoading(false))
    }
  }, [school.id, lastYear])

  useEffect(() => {
    if (classes.length > 0 && lastClassId > 0) {
      setIsLoading(true)

      const classroom = classes.find(c => c.id === lastClassId)

      if (!classroom)
        return

      $Class.studentsForMigration(classroom.degreeId, lastClassId, {
        lastYear,
        currentYear: year,
      }).then(({ data }: any) => setStudents(data)).finally(() => setIsLoading(false))
    }
  }, [lastClassId, classes, lastYear, year])

  const handleSelectStudent = ({ target: { checked } }: React.ChangeEvent<HTMLInputElement>, student: any) => {
    if (checked && !selected.some((s: any) => s.id === student.id)) {
      setSelected([...selected, student])
    } else if (!checked && selected.some((s: any) => s.id === student.id)) {
      setSelected(selected.filter(s => s.id !== student.id))
    }
  }

  const data = useMemo(() => {
    return (display === 'all' ? students : students.filter(student => !migrated.some((s: any) => s.id === student.id) && student.migrated === 0)).sort((a: any, b: any) => {
      if (order === 'asc') {
        return a[orderBy].toString().localeCompare(b[orderBy].toString())
      } else {
        return b[orderBy].toString().localeCompare(a[orderBy].toString())
      }
    })
  }, [display, migrated, order, orderBy, students])

  const handleOrderBy = (accessor: string) => {
    setOrderBy(accessor)
    setOrder(order === 'asc' ? 'desc' : 'asc')
  }

  const handleSelectAll = ({ target: { checked } }: React.ChangeEvent<HTMLInputElement>) => {
    if (checked) {
      setSelected([...students.filter(s => {
        return !migrated.some((m: any) => m.id === s.id) && s.migrated !== 1
      })])
    } else {
      setSelected([])
    }
  }

  return (
    <>
      <table className="table table-default table-borderless table-sm mb-0">
        <tbody>
          <tr>
            <td valign="middle">
              <label htmlFor="year">Ano</label>
            </td>
            <td>
              <select id="year" className="form-control" onChange={e => setLastYear(parseInt(e.target.value))}>
                {years.filter(y => y !== year).sort((a, b) => a - b).map((year: any) => (
                  <option value={year} key={`year-${year}`}>{year}</option>
                ))}
              </select>
            </td>
          </tr>

          <tr>
            <td valign="middle">
              <label htmlFor="lastClassId">Série - Turma</label>
            </td>
            <td>
              <select
                id="lastClassId"
                className="form-control"
                onChange={e => setLastClassId(parseInt(e.target.value))}
                disabled={isLoading}
              >
                <option value={0}>Selecione</option>
                {classes.map((classroom: IClass) => {
                  const length = Number(classroom.amountStudents) - migrated.filter(s => s.lastClassId === classroom.id).length

                  return (
                    <option value={classroom.id} key={`class-${classroom.id}`}>
                      {classroom.degreeShortname} - {classroom.name}&nbsp;
                      ({length} aluno{length !== 1 ? 's' : ''} não migrado{length !== 1 ? 's' : ''})
                    </option>
                  )
                })}
              </select>
            </td>
          </tr>

          <tr>
            <td valign="middle">
              <label htmlFor="all-1">Exibir</label>
            </td>
            <td>
              <div className="d-flex">
                <div className="form-check me-3">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="last__display"
                    id="current-1"
                    value="current"
                    onChange={({ target: { value } }) => setDisplay(value)}
                    defaultChecked
                  />
                  <label className="form-check-label" htmlFor="current-1">Apenas não migrados</label>
                </div>

                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="last__display"
                    id="all-1"
                    value="all"
                    onChange={({ target: { value } }) => setDisplay(value)}
                  />
                  <label className="form-check-label" htmlFor="all-1">Todos</label>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <List>
        <table className="table table-bordered table-default mb-0">
          <thead className="table-light">
            <tr>
              <th style={{ width: 60 }} scope="col">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="check-all"
                    disabled={!students?.length}
                    onChange={handleSelectAll}
                    checked={students?.length > 0 && selected.length === students.filter(s => s.migrated === 0).length}
                  />
                </div>
              </th>
              <th scope="col">
                <button className="btn btn-link p-0" onClick={() => handleOrderBy('identifier')}>
                  {orderBy === 'identifier' ? order === 'asc' ? (
                    <TiArrowSortedUp size={14} />
                  ) : (
                    <TiArrowSortedDown size={14} />
                  ) : (
                    <TiArrowUnsorted size={14} />
                  )} RA
                </button>
              </th>
              <th scope="col">
                <button className="btn btn-link p-0" onClick={() => handleOrderBy('usercode')}>
                  {orderBy === 'usercode' ? order === 'asc' ? (
                    <TiArrowSortedUp size={14} />
                  ) : (
                    <TiArrowSortedDown size={14} />
                  ) : (
                    <TiArrowUnsorted size={14} />
                  )} RE
                </button>
              </th>
              <th scope="col">
                <button className="btn btn-link p-0" onClick={() => handleOrderBy('name')}>
                  {orderBy === 'name' ? order === 'asc' ? (
                    <TiArrowSortedUp size={14} />
                  ) : (
                    <TiArrowSortedDown size={14} />
                  ) : (
                    <TiArrowUnsorted size={14} />
                  )} Nome
                </button>
              </th>
            </tr>
          </thead>

          <tbody>
            {data.map((student: any) => {
              const isMigrated = student.migrated == 1
              const inMigration = migrated.some((s: any) => s.id === student.id)
              const isSelected = selected.some((s: any) => s.id === student.id)

              return (
                <Tooltip className={isMigrated ? 'disabled' : inMigration ? 'selected' : ''} key={`student-${student.id}`}>
                  <td>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        onChange={e => handleSelectStudent(e, student)}
                        checked={isSelected || isMigrated || inMigration}
                        disabled={isMigrated || inMigration}
                      />
                    </div>
                  </td>
                  <td>{student.identifier}</td>
                  <td>{student.usercode}</td>
                  <td className="tooltip-container">
                    {student.name}
                    {student.tooltip?.length > 0 ? (
                      <div className="tooltip" dangerouslySetInnerHTML={{ __html: student.tooltip }} />
                    ) : inMigration ? (
                      <div className="tooltip">Em processo de migração</div>
                    ) : null}
                  </td>
                </Tooltip>
              )
            })}
          </tbody>
        </table>

        {data.length === 0 && (
          <div className="text-center text-muted py-5">
            {isLoading ? 'Carregando...' : (lastClassId > 0 ? 'Nenhum aluno encontrado' : 'Selecione uma turma para exibir os alunos')}
          </div>
        )}
      </List>
    </>
  )
}

export default LastYears
