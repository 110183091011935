import React, { useContext } from 'react'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import * as $Platform from '@services/Platform'
import IPlatform from '@interfaces/IPlatform'
import { Dropdown } from 'react-bootstrap'
import StudentsAndProductsContext from '@contexts/StudentsAndProducts'
import PanelContext from '@contexts/Panel'
import AssociationError from '@components/AssociationError/AssociationError'

const Disassociation: React.FC<any> = ({ disabled }: any) => {
  const { platforms } = useContext(PanelContext)
  const { selectedAssociation, isLoading, setIsLoading, refresh, year } = useContext(StudentsAndProductsContext)

  const SweetAlert = withReactContent(Swal)

  const disassociate = (platform: IPlatform) => {
    setIsLoading(true)

    SweetAlert.showLoading()

    $Platform.disassociate(platform.id, selectedAssociation, year).then(() => {
      SweetAlert.fire({
        icon: 'success',
        title: 'Alunos desassociados com sucesso',
        text: `${selectedAssociation.students.length} alunos foram desassociados da plataforma ${platform.name}`,
      }).then(() => refresh())
    }).catch(e => SweetAlert.fire({
      icon: 'error',
      title: 'Erro ao desassociar alunos',
      html: <AssociationError data={e?.response?.data?.errors} />,
    })).finally(() => {
      SweetAlert.hideLoading()
      setIsLoading(false)
    })
  }

  return (
    <Dropdown>
      <Dropdown.Toggle variant="outline-danger" disabled={isLoading || !selectedAssociation?.students?.length || disabled}>Desassociar em Massa</Dropdown.Toggle>
      <Dropdown.Menu>
        {platforms.filter(platform => !platform.isCurrentPlatform).map((platform: any) => (
          <Dropdown.Item key={platform.id} onClick={() => disassociate(platform)}>Desassociar: {platform.name}</Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default Disassociation
