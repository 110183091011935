import React, { useCallback, useEffect, useState } from 'react'
import IAvailableProduct from '@interfaces/IAvailableProduct'
import * as $Product from '@services/Product'
import { Nav, Spinner, Tab } from 'react-bootstrap'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'

interface IOldAccessAmount {
  productId: number
  accessAmount: number
}

const AvailableProducts: React.FC<any> = () => {
  const [ availableProducts, setAvailableProducts ] = useState<IAvailableProduct[]>([])
  const [ platforms, setPlatforms ] = useState<string[]>([])
  const [ isLoading, setIsLoading ] = useState<boolean>(true)
  const [ isSaving, setIsSaving ] = useState<boolean>(false)
  const [ oldAccessAmounts, setOldAccessAmounts ] = useState<IOldAccessAmount[]>([])

  const SweetAlert = withReactContent(Swal)

  useEffect(() => {
    $Product.available().then(({ data: availableProducts }: any) => {
      if (availableProducts.length) {
        const platforms = availableProducts.reduce((acc: any, curr: any) => {
          if (!acc[curr.platformName]) acc[curr.platformName] = []
          acc[curr.platformName].push(curr)
          return acc
        }, {})

        setPlatforms(Object.keys(platforms))
        setAvailableProducts(availableProducts)
      }
    }).finally(() => setIsLoading(false))
  }, [])

  const setOldAccessAmount = useCallback((item: IAvailableProduct) => {
    const index = oldAccessAmounts.findIndex(({ productId }: IOldAccessAmount) => productId === item.productId)

    if (index === -1) {
      oldAccessAmounts.push({
        productId: item.productId,
        accessAmount: item.accessAmount,
      })
    } else {
      oldAccessAmounts[index].accessAmount = item.accessAmount
    }

    setOldAccessAmounts([ ...oldAccessAmounts ])
  }, [ oldAccessAmounts ])

  const handleSetLimit = useCallback((item: IAvailableProduct, hasLimit: boolean) => {
    const index = availableProducts.findIndex((availableProduct: IAvailableProduct) => {
      return availableProduct.productId === item.productId && availableProduct.platformId === item.platformId
    })

    if (index !== -1) {
      if (hasLimit) {
        const oldAccessAmountIndex = oldAccessAmounts.findIndex((oldAccessAmount: IOldAccessAmount) => {
          return oldAccessAmount.productId === item.productId
        })

        availableProducts[index].accessAmount = oldAccessAmounts[oldAccessAmountIndex]?.accessAmount ?? 0
      } else {
        if (item.accessAmount > -1) {
          setOldAccessAmount(item)
        }

        availableProducts[index].accessAmount = -1
      }

      setAvailableProducts([ ...availableProducts ])
    }
  }, [availableProducts, oldAccessAmounts, setOldAccessAmount])

  const handleInputChange = useCallback((e: any, item: IAvailableProduct) => {
    const { value } = e.target

    const index = availableProducts.findIndex((availableProduct: IAvailableProduct) => {
      return availableProduct.productId === item.productId && availableProduct.platformId === item.platformId
    })

    if (index !== -1) {
      availableProducts[index].accessAmount = parseInt(value)
      setAvailableProducts([ ...availableProducts ])
    }
  }, [availableProducts])

  const save = useCallback(() => {
    setIsSaving(true)

    SweetAlert.fire({
      title: 'Salvando...',
      text: 'Aguarde enquanto os dados são salvos.',
      icon: 'info',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => SweetAlert.showLoading(),
    })

    $Product.updateAvailable(availableProducts).then(() => {
      SweetAlert.fire({
        title: 'Sucesso!',
        text: 'Produtos disponíveis atualizados com sucesso!',
        icon: 'success',
        showConfirmButton: true,
        didOpen: () => SweetAlert.hideLoading(),
      })
    }).catch(() => {
      SweetAlert.fire({
        title: 'Erro!',
        text: 'Erro ao atualizar produtos disponíveis!',
        icon: 'error',
        showConfirmButton: true,
        didOpen: () => SweetAlert.hideLoading(),
      })
    }).finally(() => setIsSaving(false))
  }, [SweetAlert, availableProducts])

  return (
    <div className="card">
      {platforms?.length ? (
        <Tab.Container defaultActiveKey={platforms[0]}>
          <div className="card-header d-flex justify-content-between align-items-center p-0 pe-4 has-tabs">
            <Nav variant="tabs">
              {platforms.map((platform: string) => (
                <Nav.Item key={`nav-${platform}`}>
                  <Nav.Link eventKey={platform}>{platform}</Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </div>

          <Tab.Content>
            {platforms.map((platform: any) => (
              <Tab.Pane eventKey={platform} key={`tab-${platform}`}>
                <div className="card-body p-0">
                  {availableProducts?.filter(item => item.platformName === platform)?.length > 0 ? (
                    <div className="table-responsive">
                      <table className="table table-hover table-striped mb-0">
                        <thead>
                          <tr>
                            <th>Produto</th>
                            <th>Limitar acessos</th>
                            <th>Limite de acessos</th>
                          </tr>
                        </thead>

                        <tbody>
                          {availableProducts.filter(item => item.platformName === platform).map((item: IAvailableProduct) => (
                            <tr key={item.productId}>
                              <td style={{ verticalAlign: 'middle' }}>{item.productName}</td>

                              <td style={{ width: 150, verticalAlign: 'middle' }}>
                                <div className="btn-group">
                                  <button
                                    type="button"
                                    className={'btn btn-sm pe-3 ps-3 btn-' + (item.accessAmount > -1 ? 'primary' : 'light')}
                                    onClick={() => handleSetLimit(item, true)}
                                  >Sim</button>
                                  <button
                                    type="button"
                                    className={'btn btn-sm pe-3 ps-3 btn-' + (item.accessAmount > -1 ? 'light' : 'primary')}
                                    onClick={() => handleSetLimit(item, false)}
                                  >Não</button>
                                </div>
                              </td>

                              <td style={{ width: 150, verticalAlign: 'middle' }}>
                                {item.accessAmount > -1 && (
                                  <input
                                    type="number"
                                    className="form-control text-center"
                                    defaultValue={item.accessAmount > 0 ? item.accessAmount : 0}
                                    onChange={e => handleInputChange(e, item)}
                                    min={0}
                                  />
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div className="alert bg-light text-center">Não há produtos disponíveis nesta plataforma.</div>
                  )}
                </div>
              </Tab.Pane>
            ))}
          </Tab.Content>

          <div className="card-footer d-flex justify-content-end">
            <button className="btn btn-primary" onClick={save} disabled={isSaving}>Salvar alterações</button>
          </div>
        </Tab.Container>
      ) : (
        <div className="card-body">
          {isLoading ? (
            <div className="d-flex justify-content-center p-3 bg-light mb-0">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : (
            <div className="alert bg-light text-center mb-0">Nenhum resultado foi encontrado.</div>
          )}
        </div>
      )}
    </div>
  )
}

export default AvailableProducts
