import React, { createContext, useContext, useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import * as $Class from '@services/Class'
import IClass from '@interfaces/IClass'
import * as $Degree from '@services/Degree'
import IDegree from '@interfaces/IDegree'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import { getCssProperty } from '@helpers/Theme'
import axios from 'axios'
import AuthContext from './Auth'

type ClassesContextProps = {
  selected: number[]
  setSelected: React.Dispatch<React.SetStateAction<number[]>>
  classes: IClass[]
  setClasses: React.Dispatch<React.SetStateAction<IClass[]>>
  isLoading: boolean
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  degrees: IDegree[]
  handleDelete(classId: number, degreeId: number): void
  showClassCreate: boolean
  setShowClassCreate: React.Dispatch<React.SetStateAction<boolean>>
  getClasses: () => void
}

const ClassesContext = createContext<ClassesContextProps>({ } as ClassesContextProps)

export const ClassesProvider: React.FC<any> = () => {
  const { year } = useContext(AuthContext)

  const [ selected, setSelected ] = useState<number[]>([])
  const [ classes, setClasses ] = useState<IClass[]>([] as IClass[])
  const [ isLoading, setIsLoading ] = useState(true)
  const [ degrees, setDegrees ] = useState<IDegree[]>([] as IDegree[])
  const [ showClassCreate, setShowClassCreate ] = useState(false)

  const SweetAlert = withReactContent(Swal)

  const getClasses = () => $Class.all(0, {
    year,
  }).then(({ data }) => setClasses(data))

  useEffect(() => {
    setIsLoading(true)
    axios.all([
      $Degree.all(),
      $Class.all(0, {
        year,
      }),
    ]).then(axios.spread(({ data: degrees }: any, { data: classes }: any) => {
      setDegrees(degrees)
      setClasses(classes)
    })).finally(() => setIsLoading(false))
  }, [year])

  const handleDelete = (classId: number, degreeId: number) => {
    SweetAlert.fire({
      title: 'Deseja prosseguir?',
      text: 'Deseja prosseguir com a exclusão dessa turma? Você não poderá voltar atrás dessa decisão.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: getCssProperty('--secondary-color'),
      cancelButtonColor: getCssProperty('--primary-color'),
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    }).then(result => {
      if (result.isConfirmed) {
        SweetAlert.fire({
          title: 'Aguarde...',
          didOpen: () => SweetAlert.showLoading(),
        })

        $Class.destroy(degreeId, classId).then(() => {
          setClasses(classes.filter((classroom: IClass) => classroom.id != classId))

          SweetAlert.fire({
            title: 'Sucesso!',
            text: 'Turma excluída com sucesso!',
            icon: 'success',
            confirmButtonColor: getCssProperty('--primary-color'),
          })
        }).catch(e => SweetAlert.fire({
          title: 'Erro!',
          text: e.response?.data?.message ?? 'Ocorreu um erro ao excluir turma. Tente novamente mais tarde.',
          icon: 'error',
          confirmButtonColor: getCssProperty('--primary-color'),
        }))
      }
    })
  }

  return (
    <ClassesContext.Provider
      value={{
        selected,
        setSelected,
        classes,
        setClasses,
        isLoading,
        setIsLoading,
        degrees,
        handleDelete,
        showClassCreate,
        setShowClassCreate,
        getClasses,
      }}
    >
      <Outlet />
    </ClassesContext.Provider>
  )
}

export default ClassesContext
