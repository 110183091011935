import axios from 'axios'
import { ITeacherDiscipline } from '@screens/TeachersAndProducts/TeachersAndProducts'

export const all = (params: any = {}): Promise<any> => {
  return axios.get('teachers', {
    params,
  })
}

export const find = (id: number): Promise<any> => {
  return axios.get('teachers/' + id)
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const update = (id: number, year: number, teacher: any): Promise<any> => {
  return axios.put(`teachers/${id}?year=${year}`, teacher)
}

export const disciplines = (params: any = {}): Promise<any> => {
  return axios.get('teachers/disciplines', {
    params,
  })
}

export const massRegistration = (platformIds: number[], data: ITeacherDiscipline[]): Promise<any> => {
  return axios.post('teachers/mass-registration', {
    platformIds,
    data,
  })
}

export const updateDisciplines = (data: any[]) : Promise<any> => {
  return axios.put('teachers/update-disciplines', data)
}

export const deleteDisciplines = (data: any[]) : Promise<any> => axios.delete('teachers/delete-disciplines', {
  data,
})

export const associateDiscipline = (teacherId: number, year: number, data: any = {}) : Promise<any> => {
  return axios.post(`teachers/${teacherId}/associate-discipline?year=${year}`, data)
}

export const associations = (teacherId: number, year: number): Promise<any> => axios.get(`teachers/${teacherId}/associations`, {
  params: {
    year,
  },
})
