import React, { createContext, useEffect, useState, useContext } from 'react'
import { Outlet } from 'react-router-dom'
import * as $Teacher from '@services/Teacher'
import ITeacher from '@interfaces/ITeacher'
import AuthContext from './Auth'

type TeachersContextProps = {
  selected: number[]
  setSelected: React.Dispatch<React.SetStateAction<number[]>>
  teachers: ITeacher[]
  setTeachers: React.Dispatch<React.SetStateAction<ITeacher[]>>
  isLoading: boolean
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
}

const TeachersContext = createContext<TeachersContextProps>({ } as TeachersContextProps)

export const TeachersProvider: React.FC<any> = () => {
  const { year } = useContext(AuthContext)

  const [ selected, setSelected ] = useState<number[]>([])
  const [ teachers, setTeachers ] = useState<ITeacher[]>([] as ITeacher[])
  const [ isLoading, setIsLoading ] = useState(true)

  useEffect(() => {
    $Teacher.all({
      year,
    }).then(({ data: teachers }: any) => setTeachers(teachers)).finally(() => setIsLoading(false))
  }, [year])

  return (
    <TeachersContext.Provider
      value={{
        selected,
        setSelected,
        teachers,
        setTeachers,
        isLoading,
        setIsLoading,
      }}
    >
      <Outlet />
    </TeachersContext.Provider>
  )
}

export default TeachersContext
