import React, { useState } from 'react'
import Button from '@components/Button/Button'

const Delete: React.FC<any> = () => {
  const [ isLoading ] = useState<boolean>(false)

  return (
    <Button
      className="btn btn-sm btn-danger text-white pe-3 ps-3"
      disabled={isLoading}
      isLoading={isLoading}
    >Descartar</Button>
  )
}

export default Delete
