import IRegistrationEntity from '@interfaces/IRegistrationEntity'
import IUser from '@interfaces/IUser'
import axios from 'axios'

export const all = (year?: number): Promise<any> => {
  return axios.get('students', {
    params: {
      year,
    },
  })
}

export const find = (studentId: number): Promise<any> => {
  return axios.get('students/' + studentId)
}

export const associate = (studentId: number, platformId: number, params: any = {}, year: number): Promise<any> => {
  return axios.put(`students/${studentId}/platform/${platformId}/associations/${year}`, params)
}

export const associations = (studentId: number, platformId: number, params: any = {}): Promise<any> => {
  return axios.get(`students/${studentId}/platform/${platformId}/associations`, {
    params,
  })
}

export const degreeGroups = (studentId: number, platformId: number, params: any = {}): Promise<any> => {
  return axios.get(`students/${studentId}/platform/${platformId}/degree-groups`, {
    params,
  })
}

export const create: any = (students: IRegistrationEntity[]) => {
  return axios.post('students', students)
}

export const update = (studentId: number, student: IUser): Promise<any> => {
  return axios.put('students/' + studentId, student)
}

export const destroy = (studentId: number, year: number): Promise<any> => {
  return axios.delete('students/' + studentId, {
    params: {
      year,
    },
  })
}

export const destroyAll = (year: number): Promise<any> => {
  return axios.delete('students/deleteAll', {
    params: {
      year,
    },
  })
}
