import React, { createContext, useEffect, useState } from 'react'
import IReportment from '@interfaces/IReportment'
import * as $Reportment from '@services/Reportment'
import { Outlet, useParams } from 'react-router-dom'

type ReportmentContextProps = {
  reportment: IReportment
  setReportment: React.Dispatch<React.SetStateAction<IReportment>>
  isLoading: boolean
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
}

const ReportmentContext = createContext<ReportmentContextProps>({ } as ReportmentContextProps)

export const ReportmentProvider: React.FC = () => {
  const [ reportment, setReportment ] = useState<IReportment>({} as IReportment)
  const [ isLoading, setIsLoading ] = useState<boolean>(true)

  const { id: reportmentId }: any = useParams()

  useEffect(() => {
    $Reportment.find(reportmentId).then(({ data: reportment }: any) => setReportment(reportment)).catch(() => setIsLoading(false))
  }, [reportmentId])

  return (
    <ReportmentContext.Provider
      value={{
        reportment,
        setReportment,
        isLoading,
        setIsLoading,
      }}
    >
      <Outlet />
    </ReportmentContext.Provider>
  )
}

export default ReportmentContext
