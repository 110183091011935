import { getCssProperty } from '@helpers/Theme'
import React, { useContext } from 'react'
import { useAccordionButton, AccordionContext } from 'react-bootstrap'
import { FiChevronUp, FiChevronDown } from 'react-icons/fi'
import { Button } from './AccordionToggle.styles'

const AccordionToggle: React.FC<any> = ({ eventKey }: any) => {
  const { activeEventKey } = useContext(AccordionContext)

  const decoratedOnClick = useAccordionButton(eventKey)

  return (
    <Button type="button" onClick={decoratedOnClick}>
      {activeEventKey === eventKey ? (
        <FiChevronUp size={22} color={getCssProperty('--secondary-color')} />
      ) : (
        <FiChevronDown size={22} color={getCssProperty('--secondary-color')} />
      )}
    </Button>
  )
}

export default AccordionToggle
