import styled from 'styled-components'

export const Container = styled.div`

`

export const Tooltip = styled.tr`
  cursor: default;

  .tooltip-container {
    position: relative;

    .tooltip {
      background-color: #fff;
      border-radius: 5px;
      text-align: left;
      padding: 6px 12px;
      transform: translateX(-50%);
      transition: all 0.3s ease;
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
      position: absolute;
      visibility: hidden;
      z-index: 999;
      opacity: 0;
      color: #444;
      left: 10%;
      top: calc(100% + 8px);

      &::before {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        left: 50%;
        transform: translateX(50%) rotate(135deg);
        border: 7px solid #fff;
        border-color: transparent transparent #fff #fff;
        transform-origin: 0 0;
        box-shadow: -3px 3px 3px 0 rgba(0, 0, 0, 0.1);
        top: 0;
      }
    }
  }

  &:hover .tooltip-container .tooltip {
    visibility: visible;
    opacity: 1;
  }
`

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  gap: 20px;

  > .btn {
    z-index: 1;
    position: relative;
    background-color: #fff !important;

    &:disabled {
      border-color: #eee;
      opacity: 1;
    }
  }

  &::before {
    content: '';
    display: block;
    width: 1px;
    height: 100%;
    background-color: #e0e0e0;
    position: absolute;
    left: 50%;
    top: 0;
  }
`

export const List = styled.div`
  height: 440px;
  overflow-y: scroll;
  overflow-x: hidden;
  resize: vertical;
  scrollbar-width: auto;
  scrollbar-color: var(--primary-color) #d1d1d1;
  border: 1px solid #eee;
  border-right: 0;

  > .table {
    border: none;

    > thead {
      position: sticky;
      z-index: 2;
      top: 0;

      > tr {
        border-width: 0;

        > th {
          font-weight: 500;
          background-color: #f2f2f2 !important;
          border-bottom-width: 1px;

          > .btn {
            padding: 0;
            outline: none;
            box-shadow: none;
          }
        }
      }
    }

    > tbody {
      > tr {
        &.disabled {
          background-color: #f7f7f7;
        }

        &.selected {
          background-color: rgba(var(--primary-color-rgb), 0.1);
          color: var(--primary-color);
        }

        &.active {
          background-color: rgba(var(--primary-color-rgb), 0.1);
          color: var(--primary-color);
        }
      }
    }

    thead > tr > th,
    tbody > tr > td {
      padding: 6px 10px;
      vertical-align: middle;

      &:first-child {
        border-left-width: 0;
      }
    }
  }

  > .table-bordered > :not(caption) > * {
    border-color: #eee;
  }

  > .table-bordered > :not(caption) > * > * {
    border-left-width: 0;
  }

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #d1d1d1;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--primary-color);
    border-radius: 0px;
    border: 0px solid #ffffff;
  }
`
