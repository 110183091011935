import React, { useEffect, useRef, useCallback, useContext, useState } from 'react'
import { BsChevronDown, BsChevronUp } from 'react-icons/bs'
import { Container, DropDownList, Placeholder, Trigger } from './SelectGroup.styles'
import ISelectGroupTag from '@interfaces/ISelectGroupTag'
import SelectGroupContext from '@contexts/SelectGroup'
import { IoMdCloseCircle } from 'react-icons/io'
import ISelectGroupValueItem from '@interfaces/ISelectGroupValueItem'
import { FiChevronDown, FiChevronRight } from 'react-icons/fi'

type DropDownItemProps = {
  item: ISelectGroupTag
}

const DropDownChild: React.FC<any> = ({ item }: DropDownItemProps) => {
  const { value, setValue } = useContext(SelectGroupContext)

  const checked = value.some(i => i.id === item.id)

  const onChange = () => {
    if (checked) {
      setValue(value => value.filter(i => i.id !== item.id))
    } else {
      setValue(value => [...value, {
        id: item.id,
        name: item.name,
      }])
    }
  }

  return (
    <div className="dropdown-item">
      <div className="form-check">
        <label className="form-check-label">
          <input
            type="checkbox"
            className="form-check-input"
            value={item.id}
            checked={checked}
            onChange={onChange}
          /> {item.name}
        </label>
      </div>
    </div>
  )
}

const DropDownParent: React.FC<any> = ({ item }: DropDownItemProps) => {
  const { value, setValue } = useContext(SelectGroupContext)

  const [ isOpen, setIsOpen ] = useState(false)

  const checked = item.children?.every(child => value.some(i => i.id === child.id)) || false

  const onChange = () => {
    if (checked) {
      setValue(value => value.filter(i => !item.children?.some(child => child.id === i.id)))
    } else {
      item.children?.forEach(child => {
        if (!value.some(i => i.id === child.id)) {
          setValue(value => [...value, {
            id: child.id,
            name: child.name,
          }])
        }
      })
    }
  }

  return (
    <div className="dropdown-item">
      <div className="dropdown-item-label">
        <button className="toggle" onClick={() => setIsOpen(!isOpen)}>
          {isOpen ? (
            <FiChevronRight size={16} />
          ) : (
            <FiChevronDown size={16} />
          )}
        </button>

        <div className="form-check">
          <label className="form-check-label">
            <input
              type="checkbox"
              className="form-check-input"
              value={item.id}
              checked={checked}
              onChange={onChange}
            /> {item.name}
          </label>
        </div>
      </div>

      {isOpen && item.children && item.children.length > 0 && (
        <div className="children">
          {item.children.map(child => <DropDownChild item={child} key={`${item.id}-${child.id}`} />)}
        </div>
      )}
    </div>
  )
}

type Props = {
  data: ISelectGroupTag[]
  placeholder?: string
}

const SelectGroup: React.FC<any> = ({ data, placeholder }: Props) => {
  const { value, setValue } = useContext(SelectGroupContext)

  const [ isOpen, setIsOpen ] = React.useState<boolean>(false)

  const containerRef = useRef<HTMLDivElement>(null)

  const handleClickOutside = useCallback((event: any) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setIsOpen(false)
    }
  }, [])

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [containerRef, handleClickOutside])

  const onDelete = (item: ISelectGroupValueItem) => {
    setValue(value => value.filter(i => i.id !== item.id))
  }

  return (
    <Container ref={containerRef}>
      <div className="field">
        <div className="field-container">
          {value.length > 0 ? (
            <div className="tags">
              {value.map(item => (
                <div className="tag" key={item.id}>
                  <span>{item.name}</span>
                  <button className="delete is-small" onClick={() => onDelete(item)}>
                    <IoMdCloseCircle size={16} />
                  </button>
                </div>
              ))}
            </div>
          ) : placeholder && placeholder.length > 0 && (
            <Placeholder>{placeholder}</Placeholder>
          )}
        </div>

        <div className="control">
          <Trigger className="dropdown-trigger" aria-haspopup="true" aria-controls="dropdown-list" onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? (
              <BsChevronUp size={24} />
            ) : (
              <BsChevronDown size={24} />
            )}
          </Trigger>
        </div>
      </div>

      {isOpen && (
        <DropDownList role="menu">
          {data.length > 0 && data.map((item: ISelectGroupTag) => (
            <DropDownParent item={item} key={item.id} />
          ))}
        </DropDownList>
      )}
    </Container>
  )
}

export default SelectGroup
