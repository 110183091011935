import React, { useContext, useEffect, useMemo, useState } from 'react'
import IUser from '@interfaces/IUser'
import * as $Manager from '@services/Manager'
import * as Utils from '@helpers/Utils'
import AuthContext from '@contexts/Auth'
import { Link, useNavigate } from 'react-router-dom'
import Pagination from '@components/Pagination/Pagination'
import { usePagination, useSortBy, useTable } from 'react-table'
import columns from './../../data/ReactTableAcademicColumns'
import { Spinner } from 'react-bootstrap'
import { ReactComponent as Sort } from '@resources/svg/sort.svg'
import Password from '@components/Password/Password'
import Delete from './Delete/Delete'
import Permission from '@enums/Permission.enum'
import { Buttons } from './Managers.styles'
import { PasswordProvider } from '@contexts/Password'

const Managers: React.FC<any> = () => {
  const { permissions } = useContext(AuthContext)

  const [ managers, setManagers ] = useState<IUser[]>([] as IUser[])
  const [ isLoading, setIsLoading ] = useState(true)
  const [ search, setSearch ] = useState<string>('')

  const navigate = useNavigate()

  useEffect(() => {
    $Manager.all().then(({ data }) => setManagers(data)).finally(() => setIsLoading(false))
  }, [])

  const data = useMemo(() => {
    return managers.filter((manager: IUser) => {
      let exists = true

      if (search.length > 0) {
        exists = manager.name.toLowerCase().includes(search.toLowerCase()) ||
          manager.nickname.toLowerCase().includes(search.toLowerCase()) ||
          manager.email === search
      }

      return exists
    })
  }, [managers, search])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: {
      pageIndex,
    },
  }: any = useTable({
    columns,
    data,
    initialState: {
      pageIndex: 0,
      pageSize: 10,
    } as any,
  }, useSortBy, usePagination)

  const exportManagers = () => {
    const data = managers.map((manager: IUser) => [
      manager.name,
      manager.nickname,
      manager.email,
      manager.password && manager.password?.length > 20 ? 'Senha alterada' : manager.password,
    ])

    data.unshift([
      'Nome', 'Apelido', 'Email', 'Senha',
    ])

    Utils.exportXLSX(data as string[][], 'Gestores', 'Gestores.xlsx')
  }

  const handleOnDelete = (managerId: number) => {
    setManagers(managers => managers.filter(manager => manager.id !== managerId))
  }

  return (
    <>
      <div className="row align-items-end">
        <div className="col-12 col-md-4 mb-3">
          <div className="form-group">
            <label htmlFor="search">Pesquisar:</label>
            <input type="text" id="search" name="search" className="form-control" placeholder="Pesquise pelo nome ou e-mail" onChange={e => setSearch(e.target.value)} />
          </div>
        </div>

        <Buttons className="col-12 col-md-8 d-flex justify-content-end align-items-end mb-3">
          {permissions.includes(Permission.EDIT_MANAGERS) && (
            <button className="btn btn-outline-primary" onClick={exportManagers}>Exportar</button>
          )}

          {permissions.includes(Permission.CREATE_MANAGERS) && (
            <button className="btn btn-primary ms-2" onClick={() => navigate('/gestores/cadastrar')}>Cadastrar</button>
          )}
        </Buttons>
      </div>

      <div className="card">
        <div className="card-header">Gestores ({managers.length})</div>

        <div className="card-body p-0">
          {!isLoading ? (managers.length ? (
            <div className="table-responsive">
              <table {...getTableProps()} className="table table-default mb-0">
                <thead>
                  {headerGroups.map((headerGroup: any, headerGroupIndex: number) => (
                    <tr {...headerGroup.getHeaderGroupProps()} key={headerGroupIndex}>
                      {headerGroup.headers.map((column: any, columnIndex: number) => (
                        <th {...column.getHeaderProps(column.getSortByToggleProps())} key={columnIndex}>
                          {column.render('Header')}
                          <Sort className={'sort-icon ms-1 ' + (column.isSorted ? column.isSortedDesc ? 'sorted-up' : 'sorted-down' : 'unsorted')} />
                        </th>
                      ))}
                      {permissions.includes(Permission.CREATE_MANAGERS) && (
                        <>
                          <th style={{ width: 120 }}>Senha</th>
                          <th style={{ width: 120 }}>Ações</th>
                        </>
                      )}
                    </tr>
                  ))}
                </thead>

                <tbody {...getTableBodyProps()}>
                  <PasswordProvider>
                    {page.map((row: any, rowIndex: number) => {
                      prepareRow(row)
                      return (
                        <tr {...row.getRowProps()} key={rowIndex}>
                          {row.cells.map((cell: any, index: number) => {
                            return (
                              <td style={{ verticalAlign: 'middle' }} {...cell.getCellProps()} key={index}>
                                {cell.render('Cell')}
                              </td>
                            )
                          })}
                          {permissions.includes(Permission.CREATE_MANAGERS) && (
                            <>
                              <td style={{ verticalAlign: 'middle' }}>
                                <Password userId={row.original.id} passwordLength={row.original.passwordLength} disabled={!row.original?.email?.length} />
                              </td>

                              <td style={{ verticalAlign: 'middle' }}>
                                <div className="d-flex justify-content-end">
                                  <Link to={`/gestores/${row.original.id}/editar`} className="btn btn-sm ps-4 pe-4 btn-primary">Editar</Link>
                                  <Delete manager={row.original} onDelete={handleOnDelete} />
                                </div>
                              </td>
                            </>
                          )}
                        </tr>
                      )
                    })}
                  </PasswordProvider>
                </tbody>
              </table>
            </div>
          ) : (
            <div className="bg-light text-center p-3">
              Nenhum gestor cadastrado.
            </div>
          )) : (
            <div className="d-flex justify-content-center p-3 bg-light">
              <Spinner animation="border" variant="primary" />
            </div>
          )}
        </div>

        {pageCount > 1 && (
          <div className="card-footer d-flex justify-content-end">
            <Pagination
              page={pageIndex}
              pageCount={pageCount}
              canPreviousPage={canPreviousPage}
              canNextPage={canNextPage}
              nextPage={nextPage}
              previousPage={previousPage}
              onClick={(page: number) => gotoPage(page)}
            />
          </div>
        )}
      </div>
    </>
  )
}

export default Managers
