import styled from 'styled-components'
import { ToastContainer as ToastContainerComponent } from 'react-bootstrap'

export const ToastContainer = styled(ToastContainerComponent)`
  position: fixed !important;
  bottom: 20px !important;
  right: 20px !important;

  > .toast {
    > .toast-body {
      background-color: #fff;

      &:not(:first-child) {
        border-top: 1px solid #e5e5e5;
      }
    }
  }
`
