import IDegree from '@interfaces/IDegree'
import IPlatform from '@interfaces/IPlatform'

const useAssociationDegree: any = (disciplineId: number, degree: IDegree, associations: any[], platforms: IPlatform[]) => {
  const degreePlatforms = platforms.filter(p => p.degreeIds?.some(d => d === degree.id))

  const degreeLength = associations.filter(a => {
    return (
      a.disciplineId === disciplineId &&
      a.degreeId === degree.id &&
      a.isAssociated &&
      degreePlatforms.some(p => p.id === a.platformId)
    )
  }).length

  const isDegreeChecked = degreeLength > 0 && degreeLength === degree.classes.length * degreePlatforms.length

  const isDegreeIndeterminate = degreeLength > 0 && degreeLength < degree.classes.length * degreePlatforms.length

  const getPlatformLength = (platformId: number) => {
    return associations.filter(a => a.disciplineId === disciplineId && a.degreeId === degree.id && a.platformId === platformId && a.isAssociated).length
  }

  const getClassLength = (classId: number) => {
    return associations.filter(a => a.disciplineId === disciplineId && a.degreeId === degree.id && a.classId === classId && a.isAssociated).length
  }

  const isClassChecked = (classId: number) => {
    return getClassLength(classId) > 0
  }

  const isClassIndeterminate = (classId: number) => {
    const length = getClassLength(classId)
    return length > 0 && length < degreePlatforms.length
  }

  return {
    isDegreeChecked,
    isDegreeIndeterminate,
    getPlatformLength,
    getClassLength,
    isClassChecked,
    isClassIndeterminate,
  }
}

export default useAssociationDegree
