import React, { createContext, useCallback, useEffect, useMemo, useState, useContext } from 'react'
import IDegree from '@interfaces/IDegree'
import IRegistrationEntity from '@interfaces/IRegistrationEntity'
import IRegistrationEntityValidation from '@interfaces/IRegistrationEntityValidation'
import RegistrationTypeEnum from '@enums/RegistrationType.enum'
import RoleEnum from '@enums/Role.enum'
import RegistrationStepEnum from '@enums/RegistrationStep.enum'
import RegistrationTypeAction from '../actions/RegistrationTypeAction'
import * as $Degree from '@services/Degree'
import * as $Discipline from '@services/Discipline'
import * as $EntityRegistration from '@services/EntityRegistration'
import { Outlet } from 'react-router-dom'
import AuthContext from './Auth'
import axios from 'axios'
import IDiscipline from '@interfaces/IDiscipline'
import * as $DegreeGroup from '@services/DegreeGroup'

export interface IEntityLabels {
  plural: string;
  singular: string;
}

interface IRegistrationProps {
  title: string
  token: string
  registrationTypeAction: RegistrationTypeAction|undefined
  entityLabels: IEntityLabels
  setEntityLabels: React.Dispatch<React.SetStateAction<IEntityLabels>>
  degrees: IDegree[]
  setDegrees: React.Dispatch<React.SetStateAction<IDegree[]>>
  disciplines: IDiscipline[]
  step: number
  setStep: React.Dispatch<React.SetStateAction<number>>
  registrationType: RegistrationTypeEnum
  setRegistrationType: React.Dispatch<React.SetStateAction<RegistrationTypeEnum>>
  role: RoleEnum
  setRole: React.Dispatch<React.SetStateAction<RoleEnum>>
  entities: IRegistrationEntity[]
  setEntities: React.Dispatch<React.SetStateAction<IRegistrationEntity[]>>
  storeEntities: (entities: IRegistrationEntity[]) => Promise<any>
  validations: IRegistrationEntityValidation[]
  setValidations: React.Dispatch<React.SetStateAction<IRegistrationEntityValidation[]>>
  isLoading: boolean
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  entity: IRegistrationEntity|null
  setEntity: React.Dispatch<React.SetStateAction<IRegistrationEntity|null>>
  removeEntity: (entity: IRegistrationEntity) => void
  updateEntity: (entity: IRegistrationEntity) => void
  clear: () => void
  usersUpdate: IRegistrationEntityValidation[]
  setUsersUpdate: React.Dispatch<React.SetStateAction<IRegistrationEntityValidation[]>>
  degreeGroupId: number|null
  setDegreeGroupId: React.Dispatch<React.SetStateAction<number|null>>
  additionalData: any
  setAdditionalData: React.Dispatch<React.SetStateAction<any>>
  degreeGroups: any[]
  setDegreeGroups: React.Dispatch<React.SetStateAction<any[]>>
}

const RegistrationContext = createContext<IRegistrationProps>({ } as IRegistrationProps)

export const RegistrationProvider: React.FC = () => {
  const { year } = useContext(AuthContext)

  const generateToken = () => Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)

  const [ token, setToken ] = useState<string>(generateToken())
  const [ role, setRole ] = useState<RoleEnum>(RoleEnum.Student)
  const [ registrationType, setRegistrationType ] = useState<RegistrationTypeEnum>(RegistrationTypeEnum.Upload)
  const [ degrees, setDegrees ] = useState<IDegree[]>([])
  const [ isLoading, setIsLoading ] = useState<boolean>(true)
  const [ step, setStep ] = useState<RegistrationStepEnum>(RegistrationStepEnum.Degrees)
  const [ entities, setEntities ] = useState<IRegistrationEntity[]>([])
  const [ validations, setValidations ] = useState<IRegistrationEntityValidation[]>([])
  const [ registrationTypeAction, setRegistrationTypeAction ] = useState<RegistrationTypeAction>()
  const [ entity, setEntity ] = useState<IRegistrationEntity|null>(null)
  const [ disciplines, setDisciplines ] = useState<IDiscipline[]>([])
  const [ degreeGroupId, setDegreeGroupId ] = useState<number|null>(null)
  const [ additionalData, setAdditionalData ] = useState<any>({})
  const [ entityLabels, setEntityLabels ] = useState<IEntityLabels>({
    plural: 'Alunos',
    singular: 'Aluno'
  })
  const [ usersUpdate, setUsersUpdate ] = useState<IRegistrationEntityValidation[]>([])
  const [ degreeGroups, setDegreeGroups ] = useState<any[]>([])

  useEffect(() => {
    $DegreeGroup.all(new Date().getFullYear()).then(({ data }) => {
      setDegreeGroups(data)
    }).finally(() => setIsLoading(false))
  }, [])

  useEffect(() => {
    axios.all([
      $Degree.all({
        year,
      }),
      $Discipline.all(),
    ]).then(axios.spread(({ data: degrees }: any, { data: disciplines }: any) => {
      setDegrees(degrees)
      setDisciplines(disciplines)
    })).finally(() => setIsLoading(false))
  }, [year])

  useEffect(() => {
    setRegistrationTypeAction(new RegistrationTypeAction(role))
  }, [role])

  const storeEntities = (entities: IRegistrationEntity[]) : Promise<void> => new Promise((resolve, reject) => {
    setEntities([])
    setValidations([])
    setUsersUpdate([])

    try {
      $EntityRegistration.store(role, token, entities)
        .then(({ data: { validations, rowIds, updates } }: any) => {
          setValidations(validations)
          setUsersUpdate(updates)

          rowIds.forEach((rowId: number, index: number) => {
            entities[index].id = rowId
          })

          try {
            // pull entities with has validation to top of array
            validations.forEach((validation: IRegistrationEntityValidation) => {
              const index = entities.findIndex((entity: IRegistrationEntity) => entity.id === validation.rowId)

              if (index > -1) {
                entities.unshift(entities.splice(index, 1)[0])
              }
            })
          } finally {
            // nothing happens...
          }

          setEntities([...entities])

          setTimeout(() => resolve(), 500)
        })
        .catch(reject)
    } catch (e) {
      reject()
    }
  })

  const updateEntity = useCallback((entity: IRegistrationEntity) => {
    const index = entities.findIndex((e: IRegistrationEntity) => e.id === entity.id)

    if (index > -1) {
      entities[index] = entity
      setEntities([ ...entities ])
    }

    setEntity(entity)
  }, [entities])

  const removeEntity = useCallback((entity: IRegistrationEntity) => {
    const entityIndex = entities.findIndex((e: IRegistrationEntity) => e.id === entity.id)

    if (entityIndex > -1) {
      entities.splice(entityIndex, 1)
      setEntities([ ...entities ])
    }

    const validationIndex = validations.findIndex((v: IRegistrationEntityValidation) => v.rowId === entity.id)

    if (validationIndex > -1) {
      validations.splice(validationIndex, 1)
      setValidations([ ...validations ])
    }
  }, [entities, validations])

  const title = useMemo(() => {
    if (step === RegistrationStepEnum.DegreeGroups) {
      return 'Seleção do tipo de cadastro de professores'
    } else if (step === RegistrationStepEnum.Degrees) {
      return 'Gerenciamento de séries e turmas'
    } else if (step === RegistrationStepEnum.Selection) {
      return 'Seleção do tipo de cadastro de ' + entityLabels.plural.toLowerCase()
    } else if (step === RegistrationStepEnum.Data && registrationType === RegistrationTypeEnum.Upload) {
      return 'Envio de arquivo'
    } else if (step === RegistrationStepEnum.Data && registrationType === RegistrationTypeEnum.Manual) {
      return 'Cadastro manual'
    } else if (step === RegistrationStepEnum.Data && registrationType === RegistrationTypeEnum.CopyPaste) {
      return 'Cadastro por cópia e colagem'
    } else if (step === RegistrationStepEnum.Finish) {
      return 'Confirmação de dados e finalização'
    } else if (step === RegistrationStepEnum.Association) {
      return 'Associação de turmas e professores'
    }

    return ''
  }, [entityLabels.plural, registrationType, step])

  const clear = () => {
    setEntities([])
    setValidations([])
    setToken(generateToken())
  }

  return (
    <RegistrationContext.Provider
      value={{
        title,
        token,
        registrationTypeAction,
        entityLabels,
        setEntityLabels,
        degrees,
        setDegrees,
        disciplines,
        step,
        setStep,
        registrationType,
        setRegistrationType,
        role,
        setRole,
        entities,
        setEntities,
        storeEntities,
        validations,
        setValidations,
        isLoading,
        setIsLoading,
        entity,
        setEntity,
        removeEntity,
        updateEntity,
        clear,
        usersUpdate,
        setUsersUpdate,
        degreeGroupId,
        setDegreeGroupId,
        additionalData,
        setAdditionalData,
        degreeGroups,
        setDegreeGroups
      }}
    >
      {year === new Date().getFullYear() ? (
        <Outlet />
      ) : (
        <div className="card">
          <div className="card-header">
            <h6 className="card-title mb-0">Acesso negado</h6>
          </div>

          <div className="card-body">
            <p className="card-text">Não é possível atualizar valores de períodos retroativos.</p>
          </div>
        </div>
      )}
    </RegistrationContext.Provider>
  )
}

export default RegistrationContext
