import React, { createContext, useContext, useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import AuthContext from './Auth'
import * as $School from '@services/School'
import IClass from '@interfaces/IClass'

type MigrationContextProps = {
  migrated: any[]
  setMigrated: React.Dispatch<React.SetStateAction<any[]>>
  selected: any[]
  setSelected: React.Dispatch<React.SetStateAction<any[]>>
  undo: any[]
  setUndo: React.Dispatch<React.SetStateAction<any[]>>
  lastYear: number
  setLastYear: React.Dispatch<React.SetStateAction<number>>
  lastClassId: number
  setLastClassId: React.Dispatch<React.SetStateAction<number>>
  nextClassId: number
  setNextClassId: React.Dispatch<React.SetStateAction<number>>
  classes: IClass[]
  setClasses: React.Dispatch<React.SetStateAction<IClass[]>>
  isLoading: boolean
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
}

const MigrationContext = createContext<MigrationContextProps>({ } as MigrationContextProps)

export const MigrationProvider: React.FC<any> = () => {
  const { years, year, school } = useContext(AuthContext)

  const [ migrated, setMigrated ] = useState<any[]>([])
  const [ selected, setSelected ] = useState<any[]>([])
  const [ undo, setUndo ] = useState<any[]>([])
  const [ lastYear, setLastYear ] = useState<number>(0)
  const [ lastClassId, setLastClassId ] = useState<number>(0)
  const [ nextClassId, setNextClassId ] = useState<number>(0)
  const [ classes, setClasses ] = useState<IClass[]>([])
  const [ isLoading, setIsLoading ] = useState<boolean>(true)

  useEffect(() => {
    if (years.length > 0) {
      setLastYear(years.sort((a, b) => a - b)[0])
    }
  }, [years])

  useEffect(() => {
    $School.classes(school.id, {
      year,
    }).then(({ data }: any) => setClasses(data)).finally(() => setIsLoading(false))
  }, [school.id, year])

  return (
    <MigrationContext.Provider
      value={{
        migrated,
        setMigrated,
        selected,
        setSelected,
        undo,
        setUndo,
        lastYear,
        setLastYear,
        lastClassId,
        setLastClassId,
        nextClassId,
        setNextClassId,
        classes,
        setClasses,
        isLoading,
        setIsLoading,
      }}
    >
      <Outlet />
    </MigrationContext.Provider>
  )
}

export default MigrationContext
