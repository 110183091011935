import axios from 'axios'

export const all = (): Promise<any> => axios.get('/reportments')

export const find = (id: number): Promise<any> => axios.get(`/reportments/${id}`)

export const execute = (id: number): Promise<any> => axios.post(`/reportments/${id}`)

export const download = (id: number): Promise<any> => axios.post(`/reportments/${id}/download`)

export const authorizations = (): Promise<any> => axios.get('/reportments/authorizations')

export const authorization = (id: number): Promise<any> => axios.get(`/reportments/authorizations/${id}`)

export const requestAuthorization = (id: number): Promise<any> => axios.post(`/reportments/${id}/request-authorization`)
