enum EntityValidationErrorEnum {
  NAME_REQUIRED = 'NAME_REQUIRED',
  IDENTIFIER_REQUIRED = 'IDENTIFIER_REQUIRED',
  NUMERIC_IDENTIFIER_REQUIRED = 'NUMERIC_IDENTIFIER_REQUIRED',
  DUPLICATED_IDENTIFIER_FOUND = 'DUPLICATED_IDENTIFIER_FOUND',
  EMAIL_REQUIRED = 'EMAIL_REQUIRED',
  EMAIL_INVALID = 'EMAIL_INVALID',
  DUPLICATED_EMAIL_FOUND = 'DUPLICATED_EMAIL_FOUND',
  NICKNAME_REQUIRED = 'NICKNAME_REQUIRED',
  DUPLICATED_NICKNAME_FOUND = 'DUPLICATED_NICKNAME_FOUND',
  DEGREE_REQUIRED = 'DEGREE_REQUIRED',
  DEGREE_NOT_FOUND = 'DEGREE_NOT_FOUND',
  CLASS_REQUIRED = 'CLASS_REQUIRED',
  CLASS_NOT_FOUND = 'CLASS_NOT_FOUND',
  EMAIL_EXISTS = 'EMAIL_EXISTS',
  EMAIL_DUPLICATED_FIELD = 'EMAIL_DUPLICATED_FIELD',
  IDENTIFIER_DUPLICATED_FIELD = 'IDENTIFIER_DUPLICATED_FIELD',
  NICKNAME_DUPLICATED_FIELD = 'NICKNAME_DUPLICATED_FIELD',
  DISCIPLINE_REQUIRED = 'DISCIPLINE_REQUIRED',
  DISCIPLINE_NOT_FOUND = 'DISCIPLINE_NOT_FOUND',
}

export default EntityValidationErrorEnum
