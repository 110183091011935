import React, { useCallback, useContext, useState } from 'react'
import IDegree from '@interfaces/IDegree'
import PanelContext from '@contexts/Panel'
import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai'
import { getCssProperty } from '@helpers/Theme'
import { BsChevronRight as BsChevronRightComponent, BsFillDashCircleFill } from 'react-icons/bs'
import { BsChevronRight } from './Degree.styles'
import TeacherContext from '@contexts/Teacher'
import useAssociationDegree from '@hooks/useAssociationDegree'
import Indeterminate from '@components/Indeterminate/Indeterminate'
import { TbLineDashed } from 'react-icons/tb'
import AuthContext from '@contexts/Auth'

type Props = {
  degree: IDegree
}

const Degree: React.FC<any> = ({ degree }: Props) => {
  const { platforms } = useContext(PanelContext)
  const { year } = useContext(AuthContext)
  const { disciplineId, associations, setAssociations } = useContext(TeacherContext)

  const [ isExpanded, setIsExpanded ] = useState<boolean>(false)

  const {
    isDegreeChecked,
    isDegreeIndeterminate,
    getClassLength,
    isClassChecked,
    isClassIndeterminate,
    getPlatformLength
  } = useAssociationDegree(disciplineId, degree, associations, platforms)

  const handleDegreeAssociation = () => {
    associations.filter(a => a.disciplineId === disciplineId && a.degreeId === degree.id).forEach(a => a.isAssociated = !isDegreeChecked)
    setAssociations([...associations])
  }

  const handleClassAssociation = ({ target: { value: classId } }: React.ChangeEvent<HTMLInputElement>) => {
    const checked = getClassLength(Number(classId)) === platforms.length

    associations.filter(a => a.disciplineId === disciplineId && a.degreeId === degree.id && a.classId === Number(classId)).forEach(a => {
      a.isAssociated = !checked
    })

    setAssociations([...associations])
  }

  const handlePlatformAssociation = (platformId: number) => {
    const checked = getPlatformLength(platformId) !== degree.classes.length

    associations.filter(a => a.disciplineId === disciplineId && a.degreeId === degree.id && a.platformId === platformId).forEach(a => {
      a.isAssociated = checked
    })

    setAssociations([...associations])
  }

  const handleAssociate = (classId: number, platformId: number) => {
    const index = associations.findIndex(a => a.disciplineId === disciplineId && a.degreeId === degree.id && a.classId === classId && a.platformId === platformId)
    associations[index].isAssociated = !associations[index].isAssociated
    setAssociations([...associations])
  }

  const isAssociated = useCallback((classId: number, platformId: number) => {
    return associations.find(a => a.disciplineId === disciplineId && a.degreeId === degree.id && a.classId === classId && a.platformId === platformId)?.isAssociated || false
  }, [disciplineId, associations, degree])

  const primaryColor = getCssProperty('--primary-color')

  return (
    <>
      <tr>
        <td>
          <div className="d-flex align-items-center">
            {degree.classes.length > 0 ? (
              <BsChevronRight
                size={18}
                color={primaryColor}
                onClick={() => setIsExpanded(!isExpanded)}
                className={`me-2 ${isExpanded ? 'is-expanded' : ''}`}
              />
            ) : (
              <BsChevronRightComponent
                size={18}
                color="rgba(0, 0, 0, 0.2)"
                className="me-2"
              />
            )}

            <div className="form-check">
              <Indeterminate
                type="checkbox"
                id={`degree-${degree.id}`}
                className="form-check-input"
                indeterminate={isDegreeIndeterminate}
                checked={isDegreeChecked}
                onChange={handleDegreeAssociation}
                disabled={!degree.classes.length || year !== new Date().getFullYear()}
              />
              <label className="form-check-label cursor-pointer" htmlFor={`degree-${degree.id}`}>
                {degree.shortname}
              </label>
            </div>
          </div>
        </td>

        {platforms.map(platform => {
          const length = getPlatformLength(platform.id)

          return (
            <td
              className="text-center"
              key={`${degree.id}-p-${platform.id}`}
            >
              {platform.degreeIds?.includes(degree.id) ? (
                <button
                  className="btn btn-outline-light p-0"
                  onClick={() => handlePlatformAssociation(platform.id)}
                  disabled={year !== new Date().getFullYear()}
                >
                  {length === 0 ? (
                    <AiFillCloseCircle size={20} color="#999" />
                  ) : length === degree.classes.length ? (
                    <AiFillCheckCircle size={20} color={primaryColor} />
                  ) : (
                    <BsFillDashCircleFill size={18} color={primaryColor} />
                  )}
                </button>
              ) : <TbLineDashed size={18} />}
            </td>
          )
        })}
      </tr>

      {isExpanded && degree.classes.map(classroom => (
        <tr key={`${degree.id}-c-${classroom.id}`}>
          <td
            className="ps-5"
            style={{
              backgroundColor: '#f7f7f7',
            }}
          >
            <div className="form-check">
              <Indeterminate
                type="checkbox"
                id={`${degree.id}-${classroom.id}`}
                className="form-check-input"
                indeterminate={isClassIndeterminate(classroom.id)}
                checked={isClassChecked(classroom.id)}
                onChange={handleClassAssociation}
                disabled={year !== new Date().getFullYear()}
                value={classroom.id}
              />
              <label className="form-check-label cursor-pointer" htmlFor={`${degree.id}-${classroom.id}`}>
                {classroom.name}
              </label>
            </div>
          </td>

          {platforms.map(platform => (
            <td
              className="text-center"
              key={`${degree.id}-c-${classroom.id}-p-${platform.id}`}
              style={{
                backgroundColor: '#f7f7f7',
              }}
            >
              {platform.degreeIds?.includes(degree.id) ? (
                <button
                  className="btn btn-outline-light btn-sm p-0"
                  onClick={() => handleAssociate(classroom.id, platform.id)}
                  disabled={year !== new Date().getFullYear()}
                >
                  {isAssociated(classroom.id, platform.id) ? (
                    <AiFillCheckCircle size={18} color={primaryColor} />
                  ) : (
                    <AiFillCloseCircle size={18} color="#999" />
                  )}
                </button>
              ) : <TbLineDashed size={18} />}
            </td>
          ))}
        </tr>
      ))}
    </>
  )
}

export default Degree
