import React, { useCallback, useContext, useMemo } from 'react'
import ClassEditContext from '@contexts/ClassEdit'
import IUserSchool from '@interfaces/IUserSchool'
import { Tooltip } from '@screens/Classes/Classes.styles'

const useUserClassEditColumns: any = () => {
  const { selected, setSelected, userSchools } = useContext(ClassEditContext)

  const handleCheckboxChange = useCallback(({ target: { checked, value } }: React.ChangeEvent<HTMLInputElement>) => {
    const id = Number(value)

    if (checked) {
      setSelected([...selected, id])
    } else {
      setSelected(selected.filter((i: number) => i !== id))
    }
  }, [selected, setSelected])

  return useMemo(() => [
    {
      accessor: 'cb',
      disableSortBy: true,
      Header: () => (
        <input
          type="checkbox"
          className="form-check-input"
          checked={selected.length === userSchools.length && userSchools.length > 0}
          onChange={({ target: { checked } }) => {
            if (checked) {
              setSelected(userSchools.map((userSchool: IUserSchool) => userSchool.id) as number[])
            } else {
              setSelected([])
            }
          }}
        />
      ),
      Cell: ({ row: { original: { id } } }: any) => (
        <input
          type="checkbox"
          className="form-check-input"
          checked={selected.includes(id)}
          onChange={handleCheckboxChange}
          value={id}
        />
      )
    },
    {
      Header: 'RA',
      accessor: 'identifier',
    },
    {
      Header: 'RE',
      accessor: 'userCode',
    },
    {
      Header: 'Nome',
      Cell: ({ row: { original: { id, nickname, updatedAt, logUserId, logUserName } } }: any) => {
        return <Tooltip className="platform" key={id}>
          <div className="tooltip-container">
            {nickname}
            <div className="tooltip">Associado à turma em { new Date(updatedAt).toLocaleString() } { logUserId ? 'por ' + logUserName : '' } </div>
          </div>
        </Tooltip>
      }
    },
  ], [selected, userSchools, setSelected, handleCheckboxChange])
}

export default useUserClassEditColumns
