import React, { createContext, useCallback, useEffect, useState } from 'react'
import axios from 'axios'
import { Outlet, useLocation } from 'react-router-dom'
import useAuth from '@hooks/useAuth'
import * as $Auth from '@services/Auth'
import * as $Notification from '@services/Notification'
import * as $School from '@services/School'
import IClient from '@interfaces/IClient'
import ISchool from '@interfaces/ISchool'
import IUser from '@interfaces/IUser'
import INotification from '@interfaces/INotification'
import useStickyState from '@hooks/useStickyState'

interface IAuthContext {
  user: IUser
  client: IClient
  school: ISchool
  setSchool: React.Dispatch<React.SetStateAction<ISchool>>
  permissions: string[]
  token: string
  isLoading: boolean
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  isAuthenticated: boolean
  login(token: string): Promise<void>
  logout(): void
  redirectToLogin(): void
  permissionsFailure: string|null
  reloadPermissions(): void
  notifications: INotification[]
  setNotifications: React.Dispatch<React.SetStateAction<INotification[]>>
  year: number
  setYear: React.Dispatch<React.SetStateAction<number>>
  years: number[]
  setYears: React.Dispatch<React.SetStateAction<number[]>>
  appId: string
  setAppId: React.Dispatch<React.SetStateAction<string>>
}

const AuthContext = createContext<IAuthContext>({ } as IAuthContext)

export const AuthProvider: React.FC = () => {
  const [ permissionsFailure, setPermissionsFailure ] = useState<string>('')
  const [ notifications, setNotifications ] = useState<INotification[]>([])
  const [ year, setYear ] = useStickyState<number>(new Date().getFullYear(), 'year')
  const [ years, setYears ] = useState<number[]>([])
  const {
    user,
    setUser,
    token,
    setToken,
    client,
    setClient,
    school,
    setSchool,
    isAuthenticated,
    setIsAuthenticated,
    permissions,
    setPermissions,
    isLoading,
    setIsLoading,
    appId,
    setAppId, } = useAuth()

  const { search } = useLocation()

  const redirectToLogin = useCallback(() => {
    const client = JSON.parse(localStorage.getItem('client') || '{}') as IClient
    window.location.href = client?.domain?.length ? `${client.domain}/sair` : 'https://simulados.evolucional.com.br/sair'
  }, [])

  const setAuth = useCallback((token: string, { user, client, school, appId }: any) => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

    setUser(user)
    setToken(token)
    setClient(client)
    setSchool(school)
    setAppId(appId)
    setIsAuthenticated(true)
  }, [setAppId, setClient, setIsAuthenticated, setSchool, setToken, setUser])

  const reloadPermissions = useCallback(() => {
    if (school?.id) {
      setPermissionsFailure('')

      axios.all([
        $School.years(school.id),
        $Auth.permissions(),
      ]).then(axios.spread(({ data: years }: any, { data: permissions }: any) => {
        setYears(years)
        setPermissions(permissions)
      })).catch(e => setPermissionsFailure(e.message))
    }
  }, [school.id, setPermissions])

  useEffect(() => {
    if (isAuthenticated) {
      $Notification.all().then(({ data }) => setNotifications(data))
    }
  }, [isAuthenticated, school])

  useEffect(() => reloadPermissions(), [reloadPermissions])

  useEffect(() => {
    const token = localStorage.getItem('token') as string

    if (new URLSearchParams(search).has('token'))
      return

    if (token.length > 0 && !isAuthenticated) {
      $Auth.me(token).then(({ data }) => {
        setAuth(token, data)
        setIsLoading(false)
      }).catch(redirectToLogin)
    } else if (token.length === 0) {
      redirectToLogin()
    }
  }, [isAuthenticated, redirectToLogin, search, setAuth, setIsLoading, setToken])

  const login = (token: string) => new Promise<void>((resolve, reject) => $Auth.me(token).then(({ data }) => {
    setAuth(token, data)
    resolve()
  }).catch(reject).finally(() => setIsLoading(false)))

  const logout = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('hideNavigation')

    setToken('')
    setUser({} as IUser)
    setAppId('')
    setPermissions([])
    setIsAuthenticated(false)

    redirectToLogin()
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        client,
        school,
        setSchool,
        permissions,
        token,
        isLoading,
        setIsLoading,
        isAuthenticated,
        login,
        logout,
        redirectToLogin,
        permissionsFailure,
        reloadPermissions,
        notifications,
        setNotifications,
        year,
        setYear,
        years,
        setYears,
        appId,
        setAppId,
      }}
    >
      <Outlet />
    </AuthContext.Provider>
  )
}

export default AuthContext
