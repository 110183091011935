import axios from 'axios'
import IRegistrationEntity from '@interfaces/IRegistrationEntity'
import IUser from '@interfaces/IUser'

export const all = (params: any = {}): Promise<any> => {
  return axios.get('managers', {
    params,
  })
}

export const find = (id: number): Promise<any> => {
  return axios.get(`/managers/${id}`)
}

export const create: any = (managers: IRegistrationEntity[]) => {
  return axios.post('managers', managers)
}

export const update = (id: number, manager: IUser): Promise<any> => {
  return axios.put('managers/' + id, manager)
}
