import React, { useRef, useState } from 'react'
import * as $Class from '@services/Class'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import Button from '@components/Button/Button'

const Create: React.FC<any> = ({ degree, onSaved }: any) => {
  const [ value, setValue ] = useState<string>('')
  const [ isLoading, setIsLoading ] = useState<boolean>(false)

  const inputRef = useRef<HTMLInputElement>(null)
  const SweetAlert = withReactContent(Swal)

  const handleClick = () => {
    if (!value.length) return

    setIsLoading(true)

    $Class.create(degree.id, value)
      .then(({ data: class_ }: any) => {
        onSaved(degree, class_)
      })
      .catch((e) => SweetAlert.fire({
        title: e.response?.data?.message ?? 'Erro ao cadastrar a turma',
        icon: 'error',
      }))
      .finally(() => {
        setIsLoading(false)

        if (inputRef.current) {
          inputRef.current.value = ''
        }
      })
  }

  return (
    <div className="input-group">
      <input
        type="text"
        ref={inputRef}
        className="form-control"
        placeholder="Digite o nome da turma"
        onChange={(e: any) => setValue(e.target.value)}
      />
      <Button className="btn btn-sm btn-primary" onClick={handleClick} isLoading={isLoading}>Criar turma</Button>
    </div>
  )
}

export default Create
