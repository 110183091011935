import React, { useContext, useEffect } from 'react'
import NavigationContext from '@contexts/Navigation'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Header from '@components/Header/Header'
import Sidebar from '@components/Sidebar/Sidebar'
import PanelContext from '@contexts/Panel'
import { ContainerFluid } from './App.styles'
import { Outlet } from 'react-router-dom'
import Notifications from '@components/Notifications/Notifications'

const App: React.FC = () => {
  const { hideNavigation } = useContext(NavigationContext)
  const { connectionLost, sidebarCollapsed, setSidebarCollapsed } = useContext(PanelContext)

  const SweetAlert = withReactContent(Swal)

  useEffect(() => {
    window.addEventListener('resize', () => {
      if (window.innerWidth < 1200 && !sidebarCollapsed) {
        setSidebarCollapsed(true)
      }
    })
  }, [setSidebarCollapsed, sidebarCollapsed])

  useEffect(() => {
    if (connectionLost) {
      SweetAlert.fire({
        title: 'Conexão perdida',
        text: 'A sua conexão foi perdida, por favor, aguarde ou tente novamente mais tarde.',
        icon: 'warning',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => SweetAlert.showLoading(),
      })
    } else {
      SweetAlert.close()
      SweetAlert.hideLoading()
    }
  }, [SweetAlert, connectionLost])

  return (
    <>
      <Header />

      <Notifications />

      <ContainerFluid className="container-fluid">
        <div className="row">
          {!hideNavigation && <Sidebar />}

          <main className={'main col-12 ' + (!hideNavigation ? (sidebarCollapsed ? 'col-md-11 collapsed' : 'col-md-10') : '')}>
            <Outlet />
          </main>
        </div>
      </ContainerFluid>
    </>
  )
}

export default App
