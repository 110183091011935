import React, { createRef, useCallback, useEffect, useState } from 'react'

type ILogotipoProps = React.DetailedHTMLProps<React.CanvasHTMLAttributes<HTMLCanvasElement>, HTMLCanvasElement> & {
  width: number;
  height: number;
  color: string
  src: string;
}

const Logotipo: React.FC<ILogotipoProps> = ({ color, src, ...props }: ILogotipoProps) => {
  const canvasRef = createRef<HTMLCanvasElement>()

  const [ image, setImage ] = useState<HTMLImageElement|null>(null)
  const [ imageLoaded, setImageLoaded ] = useState<boolean>(false)
  const [ processFinished, setProcessFinished ] = useState<boolean>(false)

  const getRgb = useCallback(() => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color)

    return {
      r: result && result[1] ? parseInt(result[1], 16) : 0,
      g: result && result[2] ? parseInt(result[2], 16) : 0,
      b: result && result[3] ? parseInt(result[3], 16) : 0,
    }
  }, [color])

  useEffect(() => {
    const image = new Image

    image.src = src
    image.crossOrigin = 'Anonymous'
    image.addEventListener('load', () => setImageLoaded(image.complete))

    setImage(image)
  }, [src])

  useEffect(() => {
    if (canvasRef.current != null && image && imageLoaded && !processFinished) {
      const context = canvasRef.current.getContext('2d')

      if (context != null) {
        try {
          canvasRef.current.width = image.width * 0.5
          canvasRef.current.height = image.height * 0.5

          context.drawImage(image, 0, 0, canvasRef.current.width, canvasRef.current.height)

          const imageData = context.getImageData(0, 0, image.width, image.height)

          const pixels = imageData.data
          const rgb = getRgb()

          for (let i = 0; i < pixels.length; i += 4) {
            if (pixels[i] > 10) {
              pixels[i] = rgb.r
              pixels[i + 1] = rgb.g
              pixels[i + 2] = rgb.b
            }
          }

          context.putImageData(imageData, 0, 0)

          setProcessFinished(true)
        } catch (e) {
          //
        }
      }
    }
  }, [canvasRef, getRgb, image, imageLoaded, processFinished])

  return (
    <canvas {...props} ref={canvasRef}></canvas>
  )
}

export default Logotipo
