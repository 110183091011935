import React from 'react'
import IUserRegistrationEntity from '@interfaces/IUserRegistrationEntity'
import Delete from '../Delete/Delete'

const Table: React.FC<any> = ({ entities }: any) => {
  return (
    <div className="card-body p-0">
      <div className="table-responsive">
        <table className="table table-striped table-dashed mb-0">
          <thead>
            <tr>
              <th style={{ width: 110 }}>ID da Escola</th>
              <th>RA</th>
              <th>Nome</th>
              <th>E-mail</th>
              <th>Senha</th>
              <th>Cargo</th>
              <th>Série</th>
              <th>Turma</th>
              <th>Disciplina</th>
              <th>Plataforma</th>
              <th>Simulado</th>
              <th>Excluir</th>
              <th style={{ width: 110 }}>Ações</th>
            </tr>
          </thead>

          <tbody>
            {entities.map((entity: IUserRegistrationEntity) => (
              <tr key={entity.id}>
                <td>{entity.schoolId}</td>
                <td>{entity.identifier}</td>
                <td>{entity.name}</td>
                <td>{entity.email}</td>
                <td>{entity.password}</td>
                <td>{entity.role}</td>
                <td>{entity.degreeName}</td>
                <td>{entity.className}</td>
                <td>{entity.disciplineName}</td>
                <td>{entity.platformId}</td>
                <td>{entity.assessmentId}</td>
                <td>
                  {entity.delete ? (
                    <span className="text-danger">Sim</span>
                  ) : (
                    <span className="text-muted">Não</span>
                  )}
                </td>
                <td>
                  <Delete entity={entity} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Table
