import React, { useContext } from 'react'
import { BsChevronRight } from 'react-icons/bs'
import RegistrationContext from '@contexts/Registration'
import RegistrationTypeEnum from '@enums/RegistrationType.enum'
import RegistrationStepEnum from '@enums/RegistrationStep.enum'
import AuthContext from '@contexts/Auth'

type Props = {
  uses: string[]
  instructions: any
}

const Selection: React.FC<any> = ({ uses, instructions }: Props) => {
  const { years } = useContext(AuthContext)
  const { setRegistrationType, setStep } = useContext(RegistrationContext)

  const handleRegistrationType = (registrationType: RegistrationTypeEnum) => {
    setRegistrationType(registrationType)
    setStep(RegistrationStepEnum.Data)
  }

  return (
    <>
      <div className="card-body">
        <div className="row">
          <div className={'col-12 col-md-' + (uses.includes('migration') && years.length > 1 ? 3 : 4)}>
            <button className="btn btn-outline-primary btn-option" onClick={() => handleRegistrationType(RegistrationTypeEnum.Upload)}>
              <span>Upload de planilha</span> <BsChevronRight size={22}/>
            </button>

            <div className="mt-3 p-3 outline">
              {instructions?.upload}
            </div>
          </div>

          <div className={'col-12 col-md-' + (uses.includes('migration') && years.length > 1 ? 3 : 4)}>
            <button className="btn btn-outline-primary btn-option" onClick={() => handleRegistrationType(RegistrationTypeEnum.CopyPaste)}>
              <span>Copiar e colar</span> <BsChevronRight size={22}/>
            </button>

            <div className="mt-3 p-3 outline">
              {instructions?.copyPaste}
            </div>
          </div>

          <div className={'col-12 col-md-' + (uses.includes('migration') && years.length > 1 ? 3 : 4)}>
            <button className="btn btn-outline-primary btn-option" onClick={() => handleRegistrationType(RegistrationTypeEnum.Manual)}>
              <span>Preenchimento manual</span> <BsChevronRight size={22}/>
            </button>

            <div className="mt-3 p-3 outline">
              {instructions?.manual}
            </div>
          </div>

          {uses.includes('migration') && years.length > 1 && (
            <div className={'col-12 col-md-' + (uses.includes('migration') ? 3 : 4)}>
              <button className="btn btn-outline-primary btn-option" onClick={() => handleRegistrationType(RegistrationTypeEnum.Migration)}>
                <span>Migração de anos anteriores</span> <BsChevronRight size={22}/>
              </button>

              <div className="mt-3 p-3 outline">
                {instructions?.migration}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default Selection
