import React, { useContext, useEffect, useMemo, useState } from 'react'
import Steps from '@components/Steps/Steps'
import RegistrationContext from '@contexts/Registration'
import { useNavigate } from 'react-router'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import Selection from '@components/Registration/Selection/Selection'
import Finish from '@components/Registration/Finish/Finish'
import Upload from '@components/Registration/RegistrationTypes/Upload/Upload'
import Manual from '@components/Registration/RegistrationTypes/Manual/Manual'
import CopyPaste from '@components/Registration/RegistrationTypes/CopyPaste/CopyPaste'
import RegistrationStepEnum from '@enums/RegistrationStep.enum'
import RoleEnum from '@enums/Role.enum'
import RegistrationTypeEnum from '@enums/RegistrationType.enum'
import * as $EntityRegistration from '@services/EntityRegistration'
import DegreeGroups from '@components/Registration/DegreeGroups/DegreeGroups'
import Association from '@components/Registration/Association/Association'

const TeachersRegister: React.FC<any> = () => {
  const { title, token, registrationType, step, setStep, setRole, setEntityLabels, clear, degreeGroups } = useContext(RegistrationContext)
  const [ isLoading, setIsLoading ] = useState<boolean>(false)

  const SweetAlert = withReactContent(Swal)
  const navigate = useNavigate()

  useEffect(() => {
    setRole(RoleEnum.Teacher)
    setStep(RegistrationStepEnum.DegreeGroups)

    setEntityLabels({
      plural: 'Professores',
      singular: 'Professor',
    })
  }, [setEntityLabels, setRole, setStep])

  const handleStepClick = (step: number) => {
    switch (step) {
    case 1:
      setStep(RegistrationStepEnum.DegreeGroups)
      break

    case 2:
      setStep(RegistrationStepEnum.Selection)
      break

    case 3:
      setStep(RegistrationStepEnum.Data)
      break

    case 4:
      setStep(RegistrationStepEnum.Finish)
      break

    case 5:
      setStep(RegistrationStepEnum.Association)
      break
    }
  }

  const currentStep = useMemo((): number => {
    switch (step) {
    case RegistrationStepEnum.DegreeGroups:
      return 1
    case RegistrationStepEnum.Selection:
      return 2
    case RegistrationStepEnum.Data:
      return 3
    case RegistrationStepEnum.Finish:
      return 4
    case RegistrationStepEnum.Association:
      return 5
    }

    return 1
  }, [step])

  const onFinish = () => {
    setIsLoading(true)

    $EntityRegistration.finish(token).then(() => {
      clear()

      navigate('/professores')

      SweetAlert.fire({
        title: 'Sucesso!',
        text: 'Professores cadastrados com sucesso!',
        icon: 'success',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: true,
      })
    }).catch(() => {
      SweetAlert.fire({
        title: 'Erro!',
        text: 'Erro ao cadastrar os professores!',
        icon: 'error'
      })
    }).finally(() => setIsLoading(false))
  }

  return (
    <>
      <h1 className="fs-3 mb-3">Cadastro de professores</h1>

      <div className="card mb-4">
        <div className="card-header d-flex justify-content-between align-items-center">
          <div className="mb-0">{title}</div>
          {degreeGroups.length > 0 ? (
            <Steps
              current={currentStep}
              range={[ 1, 5 ]}
              onClick={handleStepClick}
            />
          ): null}
        </div>

        {step === RegistrationStepEnum.DegreeGroups && (
          <DegreeGroups />
        )}

        {step === RegistrationStepEnum.Selection && (
          <Selection
            uses={[
              'upload', 'manual', 'copyPaste',
            ]}
            instructions={{
              upload: (
                <>Inscreva os professores via upload de planilha de Excel. Caso selecionado este modelo, o modelo de planilha e forma de preenchimento serão mostrados na próxima etapa.</>
              ),
              copyPaste: (
                <>Copie e cole os dados dos professores de um arquivo salvo atentando-se aos dados informados e espaçamentos. Caso selecionado este formato, o modelo de preenchimento será mostrado na etapa a seguir.</>
              ),
              manual: (
                <>Preencha manualmente um formulário na plataforma com as informações dos professores. Caso selecionado este formato, o modelo de preenchimento será exibido na etapa a seguir.</>
              )
            }}
          />
        )}

        {step === RegistrationStepEnum.Data && registrationType === RegistrationTypeEnum.Upload && (
          <Upload
            instruction={(
              <>
                Preencher a planilha modelo com o nome completo do professor, apelido único de cada professor, e-mail do professor e disciplina.
                O campo de senha é opcional. A disciplina deve estar no formato “FIS” ou “Física”.
                <br/>
                Atenção: dois professores, gestores ou corretores diferentes não podem ter o mesmo apelido em uma mesma instituição.
              </>
            )}
          />
        )}

        {step === RegistrationStepEnum.Data && registrationType === RegistrationTypeEnum.Manual && (
          <Manual
            instruction={(
              <>
                Preencher dados com o apelido único de cada professor, nome completo do professor, e o e-mail do professor. O campo de senha é opcional.
                <br/>
                Atenção: dois professores, gestores ou corretores diferentes não podem possuir o mesmo apelido em uma mesma instituição.
              </>
            )}
          />
        )}

        {step === RegistrationStepEnum.Data && registrationType === RegistrationTypeEnum.CopyPaste && (
          <CopyPaste
            instruction={(
              <>
                Preencher uma planilha com o nome completo do professor, apelido único de cada professor, e-mail do professor, disciplina e, em seguida,
                copiar tabela e colar na área lateral. O campo de senha é opcional. A disciplina deve estar no formato “FIS” ou “Física”.
                <br/>
                Atenção: dois professores, gestores ou corretores diferentes não podem ter o mesmo apelido em uma mesma instituição.
              </>
            )}
          />
        )}

        {step === RegistrationStepEnum.Finish && (
          <Finish
            onFinish={() => setStep(RegistrationStepEnum.Association)}
            isLoading={isLoading}
            nextStep={{
              label: 'Associar turmas e finalizar',
            }}
          />
        )}

        {step === RegistrationStepEnum.Association && (
          <Association isLoading={isLoading} setIsLoading={setIsLoading} onFinish={onFinish} />
        )}
      </div>
    </>
  )
}

export default TeachersRegister
