import React, { useContext, useEffect, useState } from 'react'
import { Nav, Spinner, Tab } from 'react-bootstrap'
import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai'
import { getCssProperty } from '@helpers/Theme'
import AutoComplete from './Components/Autocomplete'
import * as $Degree from '@services/Degree'
import * as $Discipline from '@services/Discipline'
import * as $Teacher from '@services/Teacher'
import { Tag } from 'react-tag-autocomplete'
import Managers from './Managers/Managers'
import { Link } from 'react-router-dom'
import axios from 'axios'
import IDegree from '@interfaces/IDegree'
import IClass from '@interfaces/IClass'
import IUser from '@interfaces/IUser'
import IDiscipline from '@interfaces/IDiscipline'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Degrees from './Components/Degrees'
import PanelContext from '@contexts/Panel'
import AuthContext from '@contexts/Auth'

export interface ITeacherDiscipline {
  userId: string|number;
  platformId?: number;
  disciplineId: number;
  degreeId: number;
  classId: number;
}

export interface IDisciplineAssociation {
  platformId: number
  platformName?: string
  degreeId: number
  degreeName?: string
  classId: number
	userId: number
  disciplineId: number
  disciplineName?: string
}

const TeachersAndProducts: React.FC<any> = () => {
  const { year } = useContext(AuthContext)
  const { platforms } = useContext(PanelContext)

  const [ teachers, setTeachers ] = useState<IUser[]>([])
  const [ degrees, setDegrees ] = useState<IDegree[]>([])
  const [ disciplines, setDisciplines ] = useState<IDiscipline[]>([])
  const [ editMode, setEditMode ] = useState<boolean>(false)
  const [ dataToSave, setDataToSave ] = useState<ITeacherDiscipline[]>([])
  const [ dataToDelete, setDataToDelete ] = useState<ITeacherDiscipline[]>([])
  const [ isLoading, setIsLoading ] = useState<boolean>(true)
  const [ associations, setAssociations ] = useState<IDisciplineAssociation[]>([])

  const SweetAlert = withReactContent(Swal)

  useEffect(() => {
    setIsLoading(true)

    axios.all([
      $Teacher.all({
        year,
      }),
      $Teacher.disciplines({
        year,
      }),
      $Degree.all({
        year,
      }),
      $Discipline.all(),
    ]).then(axios.spread(({ data: teachers }: any, { data: associations }: any, { data: degrees }: any, { data: disciplines }: any) => {
      setTeachers(teachers)
      setAssociations(associations)
      setDegrees(degrees)
      setDisciplines(disciplines)
    })).finally(() => setIsLoading(false))
  }, [year])

  const enableEditMode = () => setEditMode(true)

  const handleOnAddition = (tag: Tag, platformId: number, disciplineId: number, degreeId: number, classId: number) => {
    const item = dataToSave.find((item: ITeacherDiscipline) => item.userId === tag.id && item.platformId === platformId && item.disciplineId === disciplineId && item.classId === classId)

    if (item == null) {
      setDataToSave([
        ...dataToSave,
        {
          userId: tag.id,
          platformId,
          disciplineId,
          degreeId,
          classId,
        },
      ])
    }
  }

  const handleOnDelete = (tag: Tag, platformId: number, disciplineId: number, degreeId: number, classId: number) => {
    const index = dataToSave.findIndex((item: ITeacherDiscipline) => item.userId === tag.id && item.platformId === platformId && item.disciplineId === disciplineId && item.classId === classId)

    setDataToDelete([
      ...dataToDelete,
      {
        userId: tag.id,
        platformId,
        disciplineId,
        degreeId,
        classId,
      },
    ])

    if (index !== -1) {
      dataToSave.splice(index, 1)
      setDataToSave([ ...dataToSave ])
    }
  }

  const cancel = () => {
    setEditMode(false)
    setDataToSave([])
    setDataToDelete([])
  }

  const save = () => {
    SweetAlert.fire({
      title: 'Salvando alterações...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => SweetAlert.showLoading()
    })

    const requests: Promise<any>[] = []

    if (dataToSave.length > 0)
      requests.push($Teacher.updateDisciplines(dataToSave))

    if (dataToDelete.length > 0)
      requests.push($Teacher.deleteDisciplines(dataToDelete))

    axios.all(requests).then(() => {
      setDataToSave([])
      setDataToDelete([])

      axios.all([
        $Teacher.all({
          year,
        }),
        $Teacher.disciplines({
          year,
        }),
      ]).then(axios.spread(({ data: teachers }: any, { data: associations }: any) => {
        setTeachers(teachers)
        setAssociations(associations)
      })).finally(() => {
        SweetAlert.fire({
          title: 'Alterações salvas com sucesso!',
          icon: 'success',
          didOpen: () => SweetAlert.hideLoading(),
        })

        setEditMode(false)
      })
    }).catch(() => {
      SweetAlert.fire({
        title: 'Erro ao salvar alterações!',
        icon: 'error',
        didOpen: () => SweetAlert.hideLoading(),
      })
    })
  }

  return (
    <div className="teachers-and-products-page mb-4">
      <div className="card mb-4">
        {!isLoading && platforms?.length > 0 ? (
          <Tab.Container defaultActiveKey={platforms[0].name}>
            <div className="card-header d-flex justify-content-between align-items-center p-0 pe-4 has-tabs">
              <Nav variant="tabs">
                {platforms?.filter(platform => !platform.isCurrentPlatform).map((product: any) => (
                  <Nav.Item key={`nav-${product.name}`}>
                    <Nav.Link eventKey={product.name} disabled={editMode}>{product.name}</Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>

              {year === new Date().getFullYear() && (
                <Link to="/professores-e-produtos/cadastro-em-massa" className="btn btn-outline-primary">Cadastro em massa</Link>
              )}
            </div>

            <Tab.Content>
              {platforms?.filter(platform => !platform.isCurrentPlatform).map((platform: any) => (
                <Tab.Pane eventKey={platform.name} key={`tab-${platform.id}`}>
                  <div className="card-body pb-0">
                    <div className="d-flex justify-content-between align-items-center mb-3 pt-3">
                      <h6>Professores</h6>
                      {!editMode && year === new Date().getFullYear() && (
                        <button className="btn btn-link" onClick={enableEditMode}>Editar professores &gt;</button>
                      )}
                    </div>

                    {degrees?.length > 0 ? (
                      <Degrees
                        platform={platform}
                        degrees={degrees}
                        disciplines={disciplines}
                        associations={associations}
                        label={(data: IDisciplineAssociation[], index: number) => {
                          return (
                            <div className="discipline" key={`length-${index}`}>
                              <span>
                                {data.length} {data.length > 0 ? <AiFillCheckCircle size={18} color={getCssProperty('--primary-color')} /> : <AiFillCloseCircle size={18} color="#999" />}
                              </span>
                            </div>
                          )
                        }}
                        render={(data: IDisciplineAssociation[], class_: IClass, discipline: IDiscipline) => {
                          const classTeachers = teachers?.filter((teacher: IUser) => data?.length && data.some((association: IDisciplineAssociation) => association.userId === teacher.id))

                          return (
                            <div className="teachers">
                              {!editMode ? (
                                <>
                                  {classTeachers.map((teacher: any) => (
                                    <div className="teacher-name" key={`teacher-${teacher.nickname}-class-${class_.id}`}>
                                      <span>{teacher.nickname}</span>
                                    </div>
                                  ))}
                                </>
                              ) : (
                                <AutoComplete
                                  teachers={teachers}
                                  value={classTeachers}
                                  onAddition={(tag: Tag) => handleOnAddition(tag, platform.id, discipline.id, class_.degreeId, class_.id)}
                                  onDelete={(tag: Tag) => handleOnDelete(tag, platform.id, discipline.id, class_.degreeId, class_.id)}
                                />
                              )}
                            </div>
                          )
                        }}
                      />
                    ) : (isLoading ? (
                      <div className="d-flex justify-content-center p-3 bg-light mb-3">
                        <Spinner animation="border" variant="primary" />
                      </div>
                    ) : (
                      <div className="alert bg-light text-center">Não há resultados a serem exibidos.</div>
                    ))}

                    {editMode && year === new Date().getFullYear() && (
                      <div className="d-flex justify-content-end mb-3">
                        <button className="btn btn-outline-primary me-3" onClick={cancel} disabled={isLoading}>Cancelar</button>
                        <button className="btn btn-primary" onClick={save} disabled={isLoading}>Salvar</button>
                      </div>
                    )}
                  </div>
                </Tab.Pane>
              ))}
            </Tab.Content>
          </Tab.Container>
        ) : isLoading ? (
          <div className="card-body">
            <div className="d-flex justify-content-center p-3 bg-light">
              <Spinner animation="border" variant="primary" />
            </div>
          </div>
        ) : (
          <div className="card-body">
            <div className="alert bg-light text-center">Nenhuma plataforma cadastrada.</div>
          </div>
        )}
      </div>

      <Managers />
    </div>
  )
}

export default TeachersAndProducts
