import React, { useState, useContext, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import ClassEditContext from '@contexts/ClassEdit'
import * as $Classroom from '@services/Class'
import IClass from '@interfaces/IClass'
import { getCssProperty } from '@helpers/Theme'
import IUserSchool from '@interfaces/IUserSchool'

const Migrate: React.FC<any> = () => {
  const { showConfirmMigrate, setShowConfirmMigrate, selected, degrees, userSchools, setUserSchools, setSelected, year } = useContext(ClassEditContext)
  const [ degreeId, setDegreeId ] = useState<number>(0)
  const [ classList, setClassList ] = useState<IClass[]>([] as IClass[])
  const [ classId, setClassId ] = useState<number>(0)

  const SweetAlert = withReactContent(Swal)

  useEffect(() => {
    if (degreeId > 0)
      $Classroom.findClasses(degreeId, year).then(({ data }: any) => setClassList(data))
  }, [degreeId, year])

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (degreeId == 0 || classId == 0)
      SweetAlert.fire({
        title: 'Erro!',
        text: 'Insira informações válidas.',
        icon: 'error',
        confirmButtonColor: getCssProperty('--primary-color'),
      })
    else {
      SweetAlert.fire({
        title: 'Aguarde...',
        showConfirmButton: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: () => SweetAlert.showLoading(),
      })
      $Classroom.migrate(degreeId, classId, selected).then(() => {
        SweetAlert.fire({
          title: 'Sucesso!',
          text: 'Aluno' + (selected.length > 1 ? 's migrados' : ' migrado') + ' com sucesso!',
          icon: 'success',
          confirmButtonColor: getCssProperty('--primary-color'),
        })
        setUserSchools(userSchools.filter((users: IUserSchool) => !selected.includes(users.id)))
        setSelected([])
        setShowConfirmMigrate(false)
      }).catch((e => SweetAlert.fire({
        title: 'Erro!',
        text: e.response?.data?.message ?? 'Ocorreu um erro ao migrar turma. Tente novamente mais tarde.',
        icon: 'error',
        confirmButtonColor: getCssProperty('--primary-color'),
      })))
    }
  }

  const handleReset = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setShowConfirmMigrate(false)
    setDegreeId(0)
    setClassId(0)
    setClassList([])
  }

  return (
    <Modal
      backdrop="static"
      show={showConfirmMigrate}
      onHide={() => setShowConfirmMigrate(false)}
      centered
    >
      <form onSubmit={handleSubmit} onReset={handleReset}>
        <Modal.Header>
          <h5 className="mb-0">Migrar aluno{selected.length > 1 ? 's' : ''}</h5>
        </Modal.Header>

        <Modal.Body>
          <div className="form-group mb-3">
            <label>Série</label>
            <select className="form-control" name="degreeId" onChange={(e: any) => setDegreeId(e.target.value)}>
              <option value="0">Selecione uma série</option>
              {degrees.map((degree: any) => (
                <option value={degree.id} key={degree.id}>{degree.name}</option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label>Turma</label>
            <select
              className="form-control"
              name="classId"
              onChange={(e: any) => setClassId(e.target.value)}
              disabled={degreeId == 0 ? true : false && !classList.length}
            >
              <option value="0">Selecione uma turma</option>
              {classList.map((cl: any) => (
                <option value={cl.id} key={cl.id}>{cl.name}</option>
              ))}
            </select>
          </div>
        </Modal.Body>

        <Modal.Footer className="d-flex justify-content-end">
          <button className="btn btn-outline-primary me-2" type="reset">Cancelar</button>
          <button className="btn btn-primary" type="submit">Migrar</button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default Migrate
