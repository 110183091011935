import React, { useCallback, useContext } from 'react'
import Button from '@components/Button/Button'
import * as $User from '@services/User'
import PasswordContext from '@contexts/Password'

type Props = {
  userId: number
  passwordLength: number
  disabled: boolean
}

const Password: React.FC<any> = ({ userId, passwordLength, disabled }: Props) => {
  const { sendedPasswords, setSendedPasswords, sendingPasswordToUserId, setSendingPasswordToUserId, isLoading, setIsLoading } = useContext(PasswordContext)

  const handleSetSendedPassword = useCallback((hasError: boolean) => {
    const index = sendedPasswords.findIndex(({ userId: id }) => id === userId)

    if (index === -1) {
      sendedPasswords.push({ userId, hasError })
    } else {
      sendedPasswords[index].hasError = hasError
    }

    setSendedPasswords([ ...sendedPasswords ])
  }, [sendedPasswords, setSendedPasswords, userId])

  const sendPassword = () => {
    setIsLoading(true)
    setSendingPasswordToUserId(userId)

    $User.sendPassword(userId).then(() => {
      handleSetSendedPassword(false)
    }).catch(() => {
      handleSetSendedPassword(true)
    }).finally(() => {
      setIsLoading(false)
      setSendingPasswordToUserId(null)
    })
  }

  const sendedPassword = sendedPasswords?.find(({ userId: id }) => id === userId) ?? null

  if (sendedPassword && sendedPassword?.hasError === false) {
    return <small className="text-success">Senha enviada</small>
  }

  return (
    <Button
      className={'btn btn-link text-nowrap' + (sendedPassword?.hasError && !isLoading ? ' text-danger' : '')}
      onClick={sendPassword}
      isLoading={isLoading}
      disabled={isLoading || disabled}
      loadingText={sendingPasswordToUserId === userId ? 'Enviando, aguarde...' : (passwordLength > 20 ? 'Enviar nova senha' : 'Enviar senha por e-mail')}
    >
      {sendedPassword?.hasError ? 'Erro ao enviar, tentar novamente?' : (passwordLength > 20 ? 'Enviar nova senha' : 'Enviar senha por e-mail')}
    </Button>
  )
}

export default Password
