import React, { useContext } from 'react'
import * as $Teacher from '@services/Teacher'
import { Link } from 'react-router-dom'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import Association from './Association/Association'
import TeacherContext from '@contexts/Teacher'
import AuthContext from '@contexts/Auth'

const TeachersEdit: React.FC<any> = () => {
  const { year } = useContext(AuthContext)
  const { teacher, setTeacher, associations, isLoading, setIsLoading } = useContext(TeacherContext)

  const SweetAlert = withReactContent(Swal)

  const handleUserChange = (e: any) => {
    const { name, value } = e.target

    setTeacher({
      ...teacher,
      [name]: value,
    })
  }

  const update = () => {
    setIsLoading(true)

    SweetAlert.showLoading()

    $Teacher.update(teacher.id as number, year, {
      name: teacher.name,
      email: teacher.email,
      nickname: teacher?.nickname,
      password: teacher.password,
      passwordConfirmation: teacher.passwordConfirmation,
      associations,
    }).then(() => {
      SweetAlert.fire({
        title: 'Sucesso!',
        text: 'Professor atualizado com sucesso!',
        icon: 'success'
      })
    }).catch(e => {
      SweetAlert.fire({
        title: 'Erro!',
        text: e.response?.data?.message ?? 'Erro ao atualizar o professor!',
        icon: 'error'
      })
    }).finally(() => {
      setIsLoading(false)
      SweetAlert.hideLoading()
    })
  }

  return (
    <>
      <div className="d-flex justify-content-end mb-3">
        <Link to="/professores" className="btn btn-outline-primary me-2">Voltar</Link>
        <button className="btn btn-primary" onClick={update} disabled={isLoading}>Atualizar</button>
      </div>

      <div className="card mb-3">
        <div className="card-header">
          Editar professor ({teacher?.nickname})
        </div>

        <div className="card-body">
          <div className="row">
            <div className="col-md-4">
              <div className="form-group mb-3">
                <label htmlFor="nickname">Apelido</label>
                <input type="text" name="nickname" className="form-control" id="nickname" placeholder="Apelido" defaultValue={teacher?.nickname} onChange={handleUserChange} />
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group mb-3">
                <label htmlFor="name">Nome</label>
                <input type="text" name="name" className="form-control" id="name" placeholder="Nome" defaultValue={teacher?.name} onChange={handleUserChange} />
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group mb-3">
                <label htmlFor="email">E-mail</label>
                <input type="email" name="email" className="form-control" id="email" placeholder="E-mail" defaultValue={teacher?.email} onChange={handleUserChange} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card mb-3">
        <div className="card-header">
          Alteração de senha
        </div>

        <div className="card-body pb-0">
          <div className="row">
            <div className="col-12 col-md-6 mb-3">
              <div className="form-group">
                <label htmlFor="password">Nova senha (mínimo 6 caracteres)</label>
                <input type="password" name="password" className="form-control" id="password" placeholder="Nova senha" onChange={handleUserChange} autoComplete="new-password" />
              </div>
            </div>

            <div className="col-12 col-md-6 mb-3">
              <div className="form-group">
                <label htmlFor="passwordConfirmation">Confirmação da senha</label>
                <input type="password" name="passwordConfirmation" className="form-control" id="passwordConfirmation" placeholder="Confirmação da senha" onChange={handleUserChange} autoComplete="new-password" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Association />

      <div className="d-flex justify-content-end mb-3">
        <button className="btn btn-primary" onClick={update} disabled={isLoading}>Atualizar</button>
      </div>
    </>
  )
}

export default TeachersEdit
