enum RegistrationStepEnum {
  DegreeGroups,
  Degrees,
  Selection,
  Data,
  Finish,
  Association,
}

export default RegistrationStepEnum
