import styled from 'styled-components'

export const Tooltip = styled.div`
  .tooltip-container {
    position: relative;

    .tooltip {
      border-radius: 5px;
      text-align: center;
      padding: 6px 12px;
      transition: all 0.3s ease-in-out;
      position: absolute;
      right: calc(100% + 10px);
      top: 50%;
      transform: translateY(-50%);
      width: 140px;
    }

    &:hover > .tooltip {
      visibility: visible;
      z-index: 1;
      opacity: 1;
      background: #222;
      color: #fff;

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        width: 0;
        height: 0;
        border: 6px solid transparent;
        border-left-color: #222;
        transform: translateY(-50%);
        left: 100%;
      }

    }
  }
`

export const TooltipButton = styled.div`
  .tooltip-container {
    position: relative;

    .tooltip {
      border-radius: 5px;
      text-align: center;
      padding: 6px 12px;
      transition: all 0.3s ease-in-out;
      position: absolute;
      right: calc(100% + 10px);
      top: 50%;
      transform: translateY(-50%);
      width: 140px;
    }

    &:hover > .tooltip {
      visibility: visible;
      z-index: 1;
      opacity: 1;
      background: #222;
      color: #fff;

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        width: 0;
        height: 0;
        border: 6px solid transparent;
        border-left-color: #222;
        transform: translateY(-50%);
        left: 100%;
      }

    }
  }
`
