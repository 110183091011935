import styled from 'styled-components'
import { Nav as NavComponent } from 'react-bootstrap'

export const HeaderComponent = styled.header`
  width: 100%;
  display: flex;
  position: sticky;
  z-index: 120;
  top: 0;

  > .container-fluid {
    > .row {
      height: 100px;
      display: flex;
      align-content: stretch;

      > .header-bar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: var(--primary-color);
        padding: 0 var(--spacing-padding);
        transition: 0.2s ease all;

        > .env-selector {
          width: 100%;
          display: flex;
          align-items: center;

          > .select-field {
            width: 100%;
            min-width: 120px;
            max-width: 232px;
          }

          > .separator {
            margin: 0 8px;
          }
        }

        > .menu {
          display: flex;
          align-items: center;
          margin-left: 16px;

          > .notifications,
          > .user {
            margin-right: 16px;

            @media (max-width: 576px) {
              margin-right: 5px;
            }

            > .dropdown {
              > .dropdown-menu {
                > .dropdown-item {
                  > small {
                    display: block;
                    text-transform: uppercase;
                    color: #555;
                    font-size: 0.7rem;
                  }

                  &.text {
                    pointer-events: none;
                  }
                }
              }

              > .btn {
                width: 48px;
                height: 48px;
                border-radius: 100%;
                outline: none;
                border: none;
                padding: 0;

                > svg {
                  max-width: 32px;
                  max-height: 32px;
                }

                &::after {
                  display: none;
                }
              }
            }
          }

          > .platform {
            > .dropdown {
              > .btn {
                display: flex;
                align-items: center;
                border-radius: 60px;
                padding: 8px 20px;
                background-color: var(--secondary-color);
                border-color: var(--secondary-color);
                filter: brightness(90%);
                box-shadow: none;
                border: 0;

                @media (max-width: 576px) {
                  > span {
                    display: none;
                  }
                }

                &:hover {
                  filter: brightness(100%);
                }

                &::after {
                  margin-left: 12px;
                }
              }

              > #sidebarWeb {
                display: block;
              }

              > #sidebarMobile {
                display: none;
              }

              @media (max-width: 576px) {
                > #sidebarWeb {
                  display: none !important;
                }

                > #sidebarMobile {
                  display: block;

                  > svg {
                    max-width: 32px;
                    max-height: 32px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export const Nav = styled(NavComponent) `
  margin-right: 16px;
`

export const Figure = styled.figure`
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #e5e5f0;
  background-color: #fff;
  transition: 0.2s ease all;
  position: relative;
  overflow: hidden;
  margin: 0;

  > a {
    width: auto;
    display: block;
    overflow: hidden;

    > canvas {
      width: auto;
      height: auto;
      position: absolute;
      transform: translate(-50%, -50%);
      z-index: 1;
      opacity: 0;
      left: 50%;
      top: 50%;

      &.logotipo-full {
        max-width: 200px;
        max-height: 60px;
      }

      &.logotipo-min {
        max-width: 135px;
        max-height: 60px;
      }

      &.active {
        opacity: 1;
        z-index: 2;
      }
    }
  }

  &.collapsed > a {
    width: 45px;
  }
`
