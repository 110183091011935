import React, { useEffect } from 'react'

type Props = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
  indeterminate?: boolean
}

const Indeterminate: React.FC<any> = ({ indeterminate, ...props }: Props) => {
  const inputRef = React.useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (inputRef.current && typeof indeterminate !== 'undefined') {
      inputRef.current.indeterminate = indeterminate
    }
  }, [indeterminate])

  return (
    <input
      ref={inputRef}
      {...props}
    />
  )
}

export default Indeterminate
