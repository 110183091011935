import React, { useState } from 'react'
import * as $Student from '@services/Student'
import Button from '@components/Button/Button'
import IUser from '@interfaces/IUser'
import { getCssProperty } from '@helpers/Theme'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'

type IDeleteProps = {
  student: IUser;
  year: number;
  onDelete: (studentId: number) => void;
}

const Delete: React.FC<any> = ({ student, year, onDelete }: IDeleteProps) => {
  const [ isLoading, setIsLoading ] = useState<boolean>(false)

  const SweetAlert = withReactContent(Swal)

  const destroy = () => {
    SweetAlert.fire({
      title: 'Deseja prosseguir?',
      text: `Deseja prosseguir com a exclusão do aluno ${student.name}? Você não poderá voltar atrás dessa decisão.`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: getCssProperty('--secondary-color'),
      cancelButtonColor: getCssProperty('--primary-color'),
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    }).then((result: any) => {
      if (result.value && student?.id) {
        setIsLoading(true)

        $Student.destroy(student.id, year).then(({ data }: any) => {
          if (data.delete) {
            onDelete(student.id as number)
          }
        }).finally(() => {
          setIsLoading(false)
        })
      }
    })
  }

  return (
    <Button className="btn btn-sm btn-danger text-white ps-4 pe-4 ms-2" onClick={destroy} disabled={isLoading} isLoading={isLoading}>Excluir</Button>
  )
}

export default Delete
