import React, { useContext, useEffect, useState } from 'react'
import IUser from '@interfaces/IUser'
import * as $Student from '@services/Student'
import * as $Degree from '@services/Degree'
import { Link, useNavigate, useParams } from 'react-router-dom'
import IDegree from '@interfaces/IDegree'
import IClass from '@interfaces/IClass'
import axios from 'axios'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import UserLogsComponent from '@components/UserLogs/UserLogs'
import * as $UserLog from '@services/UserLog'
import IUserLog from '@interfaces/IUserLog'
import AuthContext from '@contexts/Auth'

const StudentsEdit: React.FC<any> = () => {
  const { year } = useContext(AuthContext)

  const [ student, setStudent ] = useState<IUser>({} as IUser)
  const [ degrees, setDegrees ] = useState<IDegree[]>([])
  const [ classes, setClasses ] = useState<IClass[]>([])
  const [ isLoading, setIsLoading ] = useState<boolean>(true)
  const [ userLogs, setUserLogs ] = useState<IUserLog[]>([])

  const { id: studentId }: any = useParams()
  const SweetAlert = withReactContent(Swal)
  const navigate = useNavigate()

  useEffect(() => {
    axios.all([
      $Student.find(studentId),
      $Degree.all(),
      $UserLog.getUser(studentId),
    ]).then(axios.spread(({ data: student }: any, { data: degrees }: any, {data: userLogs }: any) => {
      if (student.year !== year) {
        navigate('/alunos')
        return
      }

      setStudent(student)
      setDegrees(degrees)
      setUserLogs(userLogs)

      degrees.map((degree: IDegree) => setClasses(classes => [...classes, ...degree.classes]))
    })).finally(() => setIsLoading(false))
  }, [navigate, studentId, year])

  const handleInputChange = (e: any) => {
    const { name, value } = e.target

    setStudent({
      ...student,
      [name]: value,
    })
  }

  const handleDegreeChange = (e: any, student: IUser) => {
    const { value: degreeId } = e.target

    setStudent({
      ...student,
      degreeId: parseInt(degreeId),
      classId: classes.find((classItem: IClass) => classItem.degreeId == degreeId)?.id,
    })
  }

  const handleClassChange = (e: any, student: IUser) => {
    const { value: classId } = e.target

    setStudent({
      ...student,
      classId: parseInt(classId),
    })
  }

  const handleUpdate = () => {
    if (student.year != null && student.year < 2022) {
      SweetAlert.fire({
        title: 'Deseja atualizar o aluno?',
        text: 'Os dados do aluno serão atualizados para o ano de 2022.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, atualizar!',
        cancelButtonText: 'Cancelar',
      }).then((result: any) => {
        if (result.value) {
          update()
        }
      })
    } else {
      update()
    }
  }

  const update = () => {
    if (student.year !== year || year !== new Date().getFullYear())
      return

    setIsLoading(true)

    SweetAlert.showLoading()

    $Student.update(student.id as number, {
      name: student.nickname ? student?.nickname : student.name,
      email: student.email,
      nickname: student?.nickname,
      degreeId: student.degreeId,
      classId: student.classId,
      password: student.password,
      passwordConfirmation: student.passwordConfirmation,
    } as IUser).then(() => {
      SweetAlert.fire({
        title: 'Sucesso!',
        text: 'Aluno atualizado com sucesso!',
        icon: 'success'
      })
    }).catch(e => {
      SweetAlert.fire({
        title: 'Erro!',
        text: e.response?.data?.message ?? 'Erro ao atualizar o aluno!',
        icon: 'error'
      })
    }).finally(() => {
      setIsLoading(false)
      SweetAlert.hideLoading()
    })
  }

  return (
    <>
      <div className="d-flex justify-content-end mb-3">
        <Link to="/alunos" className="btn btn-outline-primary me-2">Voltar</Link>
        {student.year === year && year === new Date().getFullYear() && (
          <button className="btn btn-primary" onClick={handleUpdate} disabled={isLoading}>Atualizar</button>
        )}
      </div>

      <div className="card mb-3">
        <div className="card-header">
          Editar aluno ({student?.nickname})
        </div>

        <div className="card-body">
          <div className="row">
            <div className="col-md-3">
              <div className="form-group mb-3">
                <label htmlFor="nickname">Nome</label>
                <input type="text" name="nickname" className="form-control" id="nickname" placeholder="Nome" defaultValue={student?.nickname} onChange={handleInputChange} />
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group mb-3">
                <label htmlFor="email">E-mail</label>
                <input type="email" name="email" className="form-control" id="email" placeholder="E-mail" defaultValue={student?.email} onChange={handleInputChange} />
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group mb-3">
                <label htmlFor="degreeId">Série</label>
                {degrees.length && student?.id ? (
                  <select name="degreeId" value={student.degreeId} className="form-control" onChange={(e: any) => handleDegreeChange(e, student)}>
                    {degrees.filter((degree: IDegree) => degree.classes.length > 0).map((degree: any) => (
                      <option value={degree.id} key={degree.id}>{degree.name}</option>
                    ))}
                  </select>
                ) : (
                  <select className="form-control" disabled></select>
                )}
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group mb-3">
                <label htmlFor="classId">Turma</label>
                {classes.length && student?.id ? (
                  <select name="classId" value={student.classId} className="form-control" onChange={(e: any) => handleClassChange(e, student)}>
                    {degrees.length > 0 && degrees.find((degree: IDegree) => degree.id == student.degreeId)?.classes.map((item: any) => (
                      <option key={item.id} value={item.id}>{item.name}</option>
                    ))}
                  </select>
                ) : (
                  <select className="form-control" disabled></select>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card mb-3">
        <div className="card-header">
          Alteração de senha
        </div>

        <div className="card-body pb-0">
          <div className="row">
            <div className="col-12 col-md-6 mb-3">
              <div className="form-group">
                <label htmlFor="password">Nova senha (mínimo 6 caracteres)</label>
                <input type="password" name="password" className="form-control" id="password" placeholder="Nova senha" onChange={handleInputChange} autoComplete="new-password" />
              </div>
            </div>

            <div className="col-12 col-md-6 mb-3">
              <div className="form-group">
                <label htmlFor="passwordConfirmation">Confirmação da senha</label>
                <input type="password" name="passwordConfirmation" className="form-control" id="passwordConfirmation" placeholder="Confirmação da senha" onChange={handleInputChange} autoComplete="new-password" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <UserLogsComponent data={userLogs} />
    </>
  )
}

export default StudentsEdit
