import styled from 'styled-components'

export const Tooltip = styled.div`
  .tooltip-container {
    position: relative;

    .tooltip {
      left: 50%;
      background-color: #00000005;
      border-radius: 5px;
      text-align: center;
      padding: 6px 12px;
      transform: translateX(-50%);
      transition: all 0.3s ease-in-out;
      bottom: 100%;
      position: absolute;
      visibility: hidden;
      z-index: -1;
      opacity: 0;
    }

    &:hover > .tooltip {
      visibility: visible;
      z-index: 1;
      opacity: 1;
      background: #222;
      color: #fff;

      &::before {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        width: 0;
        height: 0;
        border: .5em solid transparent;
        border-top-color: #222;
        transform: translate(-50%, 0);
      }

    }
  }
`