import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import PanelContext from '@contexts/Panel'
import StudentsAndProductsContext from '@contexts/StudentsAndProducts'
import IPlatform from '@interfaces/IPlatform'
import IPlatformAssociation from '@interfaces/IPlatformAssociation'
import { Accordion, Body, Container, Header, Labels, Toggle } from './Degree.styles'
import IClass from '@interfaces/IClass'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'
import { getCssProperty } from '@helpers/Theme'
import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai'
import Student from '../Student/Student'
import { IStudentSelection } from '@interfaces/IStudentSelection'

const Degree: React.FC<any> = ({ degree }: any) => {
  const { platforms } = useContext(PanelContext)
  const { students, associations, selectedAssociation, onCheckAll } = useContext(StudentsAndProductsContext)

  const [ activeClassId, setActiveClassId ] = useState<number>(0)

  useEffect(() => {
    if (degree?.classes?.length) {
      setActiveClassId(degree.classes[0].id)
    }
  }, [degree?.classes])

  const association: IPlatformAssociation[] = useMemo(() => associations[degree.id], [associations, degree.id])

  const getIsAllChecked = useCallback((classId: number) : boolean => {
    if (!students[degree.id] || !students[degree.id][classId])
      return false

    const data = Object.keys(students[degree.id][classId])

    const checked = selectedAssociation?.students?.filter((s: IStudentSelection) => {
      return s.classId === classId
    })

    return checked?.length === data?.length
  }, [degree.id, selectedAssociation?.students, students])

  return (
    <Container>
      <Header>
        <div className="toggle"></div>

        <div className="name">Aluno</div>
        <div className="identifier">RA</div>
        <div className="usercode">RE</div>

        {platforms.filter(platform => platform?.degreeIds?.includes(degree.id) && !platform.isCurrentPlatform).map((platform: IPlatform) => (
          <div className="platform" key={platform.id}>{platform.name}</div>
        ))}
      </Header>

      {degree?.classes?.map((class_: IClass) => (
        <Accordion key={class_.id}>
          <Labels>
            <div className="toggle">
              <Toggle onClick={() => setActiveClassId(activeClassId !== class_.id ? class_.id : 0)} disabled={students[degree.id] == null || students[degree.id][class_.id] == null}>
                {activeClassId === class_.id ? <FiChevronUp size={20} color={getCssProperty('--secondary-color')} /> : <FiChevronDown size={20} color={getCssProperty('--secondary-color')} />}
              </Toggle>
            </div>

            <div className="name">
              <label className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input me-2"
                  checked={getIsAllChecked(class_.id)}
                  onChange={(e: any) => onCheckAll(e, degree.id, class_.id)}
                  disabled={students[degree.id] == null || students[degree.id][class_.id] == null}
                />
                <strong>{class_.name}</strong>
              </label>
            </div>

            <div className="identifier"></div>
            <div className="usercode"></div>

            {platforms.filter(platform => platform?.degreeIds?.includes(degree.id) && !platform.isCurrentPlatform).map((platform: IPlatform) => {
              const item = association?.find((item: IPlatformAssociation) => item.platformId === platform.id && item.classId === class_.id)

              return (
                <div className="platform" key={platform.id}>
                  {item?.studentsCount != null && item.studentsCount > 0 ? (
                    <span>{item.studentsCount ?? 0}/{item.platformCount ?? 0}</span>
                  ) : (
                    <span>{item?.platformCount ?? 0}</span>
                  )}
                  {item?.platformCount != null && item.platformCount > 0 ? (
                    <AiFillCheckCircle size={18} color={getCssProperty('--primary-color')} />
                  ) : (
                    <AiFillCloseCircle size={18} color="#999" />
                  )}
                </div>
              )
            })}
          </Labels>

          {activeClassId === class_.id && students[degree.id] != null && students[degree.id][class_.id] != null && (Object.keys(students[degree.id][class_.id])?.length ? (
            <Body>
              {Object.entries(students[degree.id][class_.id]).map(([studentId, data]: any) => (
                <Student student={data[0]} data={data} key={studentId} />
              ))}
            </Body>
          ) : (
            <div className="text-center p-3">
              <span>Nenhum aluno cadastrado nessa turma. {class_.id}</span>
            </div>
          ))}
        </Accordion>
      ))}
    </Container>
  )
}

export default Degree
