import React, { createContext, useContext, useEffect, useState } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import * as $Classroom from '@services/Class'
import IClass from '@interfaces/IClass'
import IDegree from '@interfaces/IDegree'
import IUserSchool from '@interfaces/IUserSchool'
import AuthContext from './Auth'

type ClassEditContextProps = {
  classId: number
  selected: number[]
  setSelected: React.Dispatch<React.SetStateAction<number[]>>
  classroom: IClass
  setClassroom: React.Dispatch<React.SetStateAction<IClass>>
  userSchools: IUserSchool[]
  setUserSchools: React.Dispatch<React.SetStateAction<IUserSchool[]>>
  isLoading: boolean
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  degrees: IDegree[]
  showConfirmMigrate: boolean
  setShowConfirmMigrate: React.Dispatch<React.SetStateAction<boolean>>
  currentYear: number,
  year: number
}

const ClassEditContext = createContext<ClassEditContextProps>({ } as ClassEditContextProps)

export const ClassEditProvider: React.FC<any> = () => {
  const { year } = useContext(AuthContext)
  const { id }: any = useParams()
  const [ classId, ] = useState<number>(id)
  const [ selected, setSelected ] = useState<number[]>([])
  const [ classroom, setClassroom ] = useState<IClass>({} as IClass)
  const [ userSchools, setUserSchools ] = useState<IUserSchool[]>([] as IUserSchool[])
  const [ isLoading, setIsLoading ] = useState(true)
  const [ degrees, setDegrees ] = useState<IDegree[]>([] as IDegree[])
  const [ showConfirmMigrate, setShowConfirmMigrate ] = useState<boolean>(false)
  const [ currentYear ] = useState<number>(new Date().getFullYear())
  const navigate = useNavigate()

  useEffect(() => {
    setIsLoading(true)
    $Classroom.find(0, classId, year).then(({ data: {classroom, users, degrees } }) => {
      setClassroom(classroom)
      setUserSchools(users)
      setDegrees(degrees)
      if (classroom.year !== year)
        navigate('/turmas')
    }).finally(() => setIsLoading(false))
  }, [classId, year, navigate])

  return (
    <ClassEditContext.Provider
      value={{
        classId,
        selected,
        setSelected,
        classroom,
        setClassroom,
        userSchools,
        setUserSchools,
        isLoading,
        setIsLoading,
        degrees,
        showConfirmMigrate,
        setShowConfirmMigrate,
        currentYear,
        year
      }}
    >
      <Outlet />
    </ClassEditContext.Provider>
  )
}

export default ClassEditContext
