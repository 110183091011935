import React, { forwardRef, useContext, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import * as $Dashboard from '@services/Dashboard'
import IPlatform from '@interfaces/IPlatform'
import Platforms from './Platforms'
import { Accordion, Dropdown } from 'react-bootstrap'
import AccordionToggle from '@components/AccordionToggle/AccordionToggle'
import AuthContext from '@contexts/Auth'
import PanelContext from '@contexts/Panel'
import Permission from '@enums/Permission.enum'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

const Toggle = forwardRef(({ children, onClick }: any, ref: any) => (
  <button
    ref={ref}
    className="btn btn-link p-0"
    onClick={(e) => {
      e.preventDefault()
      onClick(e)
    }}
  >
    {children}
  </button>
))

Toggle.displayName = 'Toggle'

const Dashboard: React.FC = () => {
  const { permissions, school, year } = useContext(AuthContext)
  const { platforms } = useContext(PanelContext)

  const [ isLoading, setIsLoading ] = useState<boolean>(true)
  const [ studentsPlatformId, setStudentsPlatformId ] = useState<number>(0)
  const [ academicPlatformId, setAcademicPlatformId ] = useState<number>(0)
  const [ dashboard, setDashboard ] = useState<any>({
    students: [],
    academic: [],
    totals: {
      students: 0,
      academic: 0,
    },
  })

  useEffect(() => {
    setIsLoading(true)
    $Dashboard.all(year).then(({ data }: any) => setDashboard(data)).finally(() => setIsLoading(false))
  }, [school, year])

  useEffect(() => {
    if (platforms.length) {
      setStudentsPlatformId(platforms[0].id)
      setAcademicPlatformId(platforms[0].id)
    }
  }, [platforms])

  const students = useMemo(() => {
    const data: any = {}

    if (dashboard.students.length) {
      for (const item of dashboard.students.filter((item: any) => item.platformId === studentsPlatformId)) {
        data[item.degreeGroupName] = {
          total: (data[item.degreeGroupName]?.total ?? 0) + 1,
          degrees: {
            ...data[item.degreeGroupName]?.degrees ?? {},
            [item.degreeName]: [
              ...data[item.degreeGroupName]?.degrees?.[item.degreeName] ?? [],
              item.userId,
            ],
          },
        }
      }
    }

    return data
  }, [dashboard.students, studentsPlatformId])

  const academic = useMemo(() => {
    const data: any = {}

    if (dashboard.academic.length) {
      for (const item of dashboard.academic.filter((item: any) => item.platformId === academicPlatformId)) {
        data[item.topicName] = {
          total: (data[item.topicName]?.total ?? 0) + 1,
          disciplines: {
            ...data[item.topicName]?.disciplines ?? {},
            [item.disciplineName]: [
              ...data[item.topicName]?.disciplines?.[item.disciplineName] ?? [],
              item.userId,
            ],
          },
        }
      }
    }

    return data
  }, [dashboard.academic, academicPlatformId])

  const showRevisors = useMemo(() => platforms.some(platform => platform.id === 3), [platforms])

  const hasAcademicPermissions = useMemo(() => permissions.some(permission => {
    return permission === Permission.CREATE_TEACHERS || permission === Permission.CREATE_REVISORS || permission === Permission.CREATE_MANAGERS
  }), [permissions])

  return (
    <div className="row">
      <div className="col-12 col-md-6">
        <div className="card mb-3">
          <div className="card-body">
            <h2>Alunos</h2>

            {!isLoading ? (
              <h3 className="mb-3">{dashboard.totals.students} Cadastrados</h3>
            ) : (
              <SkeletonTheme color="#f0f0f0" highlightColor="#fff">
                <Skeleton count={1} height={28} className="mb-3" />
              </SkeletonTheme>
            )}

            <div className={'d-flex align-items-center justify-content-' + (permissions.includes(Permission.CREATE_STUDENTS) ? 'between' : 'end')}>
              {permissions.includes(Permission.CREATE_STUDENTS) && year === new Date().getFullYear() && (
                <Link to="/alunos/cadastrar" className="btn btn-link">Inserir novos usuários</Link>
              )}
              <Link to="/alunos" className="btn btn-primary">Ver todos</Link>
            </div>

            <hr/>

            <h6>Filtrar por</h6>
            <Platforms platforms={platforms} onClick={(platform: IPlatform) => setStudentsPlatformId(platform.id)} platformId={studentsPlatformId} />

            {Object.entries(students).length > 0 && (
              <Accordion defaultActiveKey={Object.keys(students)[0]}>
                {Object.entries(students).map(([degreeGroupName, degreeGroup]: any) => (
                  <div className="itemGroup bg-light mb-3" key={degreeGroupName}>
                    <div className="itemGroup-header p-4 pt-3 pb-3">
                      <AccordionToggle eventKey={degreeGroupName} />
                      <strong className="ms-3">{degreeGroup.total} {degreeGroup.total === 1 ? 'aluno' : 'alunos'}</strong> em <strong>{degreeGroupName}</strong>
                    </div>

                    <Accordion.Collapse eventKey={degreeGroupName}>
                      <div className="itemGroup-body p-4 pt-3 pb-3">
                        {Object.entries(degreeGroup?.degrees).map(([degreeName, userIds]: any) => (
                          <div className="item pt-3 pb-3 border-top" key={degreeName}>
                            {degreeName}: <strong>{userIds.length} {userIds.length === 1 ? 'aluno' : 'alunos'}</strong>
                          </div>
                        ))}
                      </div>
                    </Accordion.Collapse>
                  </div>
                ))}
              </Accordion>
            )}

            {permissions.includes(Permission.ASSOCIATE_STUDENTS) && year === new Date().getFullYear() && (
              <Link to="/alunos-e-produtos" className="btn btn-outline-primary d-block">Associar usuário a solução</Link>
            )}
          </div>
        </div>
      </div>

      <div className="col-12 col-md-6">
        <div className="card">
          <div className={'card-body' + (!hasAcademicPermissions ? ' pb-0' : '')}>
            <h2>Gestores e Professores</h2>

            {!isLoading ? (
              <h3 className="mb-3">{dashboard.totals.academic} Cadastrados</h3>
            ) : (
              <SkeletonTheme color="#f0f0f0" highlightColor="#fff">
                <Skeleton count={1} height={28} className="mb-3" />
              </SkeletonTheme>
            )}

            <div className={'d-flex align-items-center justify-content-' + (hasAcademicPermissions ? 'between' : 'end')}>
              {hasAcademicPermissions && year === new Date().getFullYear() && (
                <Dropdown>
                  <Dropdown.Toggle variant="primary" as={Toggle}>Inserir novos usuários</Dropdown.Toggle>

                  <Dropdown.Menu>
                    {permissions.includes(Permission.CREATE_MANAGERS) && (
                      <Dropdown.Item href="gestores/cadastrar">Inserir gestores</Dropdown.Item>
                    )}

                    {permissions.includes(Permission.CREATE_TEACHERS) && (
                      <Dropdown.Item href="professores/cadastrar">Inserir professores</Dropdown.Item>
                    )}

                    {showRevisors && permissions.includes(Permission.CREATE_REVISORS) && (
                      <Dropdown.Item href="corretores/cadastrar">Inserir corretores</Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              )}

              <Dropdown>
                <Dropdown.Toggle variant="primary">Ver todos</Dropdown.Toggle>

                <Dropdown.Menu>
                  {permissions.includes(Permission.VIEW_MANAGERS) && (
                    <Dropdown.Item href="gestores">Ver todos os gestores</Dropdown.Item>
                  )}

                  {permissions.includes(Permission.VIEW_TEACHERS) && (
                    <Dropdown.Item href="professores">Ver todos os professores</Dropdown.Item>
                  )}

                  {showRevisors && permissions.includes(Permission.VIEW_REVISORS) && (
                    <Dropdown.Item href="corretores">Ver todos os corretores</Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </div>

            <hr/>

            <h6>Filtrar por</h6>

            <Platforms platforms={platforms} onClick={(platform: IPlatform) => setAcademicPlatformId(platform.id)} platformId={academicPlatformId} />

            {Object.entries(academic).length > 0 && (
              <Accordion defaultActiveKey={Object.keys(academic)[0]}>
                {Object.entries(academic).map(([topicName, topic]: any) => (
                  <div className="itemGroup bg-light mb-3" key={topicName}>
                    <div className="itemGroup-header p-4 pt-3 pb-3">
                      <AccordionToggle eventKey={topicName} />
                      <strong className="ms-3">{topic.total} {topic.total === 1 ? 'usuário' : 'usuários'}</strong> em <strong>{topicName}</strong>
                    </div>

                    <Accordion.Collapse eventKey={topicName}>
                      <div className="itemGroup-body p-4 pt-3 pb-3">
                        {Object.entries(topic?.disciplines).map(([disciplineName, userIds]: any) => (
                          <div className="item pt-3 pb-3 border-top" key={disciplineName}>
                            {disciplineName}: <strong>{userIds.length} {userIds.length === 1 ? 'usuário' : 'usuários'}</strong>
                          </div>
                        ))}
                      </div>
                    </Accordion.Collapse>
                  </div>
                ))}
              </Accordion>
            )}

            {permissions.includes(Permission.ASSOCIATE_TEACHERS) && year === new Date().getFullYear() && (
              <Link to="/professores-e-produtos" className="btn btn-outline-primary d-block">Associar usuário a solução</Link>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard
