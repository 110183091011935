const columns: any[] = [
  {
    Header: 'Relatório',
    accessor: 'name',
  },
  {
    Header: 'Descrição',
    accessor: 'description',
  },
]

export default columns
